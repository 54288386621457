import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, arrayUnion, runTransaction, collection } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import OrderHeader, { ORDER_STATUSES } from '../components/orders/OrderHeader';
import OrderItems from '../components/orders/OrderItems';
import OrderSummary from '../components/orders/OrderSummary';
import OrderComments from '../components/orders/OrderComments';
import OrderActions from '../components/orders/OrderActions';
import ShippingInfo from '../components/orders/ShippingInfo';
import CancelOrderModal from '../components/orders/CancelOrderModal';
import { CancelReason } from '../components/orders/CancelOrderModal';

interface Order {
  id: string;
  orderNumber: string;
  status: string;
  items: Array<{
    productId: string;
    name: string;
    quantity: number;
    price: number;
    total: number;
  }>;
  subtotal: number;
  shippingCost: number;
  total: number;
  commission?: number;
  createdAt: string;
  buyerCompanyId?: string;
  sellerId?: string;
  shippingMethod: 'normal' | 'dropshipping';
  shippingAddress?: {
    name: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
  };
  isPrivateSale?: boolean;
  comments?: Array<{
    id: string;
    text: string;
    createdAt: string;
    createdBy: string;
    createdById: string;
  }>;
}

interface Company {
  id: string;
  commercialName: string;
  legalName: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
  email: string;
}

export default function OrderDetails() {
  const [order, setOrder] = useState<Order | null>(null);
  const [buyerCompany, setBuyerCompany] = useState<Company | null>(null);
  const [sellerCompany, setSellerCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { currentUser } = useAuth();
  const { id } = useParams();

  const isSeller = order?.sellerId === currentUser?.companyId;
  const isBuyer = order?.buyerCompanyId === currentUser?.companyId;

  useEffect(() => {
    loadOrder();
  }, [id, currentUser]);

  const loadOrder = async () => {
    if (!id || !currentUser?.companyId) return;

    try {
      const orderDoc = await getDoc(doc(db, 'orders', id));
      if (!orderDoc.exists()) {
        setError('Encomenda não encontrada');
        return;
      }

      const orderData = orderDoc.data() as Order;
      setOrder({ id: orderDoc.id, ...orderData });

      // Load buyer company data
      if (orderData.buyerCompanyId) {
        const companyDoc = await getDoc(doc(db, 'companies', orderData.buyerCompanyId));
        if (companyDoc.exists()) {
          setBuyerCompany({ id: companyDoc.id, ...companyDoc.data() } as Company);
        }
      }

      // Load seller company data
      if (orderData.sellerId) {
        const sellerDoc = await getDoc(doc(db, 'companies', orderData.sellerId));
        if (sellerDoc.exists()) {
          setSellerCompany({ id: sellerDoc.id, ...sellerDoc.data() } as Company);
        }
      }
    } catch (err) {
      console.error('Error loading order:', err);
      setError('Erro ao carregar encomenda');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (newStatus: string) => {
    if (!order || !currentUser?.companyId) return;

    // Se o novo status for "cancelled", abre o modal de cancelamento
    if (newStatus === 'cancelled') {
      setShowCancelModal(true);
      return;
    }

    setUpdating(true);
    setError('');

    try {
      const comment = {
        id: Date.now().toString(),
        text: `Estado da encomenda alterado para: ${ORDER_STATUSES[newStatus as keyof typeof ORDER_STATUSES]}`,
        createdAt: new Date().toISOString(),
        createdBy: currentUser.displayName || currentUser.email || 'Usuário',
        createdById: currentUser.uid
      };

      await updateDoc(doc(db, 'orders', order.id), {
        status: newStatus,
        updatedAt: new Date().toISOString(),
        comments: arrayUnion(comment)
      });

      await loadOrder();
    } catch (err) {
      console.error('Error updating order:', err);
      setError('Erro ao atualizar encomenda');
    } finally {
      setUpdating(false);
    }
  };

  const handleCancelOrder = async (reason: CancelReason) => {
    if (!order || !currentUser?.companyId) return;

    setUpdating(true);
    setError('');

    try {
      await runTransaction(db, async (transaction) => {
        // Get references
        const orderRef = doc(db, 'orders', order.id);
        const buyerWalletRef = doc(db, 'wallets', order.buyerCompanyId!);
        const sellerWalletRef = doc(db, 'wallets', order.sellerId!);

        // Get current wallet balances
        const buyerWalletDoc = await transaction.get(buyerWalletRef);
        const sellerWalletDoc = await transaction.get(sellerWalletRef);

        if (!buyerWalletDoc.exists() || !sellerWalletDoc.exists()) {
          throw new Error('Carteiras não encontradas');
        }

        const buyerBalance = buyerWalletDoc.data().balance || 0;
        const sellerBalance = sellerWalletDoc.data().balance || 0;

        // Calculate amounts
        const orderTotal = order.total;
        const commission = order.commission || 0;
        const sellerAmount = orderTotal - commission;

        // Create refund transaction record
        const refundRef = doc(collection(db, 'walletTransactions'));
        transaction.set(refundRef, {
          type: 'refund',
          amount: orderTotal,
          orderId: order.id,
          companyId: order.buyerCompanyId,
          description: 'Reembolso por cancelamento de encomenda',
          status: 'completed',
          createdAt: new Date()
        });

        // Update buyer wallet - always refund full amount
        transaction.update(buyerWalletRef, {
          balance: buyerBalance + orderTotal,
          updatedAt: new Date().toISOString()
        });

        if (reason === 'buyer_request') {
          // Buyer requested - refund commission
          transaction.update(sellerWalletRef, {
            balance: sellerBalance - sellerAmount,
            updatedAt: new Date().toISOString()
          });

          // Create commission refund record
          const commissionRefundRef = doc(collection(db, 'walletTransactions'));
          transaction.set(commissionRefundRef, {
            type: 'commission_refund',
            amount: commission,
            baseAmount: commission / 1.23, // Remove 23% VAT
            vatAmount: commission - (commission / 1.23),
            orderId: order.id,
            companyId: order.sellerId,
            description: 'Reembolso de comissão por cancelamento',
            status: 'completed',
            createdAt: new Date()
          });
        } else {
          // Seller cancellation - deduct order total + commission as penalty
          transaction.update(sellerWalletRef, {
            balance: sellerBalance - (orderTotal + commission),
            updatedAt: new Date().toISOString()
          });
        }

        // Update order status and add comment
        transaction.update(orderRef, {
          status: 'cancelled',
          updatedAt: new Date().toISOString(),
          comments: arrayUnion({
            id: Date.now().toString(),
            text: `Encomenda cancelada. Motivo: ${reason}`,
            createdAt: new Date().toISOString(),
            createdBy: currentUser.displayName || currentUser.email || 'Usuário',
            createdById: currentUser.uid
          })
        });
      });

      await loadOrder();
      setShowCancelModal(false);
    } catch (err) {
      console.error('Error cancelling order:', err);
      setError('Erro ao cancelar encomenda');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="text-center text-red-600">{error || 'Encomenda não encontrada'}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <OrderHeader 
          orderNumber={order.orderNumber} 
          status={order.status}
          isSeller={isSeller}
          onUpdateStatus={handleUpdateStatus}
          onCancel={() => setShowCancelModal(true)}
          updating={updating}
          buyerName={buyerCompany?.commercialName || buyerCompany?.legalName}
          sellerName={sellerCompany?.commercialName || sellerCompany?.legalName}
        />

        {error && (
          <div className="mb-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-8">
            <OrderItems items={order.items} />
            
            <ShippingInfo
              title={order.shippingMethod === 'normal' ? 'O vendedor irá enviar para:' : 'Dados de entrega:'}
              name={order.shippingMethod === 'normal' ? buyerCompany?.commercialName : order.shippingAddress?.name}
              companyName={order.shippingMethod === 'normal' ? buyerCompany?.legalName : undefined}
              address={order.shippingMethod === 'normal' ? buyerCompany?.address : order.shippingAddress?.address}
              city={order.shippingMethod === 'normal' ? buyerCompany?.city : order.shippingAddress?.city}
              postalCode={order.shippingMethod === 'normal' ? buyerCompany?.postalCode : order.shippingAddress?.postalCode}
              country={order.shippingMethod === 'normal' ? buyerCompany?.country : order.shippingAddress?.country}
              phone={order.shippingMethod === 'normal' ? buyerCompany?.phone : order.shippingAddress?.phone}
              email={order.shippingMethod === 'normal' ? buyerCompany?.email : order.shippingAddress?.email}
              method={order.shippingMethod === 'normal' ? 'Envio Normal' : 'Dropshipping'}
            />
          </div>

          <div className="space-y-8">
            <OrderSummary
              subtotal={order.subtotal}
              shippingCost={order.shippingCost}
              total={order.total}
              createdAt={order.createdAt}
            />

            <OrderActions
              status={order.status}
              isSeller={isSeller}
              isBuyer={isBuyer}
              orderId={order.id}
              buyerCompanyId={order.buyerCompanyId || ''}
              total={order.total}
              onCancel={() => setShowCancelModal(true)}
              onUpdateStatus={handleUpdateStatus}
              updating={updating}
            />

            <OrderComments
              orderId={order.id}
              comments={order.comments || []}
              onCommentAdded={loadOrder}
            />
          </div>
        </div>
      </div>

      {showCancelModal && (
        <CancelOrderModal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={handleCancelOrder}
          isSeller={isSeller}
          loading={updating}
          orderTotal={order.total}
          commission={order.commission || 0}
        />
      )}
    </div>
  );
}