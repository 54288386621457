import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, Timestamp, or } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Package, CreditCard, Truck, CheckCircle, XCircle, TrendingUp, Eye } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface OrderStats {
  total: number;
  paid: number;
  pending: number;
  shipped: number;
  delivered: number;
  cancelled: number;
}

interface Order {
  id: string;
  orderNumber: string;
  status: string;
  createdAt: any;
  total: number;
  commission?: number;
  buyerName?: string;
  customer?: {
    name: string;
  };
  isPrivateSale?: boolean;
  items: Array<{
    productId: string;
    name: string;
    quantity: number;
    price: number;
  }>;
  sellerId?: string;
  buyerCompanyId?: string;
}

interface TopProduct {
  id: string;
  name: string;
  totalSold: number;
  totalRevenue: number;
  mainImage?: string;
}

const ORDER_STATUSES = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido'
} as const;

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [orderStats, setOrderStats] = useState<OrderStats>({
    total: 0,
    paid: 0,
    pending: 0,
    shipped: 0,
    delivered: 0,
    cancelled: 0
  });
  const [recentOrders, setRecentOrders] = useState<Order[]>([]);
  const [topProducts, setTopProducts] = useState<TopProduct[]>([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [lastThreeMonthsRevenue, setLastThreeMonthsRevenue] = useState(0);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.companyId) {
      loadDashboardData();
    }
  }, [currentUser]);

  const calculateRevenue = (orders: Order[], months: number = 1) => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth() - (months - 1), 1);

    return orders
      .filter(order => {
        // Inclui todas as ordens não canceladas/pendentes
        if (['cancelled', 'pending'].includes(order.status)) return false;
        
        // Verifica se é vendedor
        if (order.sellerId !== currentUser?.companyId) return false;

        const orderDate = order.createdAt instanceof Timestamp ? 
          order.createdAt.toDate() : 
          new Date(order.createdAt);
          
        return orderDate >= startDate;
      })
      .reduce((total, order) => {
        // Calcula o lucro líquido: total da ordem - comissão
        const orderTotal = order.total || 0;
        const commission = order.commission || 0;
        return total + (orderTotal - commission);
      }, 0);
  };

  const calculateTopProducts = (orders: Order[]) => {
    const productStats = new Map<string, { 
      name: string; 
      totalSold: number; 
      totalRevenue: number;
      mainImage?: string;
    }>();

    orders
      .filter(order => !['cancelled', 'pending'].includes(order.status))
      .forEach(order => {
        order.items?.forEach(item => {
          const current = productStats.get(item.productId) || { 
            name: item.name, 
            totalSold: 0, 
            totalRevenue: 0 
          };
          
          productStats.set(item.productId, {
            ...current,
            totalSold: current.totalSold + item.quantity,
            totalRevenue: current.totalRevenue + (item.price * item.quantity)
          });
        });
      });

    return Array.from(productStats.entries())
      .map(([id, stats]) => ({
        id,
        ...stats
      }))
      .sort((a, b) => b.totalRevenue - a.totalRevenue)
      .slice(0, 10);
  };

  const loadDashboardData = async () => {
    if (!currentUser?.companyId) return;

    try {
      const ordersQuery = query(
        collection(db, 'orders'),
        or(
          where('companyId', '==', currentUser.companyId),
          where('sellerId', '==', currentUser.companyId),
          where('buyerCompanyId', '==', currentUser.companyId)
        )
      );
      
      const snapshot = await getDocs(ordersQuery);
      const ordersData = await Promise.all(
        snapshot.docs.map(async (orderDoc) => {
          const data = orderDoc.data();
          let buyerName = data.customer?.name || 'N/A';

          // If it's a private sale, get the buyer company name
          if (data.isPrivateSale && data.buyerCompanyId) {
            try {
              const buyerCompanyDoc = await getDoc(doc(db, 'companies', data.buyerCompanyId));
              if (buyerCompanyDoc.exists()) {
                const companyData = buyerCompanyDoc.data();
                buyerName = companyData.commercialName || companyData.legalName;
              }
            } catch (err) {
              console.error('Error loading buyer company:', err);
            }
          }

          return {
            id: orderDoc.id,
            ...data,
            buyerName
          };
        })
      );

      // Calculate order stats
      const stats: OrderStats = {
        total: ordersData.length,
        paid: ordersData.filter(order => order.status !== 'pending').length,
        pending: ordersData.filter(order => order.status === 'pending').length,
        shipped: ordersData.filter(order => order.status === 'shipped').length,
        delivered: ordersData.filter(order => order.status === 'delivered').length,
        cancelled: ordersData.filter(order => order.status === 'cancelled').length
      };
      setOrderStats(stats);

      // Calculate revenues
      setMonthlyRevenue(calculateRevenue(ordersData));
      setLastThreeMonthsRevenue(calculateRevenue(ordersData, 3));

      // Calculate top products
      setTopProducts(calculateTopProducts(ordersData));

      // Sort recent orders
      const sortedOrders = ordersData
        .sort((a, b) => {
          const dateA = a.createdAt instanceof Timestamp ? 
            a.createdAt.toDate() : new Date(a.createdAt);
          const dateB = b.createdAt instanceof Timestamp ? 
            b.createdAt.toDate() : new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        })
        .slice(0, 10);
      setRecentOrders(sortedOrders);

    } catch (err) {
      console.error('Error loading dashboard data:', err);
      setError('Erro ao carregar dados do dashboard');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('pt-PT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Data inválida';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Revenue Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center gap-3 mb-4">
            <TrendingUp className="text-primary" size={24} />
            <h3 className="text-xl font-bold text-secondary">Ganhos Este Mês</h3>
          </div>
          <p className="text-3xl font-bold text-primary">
            €{monthlyRevenue.toFixed(2)}
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center gap-3 mb-4">
            <TrendingUp className="text-primary" size={24} />
            <h3 className="text-xl font-bold text-secondary">Últimos 3 Meses</h3>
          </div>
          <p className="text-3xl font-bold text-primary">
            €{lastThreeMonthsRevenue.toFixed(2)}
          </p>
        </div>
      </div>

      {/* Order Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Total de Encomendas</p>
              <p className="text-2xl font-semibold mt-1">{orderStats.total}</p>
            </div>
            <div className="bg-blue-500 p-3 rounded-full">
              <Package className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Encomendas Pagas</p>
              <p className="text-2xl font-semibold mt-1">{orderStats.paid}</p>
            </div>
            <div className="bg-green-500 p-3 rounded-full">
              <CreditCard className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Aguardar Envio</p>
              <p className="text-2xl font-semibold mt-1">{orderStats.pending}</p>
            </div>
            <div className="bg-yellow-500 p-3 rounded-full">
              <Truck className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Enviadas</p>
              <p className="text-2xl font-semibold mt-1">{orderStats.shipped}</p>
            </div>
            <div className="bg-purple-500 p-3 rounded-full">
              <CheckCircle className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Canceladas</p>
              <p className="text-2xl font-semibold mt-1">{orderStats.cancelled}</p>
            </div>
            <div className="bg-red-500 p-3 rounded-full">
              <XCircle className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>
      </div>

      {/* Top Products */}
      {topProducts.length > 0 && (
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">Top 10 Produtos Mais Vendidos</h3>
            <div className="space-y-4">
              {topProducts.map((product, index) => (
                <div key={product.id} className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <span className="text-lg font-bold text-gray-400 w-6">{index + 1}</span>
                    <div>
                      <h4 className="font-medium">{product.name}</h4>
                      <p className="text-sm text-gray-500">{product.totalSold} unidades</p>
                    </div>
                  </div>
                  <p className="font-medium text-primary">€{product.totalRevenue.toFixed(2)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Recent Orders */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">Últimas Encomendas</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {recentOrders.map((order) => (
                  <tr key={order.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{order.orderNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.buyerName}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      €{order.total?.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        order.status === 'processing' ? 'bg-blue-100 text-blue-800' :
                        order.status === 'shipped' ? 'bg-purple-100 text-purple-800' :
                        order.status === 'delivered' ? 'bg-green-100 text-green-800' :
                        'bg-red-100 text-red-800'
                      }`}>
                        {ORDER_STATUSES[order.status as keyof typeof ORDER_STATUSES]}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatDate(order.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        order.isPrivateSale ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'
                      }`}>
                        {order.isPrivateSale ? 'Venda Privada' : 'Manual'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => navigate(`/orders/${order.id}`)}
                        className="text-primary hover:text-primary-dark"
                        title="Ver detalhes"
                      >
                        <Eye size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;