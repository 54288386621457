import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Package, Save, Trash2, X, Plus } from 'lucide-react';
import { validateImage, resizeImage } from '../utils/imageUpload';

interface ProductFormData {
  name: string;
  description: string;
  sku: string;
  ean: string;
  baselinker_id: string;
  regularPrice: number;
  salePrice: number;
  cost: number;
  preparationCost: number;
  stockQuantity: number;
  stockStatus: 'instock' | 'outofstock' | 'onbackorder';
  weight: number;
  length: number;
  width: number;
  height: number;
  mainImage?: string;
  galleryImages: string[];
  category: string;
  isPrivateSale: boolean;
  promotionalPeriod: {
    startDate: string;
    endDate: string;
  };
}

const ProductEdit = () => {
  const [formData, setFormData] = useState<ProductFormData>({
    name: '',
    description: '',
    sku: '',
    ean: '',
    baselinker_id: '',
    regularPrice: 0,
    salePrice: 0,
    cost: 0,
    preparationCost: 0,
    stockQuantity: 0,
    stockStatus: 'instock',
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    mainImage: '',
    galleryImages: [],
    category: '',
    isPrivateSale: false,
    promotionalPeriod: {
      startDate: '',
      endDate: ''
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    loadProduct();
    loadCategories();
  }, [id]);

  const loadCategories = async () => {
    try {
      const categoriesQuery = query(collection(db, 'categories'));
      const snapshot = await getDocs(categoriesQuery);
      const categoriesData = snapshot.docs.map(doc => doc.data().name);
      setCategories(categoriesData);
    } catch (err) {
      console.error('Error loading categories:', err);
    }
  };

  const loadProduct = async () => {
    if (!id || !currentUser) return;

    try {
      const productDoc = await getDoc(doc(db, 'products', id));
      if (productDoc.exists()) {
        const data = productDoc.data();
        setFormData({
          ...formData,
          ...data,
          promotionalPeriod: data.promotionalPeriod || formData.promotionalPeriod,
          galleryImages: data.galleryImages || [],
          category: data.category || '',
          isPrivateSale: data.isPrivateSale || false
        });
      }
    } catch (err) {
      console.error('Error loading product:', err);
      setError('Erro ao carregar produto');
    }
  };

  const handleImageUpload = async (file: File, type: 'main' | 'gallery') => {
    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Image = await resizeImage(file);
      
      if (type === 'main') {
        setFormData(prev => ({ ...prev, mainImage: base64Image }));
      } else {
        if (formData.galleryImages.length >= 4) {
          setError('Máximo de 4 imagens na galeria');
          return;
        }
        setFormData(prev => ({
          ...prev,
          galleryImages: [...prev.galleryImages, base64Image]
        }));
      }
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Erro ao processar imagem');
    }
  };

  const removeImage = (type: 'main' | 'gallery', index?: number) => {
    if (type === 'main') {
      setFormData(prev => ({ ...prev, mainImage: '' }));
    } else if (typeof index === 'number') {
      setFormData(prev => ({
        ...prev,
        galleryImages: prev.galleryImages.filter((_, i) => i !== index)
      }));
    }
  };

  const handleCreateCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.role === 'admin' || !newCategoryName.trim()) return;

    try {
      const slug = newCategoryName
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      await addDoc(collection(db, 'categories'), {
        name: newCategoryName.trim(),
        slug,
        createdAt: new Date().toISOString(),
        createdBy: currentUser.uid
      });

      setCategories([...categories, newCategoryName.trim()]);
      setFormData(prev => ({ ...prev, category: newCategoryName.trim() }));
      setNewCategoryName('');
      setShowNewCategoryForm(false);
      setSuccess('Categoria criada com sucesso');
    } catch (err) {
      console.error('Error creating category:', err);
      setError('Erro ao criar categoria');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id || !currentUser?.companyId) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await updateDoc(doc(db, 'products', id), {
        ...formData,
        updatedAt: new Date().toISOString(),
        updatedBy: currentUser.uid
      });

      setSuccess('Produto atualizado com sucesso');
      setTimeout(() => {
        navigate('/products');
      }, 2000);
    } catch (err) {
      console.error('Error updating product:', err);
      setError('Erro ao atualizar produto');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center gap-3 mb-6">
          <Package className="text-primary" size={24} />
          <h2 className="text-2xl font-bold text-secondary">Editar Produto</h2>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Images Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Imagens do Produto</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Imagem Principal
              </label>
              <div className="flex items-center gap-4">
                {formData.mainImage ? (
                  <div className="relative">
                    <img
                      src={formData.mainImage}
                      alt="Imagem principal"
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage('main')}
                      className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ) : (
                  <label className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-primary">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file, 'main');
                      }}
                    />
                    <span className="text-sm text-gray-500">Adicionar</span>
                  </label>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Galeria de Imagens (máx. 4)
              </label>
              <div className="flex items-center gap-4">
                {formData.galleryImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`Galeria ${index + 1}`}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage('gallery', index)}
                      className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
                {formData.galleryImages.length < 4 && (
                  <label className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-primary">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file, 'gallery');
                      }}
                    />
                    <span className="text-sm text-gray-500">Adicionar</span>
                  </label>
                )}
              </div>
            </div>
          </div>

          {/* Category and Private Sale */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Categoria</label>
              <div className="mt-1">
                <select
                  value={formData.category}
                  onChange={(e) => {
                    if (e.target.value === 'new' && currentUser?.role === 'admin') {
                      setShowNewCategoryForm(true);
                    } else {
                      setFormData({ ...formData, category: e.target.value });
                    }
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                >
                  <option value="">Selecione uma categoria</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                  {currentUser?.role === 'admin' && (
                    <option value="new">+ Criar Nova Categoria</option>
                  )}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Disponível para Venda Privada
              </label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.isPrivateSale}
                    onChange={(e) => setFormData({ ...formData, isPrivateSale: e.target.checked })}
                    className="rounded border-gray-300 text-primary focus:ring-primary"
                  />
                  <span className="ml-2">Sim</span>
                </label>
              </div>
            </div>
          </div>

          {/* New Category Form */}
          {showNewCategoryForm && currentUser?.role === 'admin' && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="text-sm font-medium text-gray-900 mb-2">Nova Categoria</h4>
              <form onSubmit={handleCreateCategory} className="flex gap-2">
                <input
                  type="text"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="Nome da categoria"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
                <button
                  type="submit"
                  disabled={!newCategoryName.trim()}
                  className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
                >
                  <Plus size={20} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowNewCategoryForm(false);
                    setNewCategoryName('');
                  }}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                >
                  <X size={20} />
                </button>
              </form>
            </div>
          )}

          {/* Product Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Nome do Produto</label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Descrição</label>
              <textarea
                rows={4}
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">SKU</label>
              <input
                type="text"
                required
                value={formData.sku}
                onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">EAN</label>
              <input
                type="text"
                value={formData.ean}
                onChange={(e) => setFormData({ ...formData, ean: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">ID Baselinker</label>
              <input
                type="text"
                value={formData.baselinker_id}
                onChange={(e) => setFormData({ ...formData, baselinker_id: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Quantidade em Stock</label>
              <input
                type="number"
                required
                min="0"
                value={formData.stockQuantity}
                onChange={(e) => setFormData({ ...formData, stockQuantity: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Status do Stock</label>
              <select
                value={formData.stockStatus}
                onChange={(e) => setFormData({ ...formData, stockStatus: e.target.value as 'instock' | 'outofstock' | 'onbackorder' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              >
                <option value="instock">Em Stock</option>
                <option value="outofstock">Fora de Stock</option>
                <option value="onbackorder">Sob Encomenda</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Preço Regular (€)</label>
              <input
                type="number"
                required
                min="0"
                step="0.01"
                value={formData.regularPrice}
                onChange={(e) => setFormData({ ...formData, regularPrice: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Preço Promocional (€)</label>
              <input
                type="number"
                min="0"
                step="0.01"
                value={formData.salePrice}
                onChange={(e) => setFormData({ ...formData, salePrice: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Início do Preço Promocional
              </label>
              <input
                type="datetime-local"
                value={formData.promotionalPeriod.startDate}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  promotionalPeriod: {
                    ...prev.promotionalPeriod,
                    startDate: e.target.value
                  }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Fim do Preço Promocional
              </label>
              <input
                type="datetime-local"
                value={formData.promotionalPeriod.endDate}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  promotionalPeriod: {
                    ...prev.promotionalPeriod,
                    endDate: e.target.value
                  }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Custo do Produto (€)</label>
              <input
                type="number"
                required
                min="0"
                step="0.01"
                value={formData.cost}
                onChange={(e) => setFormData({ ...formData, cost: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Custo de Preparação (€)</label>
              <input
                type="number"
                required
                min="0"
                step="0.01"
                value={formData.preparationCost}
                onChange={(e) => setFormData({ ...formData, preparationCost: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Peso (kg)</label>
              <input
                type="number"
                min="0"
                step="0.001"
                value={formData.weight}
                onChange={(e) => setFormData({ ...formData, weight: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div className="col-span-2 grid grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Comprimento (cm)</label>
                <input
                  type="number"
                  min="0"
                  step="0.1"
                  value={formData.length}
                  onChange={(e) => setFormData({ ...formData, length: Number(e.target.value) })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Largura (cm)</label>
                <input
                  type="number"
                  min="0"
                  step="0.1"
                  value={formData.width}
                  onChange={(e) => setFormData({ ...formData, width: Number(e.target.value) })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Altura (cm)</label>
                <input
                  type="number"
                  min="0"
                  step="0.1"
                  value={formData.height}
                  onChange={(e) => setFormData({ ...formData, height: Number(e.target.value) })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/products')}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              <Save size={20} />
              {loading ? 'Salvando...' : 'Salvar Alterações'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductEdit;