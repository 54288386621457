import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Camera, Save } from 'lucide-react';
import { validateImage, resizeImage } from '../utils/imageUpload';

const validateCoverImage = async (file: File) => {
  if (!file.type.startsWith('image/')) {
    return { isValid: false, error: 'O arquivo deve ser uma imagem' };
  }

  if (file.size > 5 * 1024 * 1024) {
    return { isValid: false, error: 'A imagem deve ter no máximo 5MB' };
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      if (img.width < 1500 || img.height < 500) {
        resolve({ isValid: false, error: 'A imagem deve ter no mínimo 1500x500 pixels' });
      } else {
        resolve({ isValid: true });
      }
    };
    img.onerror = () => {
      resolve({ isValid: false, error: 'Erro ao carregar imagem' });
    };
    img.src = URL.createObjectURL(file);
  });
};

export default function SocialProfileSetup() {
  const [formData, setFormData] = useState({
    displayName: '',
    bio: '',
    phone: '',
  });
  const [coverImage, setCoverImage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.uid) {
      loadProfile();
    }
  }, [currentUser]);

  const loadProfile = async () => {
    if (!currentUser?.uid) return;

    try {
      // Load user data first
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFormData(prev => ({
          ...prev,
          displayName: userData.displayName || ''
        }));
      }

      // Load social profile data
      const socialProfileDoc = await getDoc(doc(db, 'socialProfiles', currentUser.uid));
      if (socialProfileDoc.exists()) {
        const data = socialProfileDoc.data();
        setFormData(prev => ({
          displayName: prev.displayName || data.displayName || '',
          bio: data.bio || '',
          phone: data.phone || '',
        }));
        setCoverImage(data.coverImage || '');
      }
    } catch (err) {
      console.error('Error loading profile:', err);
      setError('Erro ao carregar perfil');
    }
  };

  const handleCoverImageUpload = async (file: File) => {
    try {
      const validation = await validateCoverImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Image = await resizeImage(file);
      setCoverImage(base64Image);
      setError('');
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Erro ao processar imagem');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.uid) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Update user's display name in users collection
      await setDoc(doc(db, 'users', currentUser.uid), {
        displayName: formData.displayName,
        updatedAt: new Date().toISOString()
      }, { merge: true });

      // Save social profile data
      await setDoc(doc(db, 'socialProfiles', currentUser.uid), {
        ...formData,
        coverImage,
        userId: currentUser.uid,
        updatedAt: new Date().toISOString()
      });

      setSuccess('Perfil atualizado com sucesso');
      
      // Navigate to profile page after a short delay
      setTimeout(() => {
        navigate(`/social/profile/${currentUser.uid}`);
      }, 1500);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Erro ao atualizar perfil');
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-secondary mb-6">
          Configurar Perfil Social
        </h2>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Cover Image */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Imagem de Capa
            </label>
            <div className="relative">
              <div className="aspect-[3/1] bg-gray-100 rounded-lg overflow-hidden">
                {coverImage ? (
                  <img
                    src={coverImage}
                    alt="Capa"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gradient-to-br from-primary to-secondary opacity-50" />
                )}
                <label className="absolute bottom-4 right-4 p-2 bg-white rounded-full cursor-pointer hover:bg-gray-50">
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => e.target.files?.[0] && handleCoverImageUpload(e.target.files[0])}
                  />
                  <Camera size={20} className="text-gray-600" />
                </label>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Tamanho mínimo: 1500x500 pixels. Máximo: 5MB
              </p>
            </div>
          </div>

          {/* Profile Info */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Nome
              </label>
              <input
                type="text"
                value={formData.displayName}
                onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Biografia
              </label>
              <textarea
                value={formData.bio}
                onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                WhatsApp
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                placeholder="+351 999 999 999"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/social')}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              <Save size={20} />
              {loading ? 'Salvando...' : 'Salvar Perfil'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}