import { Home, Truck, Mail, Phone } from 'lucide-react';

interface ShippingInfoProps {
  title: string;
  name: string | undefined;
  companyName?: string;
  address: string | undefined;
  city: string | undefined;
  postalCode: string | undefined;
  country: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  method: string;
}

export default function ShippingInfo({
  title,
  name,
  companyName,
  address,
  city,
  postalCode,
  country,
  phone,
  email,
  method
}: ShippingInfoProps) {
  return (
    <div>
      <h3 className="text-lg font-semibold mb-4">
        {method === 'Envio Normal' ? 'O vendedor irá enviar para:' : 'Dados de entrega:'}
      </h3>
      <div className="bg-gray-50 p-6 rounded-lg space-y-4">
        <div className="flex items-start gap-3">
          <Home className="text-gray-400 mt-1" size={20} />
          <div>
            <p className="font-medium">{name}</p>
            {companyName && <p className="text-gray-600">{companyName}</p>}
            <p className="text-gray-600">{address}</p>
            <p className="text-gray-600">
              {postalCode} {city}
            </p>
            <p className="text-gray-600">{country}</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <Phone className="text-gray-400" size={20} />
          <div>
            <p className="font-medium">Telefone</p>
            <p className="text-gray-600">{phone}</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <Mail className="text-gray-400" size={20} />
          <div>
            <p className="font-medium">Email</p>
            <p className="text-gray-600">{email}</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <Truck className="text-gray-400" size={20} />
          <div>
            <p className="font-medium">Método de Envio</p>
            <p className="text-gray-600">{method}</p>
          </div>
        </div>
      </div>
    </div>
  );
}