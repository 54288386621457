import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, Timestamp, or } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { ShoppingCart, Search, Eye, Package, Truck, CheckCircle, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Order {
  id: string;
  orderNumber: string;
  status: string;
  total: number;
  createdAt: string | Timestamp;
  updatedAt: string | Timestamp;
  companyId?: string;
  sellerId?: string;
  isPrivateSale?: boolean;
  customer?: {
    name: string;
    email: string;
    phone: string;
  };
  buyerName?: string;
}

const ORDER_STATUSES = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido'
};

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.companyId) {
      loadOrders();
    }
  }, [currentUser]);

  const loadOrders = async () => {
    if (!currentUser?.companyId) {
      setError('Usuário não autorizado');
      setLoading(false);
      return;
    }

    try {
      const ordersRef = collection(db, 'orders');
      
      // Query for both manual orders and private sale orders
      const ordersQuery = query(
        ordersRef,
        or(
          where('companyId', '==', currentUser.companyId),    // Manual orders
          where('sellerId', '==', currentUser.companyId)      // Private sale orders as seller
        )
      );
      
      const snapshot = await getDocs(ordersQuery);
      
      const ordersData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          // Format timestamps
          createdAt: data.createdAt instanceof Timestamp ? 
            data.createdAt.toDate().toISOString() : 
            data.createdAt,
          updatedAt: data.updatedAt instanceof Timestamp ? 
            data.updatedAt.toDate().toISOString() : 
            data.updatedAt,
          // Get customer name based on order type
          customerName: data.isPrivateSale ? data.buyerName : data.customer?.name
        };
      }) as Order[];
      
      // Sort orders by creation date
      const sortedOrders = ordersData.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA;
      });

      setOrders(sortedOrders);
      setError('');
    } catch (err) {
      console.error('Error loading orders:', err);
      setError('Erro ao carregar encomendas. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'preparation': return 'bg-blue-100 text-blue-800';
      case 'awaitingStock': return 'bg-orange-100 text-orange-800';
      case 'awaitingPickup': return 'bg-purple-100 text-purple-800';
      case 'shipped': return 'bg-indigo-100 text-indigo-800';
      case 'delivered': return 'bg-green-100 text-green-800';
      case 'cancelled': return 'bg-red-100 text-red-800';
      case 'returned': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: string) => {
    return ORDER_STATUSES[status as keyof typeof ORDER_STATUSES] || status;
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'pending': return ShoppingCart;
      case 'preparation': return Package;
      case 'awaitingStock': return Package;
      case 'awaitingPickup': return Package;
      case 'shipped': return Truck;
      case 'delivered': return CheckCircle;
      default: return ShoppingCart;
    }
  };

  const formatDate = (dateString: string | Timestamp) => {
    try {
      const date = dateString instanceof Timestamp ? 
        dateString.toDate() : 
        new Date(dateString);
      
      return date.toLocaleDateString('pt-PT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (err) {
      console.error('Error formatting date:', err);
      return 'Data inválida';
    }
  };

  const getCustomerName = (order: Order) => {
    if (order.isPrivateSale) {
      return order.buyerName;
    }
    return order.customer?.name || '-';
  };

  const filteredOrders = orders.filter(order => {
    const searchLower = searchTerm.toLowerCase();
    const orderNumber = order.orderNumber?.toLowerCase() || '';
    const customerName = getCustomerName(order)?.toLowerCase() || '';
    
    return orderNumber.includes(searchLower) || customerName.includes(searchLower);
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-secondary">Encomendas</h2>
            <button
              onClick={() => navigate('/orders/create')}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark"
            >
              <Plus size={20} />
              Inserir Encomenda
            </button>
          </div>

          {orders.length > 0 && (
            <div className="mb-6 relative">
              <input
                type="text"
                placeholder="Pesquisar por número de encomenda ou cliente..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          )}

          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {error}
            </div>
          )}

          {orders.length === 0 ? (
            <div className="text-center py-12">
              <ShoppingCart className="mx-auto h-12 w-12 text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">Nenhuma encomenda encontrada</h3>
              <p className="text-gray-500 mb-6">
                As suas encomendas aparecerão aqui quando forem criadas.
              </p>
              <button
                onClick={() => navigate('/orders/create')}
                className="inline-flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark"
              >
                <Plus size={20} />
                Inserir Primeira Encomenda
              </button>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nº Encomenda
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cliente
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Tipo
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredOrders.map((order) => {
                    const StatusIcon = getStatusIcon(order.status);
                    return (
                      <tr key={order.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.orderNumber}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {getCustomerName(order)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {formatDate(order.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          €{order.total.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}>
                            <StatusIcon className="w-4 h-4 mr-1" />
                            {getStatusText(order.status)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            order.isPrivateSale ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'
                          }`}>
                            {order.isPrivateSale ? 'Venda Privada' : 'Manual'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => navigate(`/orders/${order.id}`)}
                            className="text-primary hover:text-primary-dark"
                            title="Ver detalhes"
                          >
                            <Eye size={20} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}