import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { WalletProvider } from './contexts/WalletContext';
import { SupportViewProvider } from './contexts/SupportViewContext';
import ErrorBoundary from './components/ErrorBoundary';
import SupportModeIndicator from './components/SupportModeIndicator';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import CompanySetup from './pages/CompanySetup';
import UserManagement from './pages/UserManagement';
import CreateUser from './pages/CreateUser';
import EditUser from './pages/EditUser';
import Profile from './pages/Profile';
import ApiManagement from './pages/ApiManagement';
import Products from './pages/Products';
import ProductCreate from './pages/ProductCreate';
import ProductDetails from './pages/ProductDetails';
import ProductEdit from './pages/ProductEdit';
import Orders from './pages/Orders';
import OrderCreate from './pages/OrderCreate';
import OrderDetails from './pages/OrderDetails';
import PrivateSales from './pages/PrivateSales';
import PrivateSaleDetails from './pages/PrivateSaleDetails';
import PrivateSaleOrders from './pages/PrivateSaleOrders';
import PrivateSalesCheckout from './pages/PrivateSalesCheckout';
import Social from './pages/Social';
import SocialProfile from './pages/SocialProfile';
import SocialProfileSetup from './pages/SocialProfileSetup';
import Moderation from './pages/Moderation';
import ShippingCosts from './pages/ShippingCosts';
import SupportAccess from './pages/SupportAccess';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import Layout from './components/Layout';

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <SupportViewProvider>
          <AuthProvider>
            <WalletProvider>
              <CartProvider>
                <Routes>
                  {/* Public Routes */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />

                  {/* Protected Routes */}
                  <Route path="/profile" element={
                    <PrivateRoute>
                      <Layout>
                        <Profile />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/company-setup" element={
                    <PrivateRoute>
                      <CompanySetup />
                    </PrivateRoute>
                  } />
                  <Route path="/users" element={
                    <PrivateRoute>
                      <Layout>
                        <UserManagement />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/users/create" element={
                    <PrivateRoute>
                      <Layout>
                        <CreateUser />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/users/:id/edit" element={
                    <PrivateRoute>
                      <Layout>
                        <EditUser />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/settings/apis" element={
                    <PrivateRoute>
                      <Layout>
                        <ApiManagement />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/settings/moderation" element={
                    <AdminRoute>
                      <Layout>
                        <Moderation />
                      </Layout>
                    </AdminRoute>
                  } />
                  <Route path="/settings/shipping" element={
                    <AdminRoute>
                      <Layout>
                        <ShippingCosts />
                      </Layout>
                    </AdminRoute>
                  } />
                  <Route path="/settings/support" element={
                    <AdminRoute>
                      <Layout>
                        <SupportAccess />
                      </Layout>
                    </AdminRoute>
                  } />
                  <Route path="/products" element={
                    <PrivateRoute>
                      <Layout>
                        <Products />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/products/create" element={
                    <PrivateRoute>
                      <Layout>
                        <ProductCreate />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/products/:id" element={
                    <PrivateRoute>
                      <Layout>
                        <ProductDetails />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/products/:id/edit" element={
                    <PrivateRoute>
                      <Layout>
                        <ProductEdit />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/orders" element={
                    <PrivateRoute>
                      <Layout>
                        <Orders />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/orders/create" element={
                    <PrivateRoute>
                      <Layout>
                        <OrderCreate />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/orders/:id" element={
                    <PrivateRoute>
                      <Layout>
                        <OrderDetails />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/private-sales" element={
                    <PrivateRoute>
                      <Layout>
                        <PrivateSales />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/private-sales/orders" element={
                    <PrivateRoute>
                      <Layout>
                        <PrivateSaleOrders />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/private-sales/:id" element={
                    <PrivateRoute>
                      <Layout>
                        <PrivateSaleDetails />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/private-sales/checkout" element={
                    <PrivateRoute>
                      <Layout>
                        <PrivateSalesCheckout />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/social" element={
                    <PrivateRoute>
                      <Layout>
                        <Social />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/social/profile/:userId" element={
                    <PrivateRoute>
                      <Layout>
                        <SocialProfile />
                      </Layout>
                    </PrivateRoute>
                  } />
                  <Route path="/social/profile-setup" element={
                    <PrivateRoute>
                      <Layout>
                        <SocialProfileSetup />
                      </Layout>
                    </PrivateRoute>
                  } />

                  {/* Default Route */}
                  <Route path="/" element={
                    <PrivateRoute>
                      <Layout>
                        <Dashboard />
                      </Layout>
                    </PrivateRoute>
                  } />

                  {/* Catch-all Route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <SupportModeIndicator />
              </CartProvider>
            </WalletProvider>
          </AuthProvider>
        </SupportViewProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;