import { useState } from 'react';

interface OrderActionsProps {
  status: string;
  isSeller: boolean;
  isBuyer: boolean;
  orderId: string;
  buyerCompanyId: string;
  total: number;
  onCancel: () => void;
  onUpdateStatus: (newStatus: string) => void;
  updating: boolean;
}

export const ORDER_STATUSES = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido'
} as const;

export default function OrderActions({
  status,
  isSeller,
  isBuyer,
  orderId,
  buyerCompanyId,
  onCancel,
  total,
  onUpdateStatus,
  updating
}: OrderActionsProps) {
  const [error, setError] = useState('');
  
  // Verifica se a encomenda está em um estado que não pode ser alterado
  const isImmutableStatus = status === 'cancelled' || status === 'returned';

  const getAvailableStatuses = () => {
    if (!isSeller || isImmutableStatus) return [];

    // Define as transições permitidas para cada estado
    const transitions: Record<string, string[]> = {
      pending: ['preparation', 'awaitingStock'],
      preparation: ['awaitingStock', 'awaitingPickup'],
      awaitingStock: ['preparation'],
      awaitingPickup: ['shipped'],
      shipped: ['delivered'],
      delivered: ['returned'],
      returned: [],
      cancelled: []
    };

    return transitions[status] || [];
  };

  const canCancel = () => {
    if (isImmutableStatus) return false;

    // Comprador só pode cancelar se estiver pendente
    if (isBuyer) {
      return status === 'pending';
    }

    // Vendedor pode cancelar nos estados iniciais
    if (isSeller) {
      return ['pending', 'preparation', 'awaitingStock'].includes(status);
    }

    return false;
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-4">Ações</h3>
      <div className="bg-gray-50 p-6 rounded-lg space-y-3">
        {error && (
          <div className="text-red-600 text-sm mb-4">
            {error}
          </div>
        )}

        {isImmutableStatus ? (
          <div className="text-gray-600 text-sm">
            Esta encomenda não pode ser alterada pois já foi {status === 'cancelled' ? 'cancelada' : 'devolvida'}.
          </div>
        ) : (
          <>
            {/* Botões de mudança de estado */}
            {getAvailableStatuses().map((nextStatus) => (
              <button
                key={nextStatus}
                onClick={() => onUpdateStatus(nextStatus)}
                disabled={updating}
                className="w-full py-2 px-4 rounded-md bg-primary text-secondary hover:bg-primary-dark disabled:opacity-50"
              >
                {ORDER_STATUSES[nextStatus as keyof typeof ORDER_STATUSES]}
              </button>
            ))}

            {/* Botão de cancelamento */}
            {canCancel() && (
              <button
                onClick={onCancel}
                disabled={updating}
                className="w-full py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
              >
                Cancelar Encomenda
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}