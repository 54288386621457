import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  User as FirebaseUser
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db, restoreAuthState } from '../lib/firebase';
import { User } from '../types';
import { useSupportView } from './SupportViewContext';

interface AuthContextType {
  currentUser: (FirebaseUser & Partial<User>) | null;
  effectiveUser: (FirebaseUser & Partial<User>) | null;
  isAdmin: boolean;
  isSupportMode: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<(FirebaseUser & Partial<User>) | null>(null);
  const [effectiveUser, setEffectiveUser] = useState<(FirebaseUser & Partial<User>) | null>(null);
  const [loading, setLoading] = useState(true);
  const { supportView } = useSupportView();

  useEffect(() => {
    // Try to restore auth state from stored token
    const initAuth = async () => {
      try {
        await restoreAuthState();
      } catch (err) {
        console.error('Error restoring auth state:', err);
      }
    };
    initAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          
          if (userDoc.exists()) {
            const userData = userDoc.data() as Partial<User>;
            const fullUser = {
              ...user,
              ...userData
            };
            setCurrentUser(fullUser);

            // If in support mode, load the target user's data
            if (supportView?.userId) {
              const targetUserDoc = await getDoc(doc(db, 'users', supportView.userId));
              if (targetUserDoc.exists()) {
                const targetUserData = targetUserDoc.data() as Partial<User>;
                setEffectiveUser({
                  ...user,
                  ...targetUserData,
                  uid: supportView.userId,
                  companyId: supportView.companyId,
                  email: targetUserData.email || '',
                  displayName: targetUserData.displayName
                });
              }
            } else {
              setEffectiveUser(fullUser);
            }
          } else {
            const defaultUser = {
              ...user,
              role: 'gerente', // Default role for new users
              onboardingCompleted: false
            };
            setCurrentUser(defaultUser);
            setEffectiveUser(defaultUser);
          }
        } else {
          setCurrentUser(null);
          setEffectiveUser(null);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setCurrentUser(null);
        setEffectiveUser(null);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [supportView]);

  async function login(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }

      const userData = userDoc.data() as Partial<User>;
      const fullUser = {
        ...userCredential.user,
        ...userData
      };
      setCurrentUser(fullUser);
      setEffectiveUser(fullUser);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async function register(email: string, password: string) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document with role 'gerente'
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        role: 'gerente',
        createdAt: new Date().toISOString(),
        onboardingCompleted: false
      });

      return userCredential;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }

  async function resetPassword(email: string) {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  }

  async function logout() {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setEffectiveUser(null);
      localStorage.removeItem('lognow_auth_token');
      localStorage.removeItem('support_view');
      sessionStorage.removeItem('supportSession');
      localStorage.removeItem('adminSession');
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  }

  const value = {
    currentUser,
    effectiveUser,
    isAdmin: currentUser?.role === 'admin',
    isSupportMode: !!supportView,
    loading,
    login,
    register,
    logout,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}