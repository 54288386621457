import { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { X, Share2 } from 'lucide-react';

interface ShareModalProps {
  postId: string;
  originalPost: {
    content?: string;
    images?: string[];
    products?: any[];
    user: {
      id: string;
      name: string;
    };
  };
  onClose: () => void;
}

export default function ShareModal({ postId, originalPost, onClose }: ShareModalProps) {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleShare = async () => {
    if (!currentUser) return;

    setLoading(true);
    try {
      // Create a new post that references the original
      await addDoc(collection(db, 'social_posts'), {
        content: comment.trim(),
        sharedPost: {
          id: postId,
          content: originalPost.content || '',
          images: originalPost.images || [],
          products: originalPost.products || [],
          originalAuthor: {
            id: originalPost.user.id,
            name: originalPost.user.name
          }
        },
        userId: currentUser.uid,
        createdAt: Timestamp.now(),
        likes: [],
        comments: [],
        images: [],
        products: []
      });

      onClose();
    } catch (err) {
      console.error('Error sharing post:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Compartilhar Publicação</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-4">
          {/* Preview of original post */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm text-gray-500 mb-2">
              Publicação original de {originalPost.user.name}
            </p>
            <p className="text-gray-700 line-clamp-3">{originalPost.content}</p>
            {originalPost.images && originalPost.images.length > 0 && (
              <img
                src={originalPost.images[0]}
                alt="Preview"
                className="mt-2 rounded-lg max-h-32 object-cover"
              />
            )}
          </div>

          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Adicione um comentário ao compartilhar..."
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
          />

          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              onClick={handleShare}
              disabled={loading}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              <Share2 size={20} />
              {loading ? 'Compartilhando...' : 'Compartilhar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}