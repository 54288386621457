import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Building2, MapPin, Phone, Mail, Clock } from 'lucide-react';
import PostList from '../components/social/PostList';
import ProductGrid from '../components/social/ProductGrid';
import ErrorBoundary from '../components/ErrorBoundary';

interface Profile {
  id: string;
  displayName?: string;
  email?: string;
  photoBase64?: string;
  company?: {
    commercialName: string;
    commercialEmail: string;
    commercialPhone: string;
    address: string;
    workingHours: string;
  };
}

interface SocialProfile {
  bio?: string;
  coverImage?: string;
  phone?: string;
  location?: string;
}

interface Post {
  id: string;
  content?: string;
  images?: string[];
  products?: any[];
  likes?: string[];
  comments?: any[];
  createdAt: string;
  user: {
    id: string;
    name: string;
    photo?: string | null;
    companyName?: string | null;
  };
}

export default function SocialProfile() {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [socialProfile, setSocialProfile] = useState<SocialProfile | null>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState<'posts' | 'products'>('posts');
  const { userId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      loadData();
    }
  }, [userId]);

  const loadData = async () => {
    if (!userId) return;

    try {
      setLoading(true);
      setError('');

      // Load user profile
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('Usuário não encontrado');
      }

      const userData = userDoc.data();

      // Load company data if exists
      let companyData = null;
      if (userData.companyId) {
        const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
        if (companyDoc.exists()) {
          companyData = companyDoc.data();
        }
      }

      // Set profile data
      setProfile({
        id: userDoc.id,
        displayName: userData.displayName,
        email: companyData?.commercialEmail || userData.email,
        photoBase64: userData.photoBase64,
        company: companyData ? {
          commercialName: companyData.commercialName,
          commercialEmail: companyData.commercialEmail,
          commercialPhone: companyData.commercialPhone,
          address: companyData.address,
          workingHours: companyData.workingHours
        } : undefined
      });

      // Load social profile
      const socialProfileDoc = await getDoc(doc(db, 'socialProfiles', userId));

      // If viewing own profile and no social profile exists, redirect to setup
      if (userId === currentUser?.uid && !socialProfileDoc.exists()) {
        navigate('/social/profile-setup');
        return;
      }

      // Set social profile data if it exists
      if (socialProfileDoc.exists()) {
        setSocialProfile(socialProfileDoc.data() as SocialProfile);
      }

      // Load posts
      const postsQuery = query(
        collection(db, 'social_posts'),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );
      
      const postsSnapshot = await getDocs(postsQuery);
      const postsData = postsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate?.() || new Date(data.createdAt),
          user: {
            id: userId,
            name: userData.displayName || userData.email?.split('@')[0] || 'Usuário',
            photo: userData.photoBase64,
            companyName: companyData?.commercialName
          }
        };
      }) as Post[];
      
      setPosts(postsData);

      // Load products if company exists
      if (userData.companyId) {
        const productsQuery = query(
          collection(db, 'products'),
          where('companyId', '==', userData.companyId)
        );
        
        const productsSnapshot = await getDocs(productsQuery);
        const productsData = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setProducts(productsData);
      }

      setError('');
    } catch (err) {
      console.error('Error loading profile data:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erro ao carregar dados do perfil');
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="text-center text-red-600">{error}</div>
          {currentUser?.uid === userId && (
            <div className="mt-4 text-center">
              <button
                onClick={() => navigate('/social/profile-setup')}
                className="px-4 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark"
              >
                Configurar Perfil Social
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!profile) return null;

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Cover Photo */}
      <div className="relative h-[300px] rounded-t-lg overflow-hidden bg-gray-100">
        {socialProfile?.coverImage ? (
          <img
            src={socialProfile.coverImage}
            alt="Capa"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-primary to-secondary opacity-50" />
        )}
      </div>

      {/* Profile Info */}
      <div className="bg-white rounded-b-lg shadow-md">
        <div className="px-6 py-4">
          <div className="flex items-start gap-6">
            {/* Profile Photo */}
            <div className="relative -mt-16">
              {profile.photoBase64 ? (
                <img
                  src={profile.photoBase64}
                  alt={profile.displayName}
                  className="w-32 h-32 rounded-full border-4 border-white object-cover"
                />
              ) : (
                <div className="w-32 h-32 rounded-full border-4 border-white bg-gray-100 flex items-center justify-center">
                  <Building2 className="w-16 h-16 text-gray-400" />
                </div>
              )}
            </div>

            {/* Profile Details */}
            <div className="flex-1 pt-4">
              <h1 className="text-2xl font-bold text-gray-900">
                {profile.company?.commercialName || profile.displayName}
              </h1>

              {profile.company && (
                <div className="space-y-2 mt-4">
                  <div className="flex items-center gap-2 text-gray-600">
                    <Mail size={16} />
                    <a href={`mailto:${profile.company.commercialEmail}`} className="hover:text-primary">
                      {profile.company.commercialEmail}
                    </a>
                  </div>

                  <div className="flex items-center gap-2 text-gray-600">
                    <Phone size={16} />
                    <a href={`tel:${profile.company.commercialPhone}`} className="hover:text-primary">
                      {profile.company.commercialPhone}
                    </a>
                  </div>

                  {profile.company.address && (
                    <div className="flex items-start gap-2 text-gray-600">
                      <MapPin size={16} className="mt-1" />
                      <span>{profile.company.address}</span>
                    </div>
                  )}

                  {profile.company.workingHours && (
                    <div className="flex items-center gap-2 text-gray-600">
                      <Clock size={16} />
                      <span>{profile.company.workingHours}</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Edit Profile Button */}
            {currentUser?.uid === userId && (
              <button
                onClick={() => navigate('/social/profile-setup')}
                className="px-4 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark"
              >
                Editar Perfil
              </button>
            )}
          </div>

          {/* Bio */}
          {socialProfile?.bio && (
            <div className="mt-6">
              <p className="text-gray-600 whitespace-pre-wrap">{socialProfile.bio}</p>
            </div>
          )}
        </div>

        {/* Tabs */}
        <div className="border-t">
          <div className="flex">
            <button
              onClick={() => setActiveTab('posts')}
              className={`flex-1 py-3 text-center font-medium ${
                activeTab === 'posts'
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Posts
            </button>
            <button
              onClick={() => setActiveTab('products')}
              className={`flex-1 py-3 text-center font-medium ${
                activeTab === 'products'
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Produtos
            </button>
          </div>

          <div className="p-6">
            <ErrorBoundary>
              {activeTab === 'posts' ? (
                <PostList 
                  posts={posts} 
                  onLoadMore={() => {}} 
                  hasMore={false} 
                  loading={false} 
                />
              ) : (
                <ProductGrid products={products} />
              )}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}