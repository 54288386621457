import { useState } from 'react';
import { Camera, MapPin, Phone, Upload, X } from 'lucide-react';
import { validateImage, resizeImage } from '../../../utils/imageUpload';

interface SocialProfileStepProps {
  onSubmit: (data: {
    displayName: string;
    bio: string;
    coverImage: string | null;
    phone: string;
    location: string;
  }) => void;
  initialData: {
    displayName: string;
    bio: string;
    coverImage: string | null;
    phone: string;
    location: string;
  };
}

export default function SocialProfileStep({ onSubmit, initialData }: SocialProfileStepProps) {
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState('');

  const handleCoverImageUpload = async (file: File) => {
    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Image = await resizeImage(file);
      setFormData({ ...formData, coverImage: base64Image });
      setError('');
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Erro ao processar imagem');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.displayName) {
      setError('Por favor, preencha seu nome de exibição');
      return;
    }
    onSubmit(formData);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-secondary mb-6">
        Perfil Social
      </h2>

      {error && (
        <div className="mb-4 text-red-600 text-sm">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Cover Image */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Imagem de Capa
          </label>
          <div className="relative">
            <div className="aspect-[3/1] bg-gray-100 rounded-lg overflow-hidden">
              {formData.coverImage ? (
                <>
                  <img
                    src={formData.coverImage}
                    alt="Capa"
                    className="w-full h-full object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, coverImage: null })}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <X size={16} />
                  </button>
                </>
              ) : (
                <div className="w-full h-full bg-gradient-to-br from-primary to-secondary opacity-50" />
              )}
              <label className="absolute bottom-4 right-4 p-2 bg-white rounded-full cursor-pointer hover:bg-gray-50">
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => e.target.files?.[0] && handleCoverImageUpload(e.target.files[0])}
                />
                <Camera size={20} className="text-gray-600" />
              </label>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Tamanho recomendado: 1500x500 pixels
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nome de Exibição *
            </label>
            <input
              type="text"
              required
              value={formData.displayName}
              onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Biografia
            </label>
            <textarea
              rows={4}
              value={formData.bio}
              onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              placeholder="Conte um pouco sobre você ou sua empresa..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              WhatsApp
            </label>
            <div className="mt-1 relative">
              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                placeholder="+351 999 999 999"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Localização
            </label>
            <div className="mt-1 relative">
              <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                placeholder="Ex: Lisboa, Portugal"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end pt-6">
          <button
            type="submit"
            className="px-6 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark"
          >
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}