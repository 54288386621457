import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import TermsStep from './steps/TermsStep';
import CompanyStep from './steps/CompanyStep';
import SocialProfileStep from './steps/SocialProfileStep';
import SystemProfileStep from './steps/SystemProfileStep';
import { CheckCircle } from 'lucide-react';

export default function OnboardingFlow() {
  const [currentStep, setCurrentStep] = useState(1);
  const [acceptedTerms, setAcceptedTerms] = useState({
    general: false,
    marketplace: false,
    social: false,
    privacy: false
  });
  const [companyData, setCompanyData] = useState({
    legalName: '',
    commercialName: '',
    address: '',
    workingHours: '',
    commercialEmail: '',
    commercialPhone: '',
    logo: null as string | null
  });
  const [socialProfile, setSocialProfile] = useState({
    displayName: '',
    bio: '',
    coverImage: null as string | null,
    phone: '',
    location: ''
  });
  const [systemProfile, setSystemProfile] = useState({
    displayName: '',
    email: '',
    phone: '',
    role: 'gerente'
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleTermsAccept = (terms: typeof acceptedTerms) => {
    setAcceptedTerms(terms);
    setCurrentStep(2);
  };

  const handleCompanySubmit = (data: typeof companyData) => {
    setCompanyData(data);
    setCurrentStep(3);
  };

  const handleSocialProfileSubmit = (data: typeof socialProfile) => {
    setSocialProfile(data);
    setCurrentStep(4);
  };

  const handleSystemProfileSubmit = async (data: typeof systemProfile) => {
    if (!currentUser) return;

    setLoading(true);
    setError('');

    try {
      // Save company data
      const companyRef = doc(db, 'companies', currentUser.uid);
      await setDoc(companyRef, {
        ...companyData,
        ownerId: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      // Save social profile
      const socialProfileRef = doc(db, 'socialProfiles', currentUser.uid);
      await setDoc(socialProfileRef, {
        ...socialProfile,
        userId: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      // Save user profile and preferences
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, {
        ...data,
        companyId: currentUser.uid,
        acceptedTerms,
        onboardingCompleted: true,
        role: 'gerente', // Ensure role is set as gerente
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }, { merge: true });

      // Create initial wallet
      const walletRef = doc(db, 'wallets', currentUser.uid);
      await setDoc(walletRef, {
        balance: 0,
        companyId: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      // Navigate to dashboard
      navigate('/');
    } catch (err) {
      console.error('Error saving onboarding data:', err);
      setError('Erro ao salvar dados. Por favor, tente novamente.');
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <TermsStep onSubmit={handleTermsAccept} initialTerms={acceptedTerms} />;
      case 2:
        return <CompanyStep onSubmit={handleCompanySubmit} initialData={companyData} />;
      case 3:
        return <SocialProfileStep onSubmit={handleSocialProfileSubmit} initialData={socialProfile} />;
      case 4:
        return (
          <SystemProfileStep 
            onSubmit={handleSystemProfileSubmit} 
            initialData={systemProfile}
            loading={loading}
            error={error}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-secondary-light to-secondary py-12 px-4">
      <div className="max-w-4xl mx-auto">
        {/* Progress Steps */}
        <div className="mb-8">
          <div className="flex justify-between items-center">
            {[1, 2, 3, 4].map((step) => (
              <div
                key={step}
                className={`flex items-center ${
                  step < 4 ? 'flex-1' : ''
                }`}
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    step <= currentStep
                      ? 'bg-primary text-secondary'
                      : 'bg-gray-300 text-gray-600'
                  }`}
                >
                  {step < currentStep ? (
                    <CheckCircle size={20} />
                  ) : (
                    step
                  )}
                </div>
                {step < 4 && (
                  <div
                    className={`flex-1 h-1 mx-2 ${
                      step < currentStep ? 'bg-primary' : 'bg-gray-300'
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-2">
            <span className="text-white text-sm">Termos</span>
            <span className="text-white text-sm">Empresa</span>
            <span className="text-white text-sm">Perfil Social</span>
            <span className="text-white text-sm">Perfil Sistema</span>
          </div>
        </div>

        {/* Current Step Content */}
        <div className="bg-white rounded-lg shadow-xl p-8">
          {renderStep()}
        </div>
      </div>
    </div>
  );
}