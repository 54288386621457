import { useState } from 'react';
import { AlertTriangle, X } from 'lucide-react';

interface CancelOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: CancelReason) => Promise<void>;
  isSeller: boolean;
  loading: boolean;
  orderTotal: number;
  commission: number;
}

export const CANCEL_REASONS = {
  BUYER_REQUEST: 'buyer_request',
  OUT_OF_STOCK: 'out_of_stock',
  SELLER_CANCEL: 'seller_cancel'
} as const;

export type CancelReason = typeof CANCEL_REASONS[keyof typeof CANCEL_REASONS];

const CANCEL_REASON_LABELS = {
  [CANCEL_REASONS.BUYER_REQUEST]: 'Comprador solicitou cancelamento',
  [CANCEL_REASONS.OUT_OF_STOCK]: 'Falta de stock',
  [CANCEL_REASONS.SELLER_CANCEL]: 'Quero cancelar a encomenda'
} as const;

export default function CancelOrderModal({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isSeller,
  loading,
  orderTotal,
  commission
}: CancelOrderModalProps) {
  const [selectedReason, setSelectedReason] = useState<CancelReason | ''>('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleConfirm = async () => {
    if (!selectedReason) {
      setError('Por favor, selecione um motivo para o cancelamento');
      return;
    }

    try {
      await onConfirm(selectedReason);
      onClose();
    } catch (err) {
      console.error('Error cancelling order:', err);
      setError('Erro ao cancelar encomenda');
    }
  };

  const getRefundDetails = () => {
    if (selectedReason === CANCEL_REASONS.BUYER_REQUEST) {
      return (
        <div className="mt-4 p-4 bg-blue-50 rounded-lg">
          <p className="text-sm text-blue-800">
            <strong>Ação:</strong> Devolução total ao comprador
          </p>
          <p className="text-sm text-blue-800 mt-1">
            • O comprador receberá: €{orderTotal.toFixed(2)}
          </p>
          <p className="text-sm text-blue-800 mt-1">
            • Será revertido do saldo do vendedor: €{(orderTotal - commission).toFixed(2)}
          </p>
          <p className="text-sm text-blue-800 mt-1">
            • A comissão será devolvida: €{commission.toFixed(2)}
          </p>
        </div>
      );
    }

    if (selectedReason === CANCEL_REASONS.OUT_OF_STOCK || selectedReason === CANCEL_REASONS.SELLER_CANCEL) {
      return (
        <div className="mt-4 p-4 bg-yellow-50 rounded-lg">
          <p className="text-sm text-yellow-800">
            <strong>Ação:</strong> Devolução ao comprador e penalização do vendedor
          </p>
          <p className="text-sm text-yellow-800 mt-1">
            • O comprador receberá: €{orderTotal.toFixed(2)}
          </p>
          <p className="text-sm text-yellow-800 mt-1">
            • Será debitado do saldo do vendedor: €{(orderTotal + commission).toFixed(2)}
          </p>
          <p className="text-sm text-yellow-800 mt-1">
            • A comissão será mantida como penalização: €{commission.toFixed(2)}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3 text-red-600">
            <AlertTriangle size={24} />
            <h3 className="text-lg font-semibold">Cancelar Encomenda</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        {error && (
          <div className="mb-4 text-red-600 text-sm">
            {error}
          </div>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Motivo do Cancelamento
          </label>
          <select
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value as CancelReason)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
          >
            <option value="">Selecione um motivo</option>
            {Object.entries(CANCEL_REASONS).map(([key, value]) => (
              <option key={value} value={value}>
                {CANCEL_REASON_LABELS[value]}
              </option>
            ))}
          </select>
        </div>

        {getRefundDetails()}

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            onClick={handleConfirm}
            disabled={loading || !selectedReason}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
          >
            {loading ? 'Processando...' : 'Confirmar Cancelamento'}
          </button>
        </div>
      </div>
    </div>
  );
}