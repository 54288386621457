import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, limit, doc, getDoc, Timestamp, onSnapshot, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Users } from 'lucide-react';
import CreatePost from '../components/social/CreatePost';
import PostList from '../components/social/PostList';

const POSTS_PER_PAGE = 10;

interface Post {
  id: string;
  content: string;
  createdAt: Date;
  images: string[];
  products: any[];
  likes: string[];
  comments: any[];
  userId: string;
  user: {
    id: string;
    name: string;
    photoBase64: string | null;
    companyName: string | null;
  };
  sharedPost?: {
    id: string;
    content: string;
    images: string[];
    products: any[];
    originalAuthor: {
      id: string;
      name: string;
    };
  };
}

export default function Social() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lastPost, setLastPost] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    checkSocialProfile();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = setupRealtimeListener();
      return () => unsubscribe();
    }
  }, [currentUser]);

  const checkSocialProfile = async () => {
    if (!currentUser) return;

    try {
      const profileDoc = await getDoc(doc(db, 'socialProfiles', currentUser.uid));
      if (!profileDoc.exists()) {
        navigate('/social/profile-setup');
      }
    } catch (err) {
      console.error('Error checking social profile:', err);
    }
  };

  const setupRealtimeListener = () => {
    const postsQuery = query(
      collection(db, 'social_posts'),
      orderBy('createdAt', 'desc'),
      limit(POSTS_PER_PAGE)
    );

    return onSnapshot(postsQuery, async (snapshot) => {
      try {
        const processedPosts: Post[] = [];
        const processedIds = new Set<string>();

        for (const change of snapshot.docChanges()) {
          const postData = change.doc.data();
          
          // Skip if we've already processed this post
          if (processedIds.has(change.doc.id)) continue;
          processedIds.add(change.doc.id);

          try {
            const userDoc = await getDoc(doc(db, 'users', postData.userId));
            if (!userDoc.exists()) continue;

            const userData = userDoc.data();
            
            let companyName = null;
            if (userData.companyId) {
              const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
              if (companyDoc.exists()) {
                companyName = companyDoc.data().commercialName;
              }
            }

            let sharedPostData = undefined;
            if (postData.sharedPost) {
              sharedPostData = {
                ...postData.sharedPost,
                images: postData.sharedPost.images || [],
                products: postData.sharedPost.products || []
              };
            }

            const post: Post = {
              id: change.doc.id,
              content: postData.content || '',
              createdAt: postData.createdAt instanceof Timestamp ? 
                postData.createdAt.toDate() : new Date(postData.createdAt),
              images: Array.isArray(postData.images) ? postData.images : [],
              products: Array.isArray(postData.products) ? postData.products : [],
              likes: Array.isArray(postData.likes) ? postData.likes : [],
              comments: Array.isArray(postData.comments) ? postData.comments : [],
              userId: postData.userId,
              user: {
                id: postData.userId,
                name: userData.displayName || userData.email?.split('@')[0] || 'Usuário',
                photoBase64: userData.photoBase64 || null,
                companyName
              },
              sharedPost: sharedPostData
            };

            if (change.type === 'added') {
              processedPosts.push(post);
            } else if (change.type === 'modified') {
              setPosts(prev => prev.map(p => p.id === post.id ? post : p));
            } else if (change.type === 'removed') {
              setPosts(prev => prev.filter(p => p.id !== post.id));
            }
          } catch (err) {
            console.error('Error processing post:', err);
            continue;
          }
        }

        if (processedPosts.length > 0) {
          setPosts(prev => {
            const newPosts = [...processedPosts, ...prev];
            // Remove duplicates and sort by date
            return Array.from(new Map(newPosts.map(post => [post.id, post])).values())
              .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
          });
        }

        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === POSTS_PER_PAGE);
        setError('');
      } catch (err) {
        console.error('Error processing posts:', err);
        setError('Erro ao carregar posts');
      } finally {
        setLoading(false);
      }
    });
  };

  const loadMorePosts = async () => {
    if (!lastPost || loading) return;

    try {
      const postsQuery = query(
        collection(db, 'social_posts'),
        orderBy('createdAt', 'desc'),
        limit(POSTS_PER_PAGE),
        where('createdAt', '<', lastPost.data().createdAt)
      );

      const snapshot = await getDocs(postsQuery);
      const processedPosts: Post[] = [];

      for (const postDoc of snapshot.docs) {
        const postData = postDoc.data();
        
        const userDoc = await getDoc(doc(db, 'users', postData.userId));
        if (!userDoc.exists()) continue;

        const userData = userDoc.data();
        
        let companyName = null;
        if (userData.companyId) {
          const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
          if (companyDoc.exists()) {
            companyName = companyDoc.data().commercialName;
          }
        }

        let sharedPostData = undefined;
        if (postData.sharedPost) {
          sharedPostData = {
            ...postData.sharedPost,
            images: postData.sharedPost.images || [],
            products: postData.sharedPost.products || []
          };
        }

        const post: Post = {
          id: postDoc.id,
          content: postData.content || '',
          createdAt: postData.createdAt instanceof Timestamp ? 
            postData.createdAt.toDate() : new Date(postData.createdAt),
          images: Array.isArray(postData.images) ? postData.images : [],
          products: Array.isArray(postData.products) ? postData.products : [],
          likes: Array.isArray(postData.likes) ? postData.likes : [],
          comments: Array.isArray(postData.comments) ? postData.comments : [],
          userId: postData.userId,
          user: {
            id: postData.userId,
            name: userData.displayName || userData.email?.split('@')[0] || 'Usuário',
            photoBase64: userData.photoBase64 || null,
            companyName
          },
          sharedPost: sharedPostData
        };

        processedPosts.push(post);
      }

      setPosts(prev => [...prev, ...processedPosts]);
      setLastPost(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === POSTS_PER_PAGE);
    } catch (err) {
      console.error('Error loading more posts:', err);
      setError('Erro ao carregar mais posts');
    }
  };

  const handlePostCreated = () => {
    // Refresh posts after a new post is created
    setupRealtimeListener();
  };

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold text-secondary flex items-center gap-2">
          <Users className="text-primary" />
          Social
        </h2>
        <button
          onClick={() => navigate(`/social/profile/${currentUser?.uid}`)}
          className="px-4 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark"
        >
          Ver meu perfil
        </button>
      </div>

      <CreatePost onPostCreated={handlePostCreated} />

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      <PostList 
        posts={posts} 
        onLoadMore={loadMorePosts}
        hasMore={hasMore} 
        loading={loading} 
      />
    </div>
  );
}