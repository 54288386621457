import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Key, Save, Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ApiKeys {
  baselinker?: string;
  ifthenpay?: {
    mbKey: string;
    mbWayKey: string;
  };
}

export default function ApiManagement() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showKeys, setShowKeys] = useState<Record<string, boolean>>({});
  const [apiKeys, setApiKeys] = useState<ApiKeys>({
    baselinker: '',
    ifthenpay: {
      mbKey: '',
      mbWayKey: ''
    }
  });

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    loadApiKeys();
  }, [currentUser]);

  const loadApiKeys = async () => {
    if (!currentUser?.companyId) return;

    try {
      const companyDoc = await getDoc(doc(db, 'companies', currentUser.companyId));
      if (companyDoc.exists()) {
        const data = companyDoc.data();
        setApiKeys({
          baselinker: data.apiKeys?.baselinker || '',
          ifthenpay: data.apiKeys?.ifthenpay || {
            mbKey: '',
            mbWayKey: ''
          }
        });
      }
    } catch (err) {
      console.error('Error loading API keys:', err);
      setError('Erro ao carregar chaves de API');
    }
  };

  const validateIfthenpayKey = (key: string) => {
    const regex = /^[A-Z]{3}-\d{6}$/;
    return key === '' || regex.test(key);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.companyId) return;

    setLoading(true);
    setError('');
    setSuccess('');

    // Validate IFTHENPAY key formats
    if (apiKeys.ifthenpay?.mbKey && !validateIfthenpayKey(apiKeys.ifthenpay.mbKey)) {
      setError('Chave Multibanco IFTHENPAY inválida. Formato correto: ZZZ-000000');
      setLoading(false);
      return;
    }

    if (apiKeys.ifthenpay?.mbWayKey && !validateIfthenpayKey(apiKeys.ifthenpay.mbWayKey)) {
      setError('Chave MBWay IFTHENPAY inválida. Formato correto: ZZZ-000000');
      setLoading(false);
      return;
    }

    try {
      await updateDoc(doc(db, 'companies', currentUser.companyId), {
        apiKeys,
        updatedAt: new Date().toISOString()
      });

      setSuccess('Chaves de API atualizadas com sucesso');
    } catch (err) {
      console.error('Error updating API keys:', err);
      setError('Erro ao atualizar chaves de API');
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser?.companyId) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-8 text-center">
          <div className="text-red-600 mb-4">
            Você precisa ter uma empresa configurada para gerenciar APIs
          </div>
          <button
            onClick={() => navigate('/company-setup')}
            className="bg-primary text-secondary px-4 py-2 rounded-md hover:bg-primary-dark"
          >
            Configurar Empresa
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center gap-3 mb-6">
          <Key className="text-primary" size={24} />
          <h2 className="text-2xl font-bold text-secondary">Gerenciar APIs</h2>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* IFTHENPAY Section */}
          <div className="space-y-4 pt-4 border-t">
            <h3 className="text-lg font-semibold text-gray-900">IFTHENPAY</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Chave Multibanco
                </label>
                <div className="relative">
                  <input
                    type={showKeys.ifthenpayMb ? 'text' : 'password'}
                    value={apiKeys.ifthenpay?.mbKey}
                    onChange={(e) => setApiKeys({
                      ...apiKeys,
                      ifthenpay: { ...apiKeys.ifthenpay!, mbKey: e.target.value }
                    })}
                    className="block w-full pr-10 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                    placeholder="ZZZ-000000"
                  />
                  <button
                    type="button"
                    onClick={() => setShowKeys({ ...showKeys, ifthenpayMb: !showKeys.ifthenpayMb })}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {showKeys.ifthenpayMb ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Chave MBWay
                </label>
                <div className="relative">
                  <input
                    type={showKeys.ifthenpayMbway ? 'text' : 'password'}
                    value={apiKeys.ifthenpay?.mbWayKey}
                    onChange={(e) => setApiKeys({
                      ...apiKeys,
                      ifthenpay: { ...apiKeys.ifthenpay!, mbWayKey: e.target.value }
                    })}
                    className="block w-full pr-10 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                    placeholder="ZZZ-000000"
                  />
                  <button
                    type="button"
                    onClick={() => setShowKeys({ ...showKeys, ifthenpayMbway: !showKeys.ifthenpayMbway })}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {showKeys.ifthenpayMbway ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Baselinker Section */}
          <div className="space-y-4 pt-4 border-t">
            <h3 className="text-lg font-semibold text-gray-900">Baselinker</h3>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                API Key
              </label>
              <div className="relative">
                <input
                  type={showKeys.baselinker ? 'text' : 'password'}
                  value={apiKeys.baselinker}
                  onChange={(e) => setApiKeys({ ...apiKeys, baselinker: e.target.value })}
                  className="block w-full pr-10 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  placeholder="8000000-8000000-XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                />
                <button
                  type="button"
                  onClick={() => setShowKeys({ ...showKeys, baselinker: !showKeys.baselinker })}
                  className="absolute inset-y-0 right-0 px-3 flex items-center"
                >
                  {showKeys.baselinker ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-6">
            <button
              type="submit"
              disabled={loading}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              <Save size={20} />
              {loading ? 'Salvando...' : 'Salvar Alterações'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}