import { useState } from 'react';
import { Package, Plus, Minus, Trash2, PenSquare, Save } from 'lucide-react';

interface OrderItem {
  productId: string;
  name: string;
  sku: string;
  quantity: number;
  price: number;
  originalPrice: number;
  total: number;
  mainImage?: string;
}

interface ProductListProps {
  items: OrderItem[];
  onUpdateQuantity: (productId: string, quantity: number) => void;
  onRemoveItem: (productId: string) => void;
  onUpdatePrice: (productId: string, price: number) => void;
}

export default function ProductList({ 
  items, 
  onUpdateQuantity, 
  onRemoveItem,
  onUpdatePrice 
}: ProductListProps) {
  const [editingPrice, setEditingPrice] = useState<string | null>(null);
  const [tempPrice, setTempPrice] = useState<string>('');

  const handlePriceEdit = (productId: string, currentPrice: number) => {
    setEditingPrice(productId);
    setTempPrice(currentPrice.toString());
  };

  const handlePriceSave = (productId: string) => {
    const newPrice = parseFloat(tempPrice);
    if (!isNaN(newPrice) && newPrice >= 0) {
      onUpdatePrice(productId, newPrice);
    }
    setEditingPrice(null);
    setTempPrice('');
  };

  if (items.length === 0) {
    return (
      <div className="text-center py-8">
        <Package className="mx-auto h-12 w-12 text-gray-400 mb-4" />
        <p className="text-gray-500">Nenhum produto adicionado</p>
      </div>
    );
  }

  return (
    <div className="border rounded-lg overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Produto
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              SKU
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Preço
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Quantidade
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {items.map((item) => (
            <tr key={item.productId}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  {item.mainImage ? (
                    <img
                      src={item.mainImage}
                      alt={item.name}
                      className="h-10 w-10 rounded object-cover mr-3"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded bg-gray-100 flex items-center justify-center mr-3">
                      <Package className="h-6 w-6 text-gray-400" />
                    </div>
                  )}
                  <span className="font-medium">{item.name}</span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{item.sku}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {editingPrice === item.productId ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      value={tempPrice}
                      onChange={(e) => setTempPrice(e.target.value)}
                      className="w-24 px-2 py-1 border rounded"
                      min="0"
                      step="0.01"
                    />
                    <button
                      onClick={() => handlePriceSave(item.productId)}
                      className="text-primary hover:text-primary-dark"
                    >
                      <Save size={16} />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <span>€{item.price.toFixed(2)}</span>
                    <button
                      onClick={() => handlePriceEdit(item.productId, item.price)}
                      className="text-primary hover:text-primary-dark"
                    >
                      <PenSquare size={16} />
                    </button>
                  </div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => onUpdateQuantity(item.productId, item.quantity - 1)}
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <Minus size={16} />
                  </button>
                  <span className="w-8 text-center">{item.quantity}</span>
                  <button
                    onClick={() => onUpdateQuantity(item.productId, item.quantity + 1)}
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <Plus size={16} />
                  </button>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">€{item.total.toFixed(2)}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => onRemoveItem(item.productId)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}