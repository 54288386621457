import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Package, Search } from 'lucide-react';

interface Product {
  id: string;
  name: string;
  sku: string;
  mainImage?: string;
  regularPrice: number;
}

interface ProductSelectorProps {
  companyId: string;
  onProductSelect: (product: Product) => void;
}

export default function ProductSelector({ companyId, onProductSelect }: ProductSelectorProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (companyId) {
      loadProducts();
    }
  }, [companyId]);

  const loadProducts = async () => {
    try {
      const productsQuery = query(
        collection(db, 'products'),
        where('companyId', '==', companyId)
      );
      
      const snapshot = await getDocs(productsQuery);
      const productsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Product[];
      
      setProducts(productsData);
      setError('');
    } catch (err) {
      console.error('Error loading products:', err);
      setError('Erro ao carregar produtos');
    } finally {
      setLoading(false);
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.sku.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Pesquisar produtos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>

      {error && (
        <div className="text-red-600 text-sm">
          {error}
        </div>
      )}

      <div className="max-h-96 overflow-y-auto">
        <div className="space-y-2">
          {filteredProducts.map(product => (
            <button
              key={product.id}
              onClick={() => onProductSelect(product)}
              className="w-full flex items-center gap-3 p-3 rounded-lg border border-gray-200 hover:border-primary text-left"
            >
              {product.mainImage ? (
                <img
                  src={product.mainImage}
                  alt={product.name}
                  className="w-16 h-16 object-cover rounded"
                />
              ) : (
                <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
                  <Package size={24} className="text-gray-400" />
                </div>
              )}
              <div className="flex-1">
                <h4 className="font-medium">{product.name}</h4>
                <p className="text-sm text-gray-600">SKU: {product.sku}</p>
                <p className="text-sm font-medium text-primary">
                  €{product.regularPrice.toFixed(2)}
                </p>
              </div>
            </button>
          ))}

          {filteredProducts.length === 0 && (
            <p className="text-center text-gray-500 py-4">
              Nenhum produto encontrado
            </p>
          )}
        </div>
      </div>
    </div>
  );
}