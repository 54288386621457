import { useState } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Package, Search } from 'lucide-react';
import { debounce } from 'lodash';

interface Product {
  id: string;
  name: string;
  sku: string;
  regularPrice: number;
  mainImage?: string;
}

interface ProductSelectorProps {
  companyId: string;
  onProductSelect: (product: Product) => void;
}

export default function ProductSelector({ companyId, onProductSelect }: ProductSelectorProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const searchProducts = async (term: string) => {
    if (!term.trim()) {
      setProducts([]);
      return;
    }

    try {
      setLoading(true);
      setError('');

      const searchTermLower = term.toLowerCase();
      
      const productsRef = collection(db, 'products');
      const productsQuery = query(
        productsRef,
        where('companyId', '==', companyId),
        limit(10)
      );

      const snapshot = await getDocs(productsQuery);
      
      const results = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Product))
        .filter(product => 
          product.name.toLowerCase().includes(searchTermLower) ||
          product.sku.toLowerCase().includes(searchTermLower)
        );

      setProducts(results);
    } catch (err) {
      console.error('Error searching products:', err);
      setError('Erro ao pesquisar produtos');
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(searchProducts, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <div>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Pesquisar produtos por nome ou SKU..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>

      {error && (
        <div className="mb-4 text-red-600 text-sm">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {loading ? (
          <div className="col-span-full flex justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : products.length > 0 ? (
          products.map((product) => (
            <button
              key={product.id}
              onClick={() => onProductSelect(product)}
              className="flex items-center gap-3 p-4 border rounded-lg hover:border-primary text-left"
            >
              {product.mainImage ? (
                <img
                  src={product.mainImage}
                  alt={product.name}
                  className="w-16 h-16 object-cover rounded"
                />
              ) : (
                <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
                  <Package className="w-8 h-8 text-gray-400" />
                </div>
              )}
              <div>
                <p className="font-medium">{product.name}</p>
                <p className="text-sm text-gray-500">SKU: {product.sku}</p>
                <p className="text-primary font-medium">€{product.regularPrice.toFixed(2)}</p>
              </div>
            </button>
          ))
        ) : searchTerm ? (
          <div className="col-span-full text-center py-8 text-gray-500">
            Nenhum produto encontrado
          </div>
        ) : (
          <div className="col-span-full text-center py-8 text-gray-500">
            Digite para pesquisar produtos
          </div>
        )}
      </div>
    </div>
  );
}