import { useState } from 'react';
import { doc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { AlertTriangle, X } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface User {
  id: string;
  email: string;
  displayName?: string;
  companyId?: string;
  role: string;
}

interface DeleteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onDeleted: () => void;
}

export default function DeleteUserModal({
  isOpen,
  onClose,
  user,
  onDeleted
}: DeleteUserModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  if (!isOpen) return null;

  const handleDelete = async () => {
    if (!currentUser) return;

    setLoading(true);
    setError('');

    try {
      // Se for gerente, verifica se tem permissão
      if (user.role === 'gerente') {
        // Apenas admin pode apagar gerente
        if (currentUser.role !== 'admin') {
          throw new Error('Permissão negada');
        }

        // Busca todos os funcionários da empresa
        const employeesQuery = query(
          collection(db, 'users'),
          where('companyId', '==', user.companyId),
          where('role', '==', 'funcionario')
        );
        const employeesSnapshot = await getDocs(employeesQuery);

        // Apaga todos os funcionários
        const deleteEmployees = employeesSnapshot.docs.map(doc => 
          deleteDoc(doc.ref)
        );
        await Promise.all(deleteEmployees);

        // Apaga a empresa
        if (user.companyId) {
          await deleteDoc(doc(db, 'companies', user.companyId));
          // Apaga a carteira da empresa
          await deleteDoc(doc(db, 'wallets', user.companyId));
        }
      }

      // Apaga o usuário
      await deleteDoc(doc(db, 'users', user.id));

      onDeleted();
    } catch (err) {
      console.error('Error deleting user:', err);
      setError(err instanceof Error ? err.message : 'Erro ao remover usuário');
    } finally {
      setLoading(false);
    }
  };

  const getMessage = () => {
    if (user.role === 'gerente') {
      return 'Esta ação irá remover o gerente, todos os funcionários associados e a empresa. Esta ação não pode ser desfeita.';
    }
    return 'Esta ação irá remover o funcionário. Esta ação não pode ser desfeita.';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3 text-red-600">
            <AlertTriangle size={24} />
            <h3 className="text-lg font-semibold">Remover Usuário</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        {error && (
          <div className="mb-4 text-red-600 text-sm">
            {error}
          </div>
        )}

        <div className="mb-6">
          <p className="text-gray-700 mb-4">
            Tem certeza que deseja remover o usuário <strong>{user.displayName || user.email}</strong>?
          </p>
          <p className="text-red-600 text-sm">
            {getMessage()}
          </p>
        </div>

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            onClick={handleDelete}
            disabled={loading}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
          >
            {loading ? 'Removendo...' : 'Confirmar Remoção'}
          </button>
        </div>
      </div>
    </div>
  );
}