import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useSupportView } from '../contexts/SupportViewContext';
import { Search, Eye } from 'lucide-react';
import type { User } from '../types';

export default function SupportAccess() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser } = useAuth();
  const { enterSupportMode } = useSupportView();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.role || currentUser.role !== 'admin') {
      navigate('/');
      return;
    }
    loadUsers();
  }, [currentUser, navigate]);

  const loadUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const usersQuery = query(usersRef, where('role', '!=', 'admin'));
      const snapshot = await getDocs(usersQuery);
      
      const usersData = await Promise.all(
        snapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();
          let companyData = null;
          let walletBalance = 0;

          if (userData.companyId) {
            try {
              const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
              if (companyDoc.exists()) {
                const company = companyDoc.data();
                companyData = {
                  name: company.commercialName || company.legalName,
                  logoBase64: company.logoBase64
                };
              }

              const walletDoc = await getDoc(doc(db, 'wallets', userData.companyId));
              if (walletDoc.exists()) {
                walletBalance = walletDoc.data().balance || 0;
              }
            } catch (err) {
              console.error('Error loading company/wallet data:', err);
            }
          }

          return {
            id: userDoc.id,
            email: userData.email || '',
            displayName: userData.displayName || '',
            companyId: userData.companyId,
            role: userData.role,
            photoBase64: userData.photoBase64,
            company: companyData,
            walletBalance
          };
        })
      );

      setUsers(usersData);
      setError('');
    } catch (err) {
      console.error('Error loading users:', err);
      setError('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleSupportAccess = async (user: User) => {
    if (!currentUser?.uid || loading) return;
    
    try {
      setLoading(true);
      setError('');

      // Enter support mode
      enterSupportMode({
        userId: user.id,
        companyId: user.companyId || '',
        userName: user.displayName || user.email
      });

    } catch (err) {
      console.error('Error accessing user:', err);
      setError('Failed to access user data');
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.company?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!currentUser?.role || currentUser.role !== 'admin') {
    return null;
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-secondary mb-6">Suporte a Utilizadores</h2>
          
          <div className="mb-6 relative">
            <input
              type="text"
              placeholder="Pesquisar por nome, email ou empresa..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>

          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {error}
            </div>
          )}

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Utilizador
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Função
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Empresa
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {user.photoBase64 ? (
                          <img
                            src={user.photoBase64}
                            alt={user.displayName || user.email}
                            className="h-8 w-8 rounded-full mr-3"
                          />
                        ) : (
                          <div className="h-8 w-8 rounded-full bg-gray-200 mr-3" />
                        )}
                        <span>{user.displayName || user.email}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        user.role === 'gerente' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'
                      }`}>
                        {user.role === 'gerente' ? 'Gerente' : 'Funcionário'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user.company?.name || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleSupportAccess(user)}
                        className="text-primary hover:text-primary-dark flex items-center gap-2"
                        title="Ver dados do utilizador"
                      >
                        <Eye size={20} />
                        <span>Ver Dados</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}