import { User } from '../../types';
import { Eye, Wallet, PenSquare, Trash2, UserCog } from 'lucide-react';
import { Link } from 'react-router-dom';

interface UserListProps {
  users: User[];
  onEditUser: (userId: string) => void;
  onWalletClick: (user: User) => void;
  onDeleteClick: (user: User) => void;
  canDeleteUser: (user: User) => boolean;
  isSupport?: boolean;
  onSupportAccess?: (user: User) => void;
}

export default function UserList({
  users,
  onEditUser,
  onWalletClick,
  onDeleteClick,
  canDeleteUser,
  isSupport = false,
  onSupportAccess
}: UserListProps) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Utilizador
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Email
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Função
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Empresa
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Saldo
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Ações
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {users.map((user) => (
          <tr key={user.id}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center">
                {user.photoBase64 ? (
                  <img
                    src={user.photoBase64}
                    alt={user.displayName || user.email}
                    className="h-8 w-8 rounded-full mr-3"
                  />
                ) : (
                  <div className="h-8 w-8 rounded-full bg-gray-200 mr-3" />
                )}
                <span>{user.displayName || user.email.split('@')[0]}</span>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {user.email}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={`px-2 py-1 rounded-full text-xs ${
                user.role === 'admin'
                  ? 'bg-red-100 text-red-800'
                  : user.role === 'gerente'
                  ? 'bg-purple-100 text-purple-800'
                  : 'bg-blue-100 text-blue-800'
              }`}>
                {user.role === 'admin' ? 'Administrador' :
                 user.role === 'gerente' ? 'Gerente' : 'Funcionário'}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {user.company ? (
                <div className="flex items-center">
                  {user.company.logoBase64 && (
                    <img
                      src={user.company.logoBase64}
                      alt={user.company.name}
                      className="h-8 w-8 rounded-full mr-2"
                    />
                  )}
                  {user.company.name}
                </div>
              ) : (
                '-'
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {user.companyId ? (
                <button
                  onClick={() => onWalletClick(user)}
                  className="flex items-center gap-2 text-primary hover:text-primary-dark"
                  disabled={isSupport}
                >
                  <Wallet size={16} />
                  €{(user.walletBalance || 0).toFixed(2)}
                </button>
              ) : (
                '-'
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center gap-2">
                {isSupport ? (
                  <button
                    onClick={() => onSupportAccess?.(user)}
                    className="flex items-center gap-2 text-primary hover:text-primary-dark"
                    title="Acessar como usuário"
                  >
                    <UserCog size={20} />
                    <span>Acessar como usuário</span>
                  </button>
                ) : (
                  <>
                    <Link
                      to={`/social/profile/${user.id}`}
                      className="text-primary hover:text-primary-dark"
                      title="Ver perfil"
                    >
                      <Eye size={20} />
                    </Link>
                    <button
                      onClick={() => onEditUser(user.id)}
                      className="text-primary hover:text-primary-dark"
                      title="Editar usuário"
                    >
                      <PenSquare size={20} />
                    </button>
                    {canDeleteUser(user) && (
                      <button
                        onClick={() => onDeleteClick(user)}
                        className="text-red-600 hover:text-red-700"
                        title="Remover usuário"
                      >
                        <Trash2 size={20} />
                      </button>
                    )}
                  </>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}