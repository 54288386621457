import { useState } from 'react';
import { doc, runTransaction, Timestamp, collection } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { X, Plus, Minus } from 'lucide-react';

interface WalletManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  companyId: string;
  currentBalance: number;
  userName: string;
}

export default function WalletManagementModal({
  isOpen,
  onClose,
  userId,
  companyId,
  currentBalance,
  userName
}: WalletManagementModalProps) {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [transactionType, setTransactionType] = useState<'add' | 'subtract'>('add');

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!amount || parseFloat(amount) <= 0) {
      setError('Por favor, insira um valor válido');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const timestamp = Timestamp.now();
      const transactionAmount = parseFloat(amount);

      await runTransaction(db, async (transaction) => {
        const walletRef = doc(db, 'wallets', companyId);
        const newBalance = transactionType === 'add' 
          ? currentBalance + transactionAmount
          : currentBalance - transactionAmount;

        if (transactionType === 'subtract' && newBalance < 0) {
          throw new Error('Saldo insuficiente para esta operação');
        }

        // Update wallet balance
        transaction.set(walletRef, {
          balance: newBalance,
          companyId,
          updatedAt: timestamp
        }, { merge: true });

        // Create transaction record
        const transactionRef = doc(collection(db, 'walletTransactions'));
        transaction.set(transactionRef, {
          userId,
          companyId,
          type: transactionType === 'add' ? 'deposit' : 'withdrawal',
          amount: transactionAmount,
          description: description || `${transactionType === 'add' ? 'Crédito' : 'Débito'} adicionado pelo administrador`,
          status: 'completed',
          createdAt: timestamp,
          createdBy: 'admin'
        });
      });

      setSuccess('Operação realizada com sucesso');
      setAmount('');
      setDescription('');
      
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      console.error('Error updating wallet:', err);
      setError(err instanceof Error ? err.message : 'Erro ao atualizar carteira');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-semibold">
            Gerenciar Carteira - {userName}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        <div className="text-center mb-6">
          <p className="text-sm text-gray-600">Saldo Atual</p>
          <p className="text-3xl font-bold text-primary">
            €{currentBalance.toFixed(2)}
          </p>
        </div>

        {error && (
          <div className="mb-4 text-red-600 text-sm text-center">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 text-green-600 text-sm text-center">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex gap-2 mb-4">
            <button
              type="button"
              onClick={() => setTransactionType('add')}
              className={`flex-1 py-2 px-4 rounded-lg flex items-center justify-center gap-2 ${
                transactionType === 'add'
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              <Plus size={20} />
              Adicionar
            </button>
            <button
              type="button"
              onClick={() => setTransactionType('subtract')}
              className={`flex-1 py-2 px-4 rounded-lg flex items-center justify-center gap-2 ${
                transactionType === 'subtract'
                  ? 'bg-red-600 text-white'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              <Minus size={20} />
              Subtrair
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Valor (€)
            </label>
            <input
              type="number"
              min="0"
              step="0.01"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              placeholder="0.00"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Descrição (opcional)
            </label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              placeholder="Motivo da operação"
            />
          </div>

          <button
            type="submit"
            disabled={loading || !amount || parseFloat(amount) <= 0}
            className={`w-full py-2 rounded-lg ${
              transactionType === 'add'
                ? 'bg-green-600 hover:bg-green-700'
                : 'bg-red-600 hover:bg-red-700'
            } text-white disabled:opacity-50`}
          >
            {loading ? 'Processando...' : 'Confirmar'}
          </button>
        </form>
      </div>
    </div>
  );
}