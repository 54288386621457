import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface SupportViewData {
  userId: string;
  companyId: string;
  userName: string;
  timestamp: number;
}

interface SupportViewContextType {
  supportView: SupportViewData | null;
  isInSupportMode: boolean;
  exitSupportMode: () => void;
  enterSupportMode: (data: Omit<SupportViewData, 'timestamp'>) => void;
}

const SupportViewContext = createContext<SupportViewContextType | null>(null);

export function useSupportView() {
  const context = useContext(SupportViewContext);
  if (!context) {
    throw new Error('useSupportView must be used within a SupportViewProvider');
  }
  return context;
}

export function SupportViewProvider({ children }: { children: React.ReactNode }) {
  const [supportView, setSupportView] = useState<SupportViewData | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const loadSupportView = async () => {
      const data = localStorage.getItem('support_view');
      if (!data) {
        setSupportView(null);
        return;
      }

      try {
        const parsedData = JSON.parse(data) as SupportViewData;
        
        // Verify if support session is still valid (1 hour)
        if (Date.now() - parsedData.timestamp > 3600000) {
          localStorage.removeItem('support_view');
          setSupportView(null);
          return;
        }

        // Verify if user still exists
        const userDoc = await getDoc(doc(db, 'users', parsedData.userId));
        if (!userDoc.exists()) {
          localStorage.removeItem('support_view');
          setSupportView(null);
          return;
        }

        setSupportView(parsedData);
      } catch (err) {
        console.error('Invalid support view data:', err);
        localStorage.removeItem('support_view');
        setSupportView(null);
      }
    };

    loadSupportView();
  }, [location.pathname]);

  const enterSupportMode = (data: Omit<SupportViewData, 'timestamp'>) => {
    const supportData = {
      ...data,
      timestamp: Date.now()
    };
    localStorage.setItem('support_view', JSON.stringify(supportData));
    setSupportView(supportData);
    navigate('/dashboard');
  };

  const exitSupportMode = () => {
    localStorage.removeItem('support_view');
    setSupportView(null);
    navigate('/settings/support');
    // Force reload to reset all contexts
    window.location.reload();
  };

  const value = {
    supportView,
    isInSupportMode: !!supportView,
    exitSupportMode,
    enterSupportMode
  };

  return (
    <SupportViewContext.Provider value={value}>
      {children}
    </SupportViewContext.Provider>
  );
}