import { useState } from 'react';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { MessageSquare, Send } from 'lucide-react';

interface Comment {
  id: string;
  text: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
}

interface OrderCommentsProps {
  orderId: string;
  comments: Comment[];
  onCommentAdded: () => void;
}

export default function OrderComments({ orderId, comments, onCommentAdded }: OrderCommentsProps) {
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser || !newComment.trim()) return;

    setLoading(true);
    try {
      const comment = {
        id: Date.now().toString(),
        text: newComment.trim(),
        createdAt: new Date().toISOString(),
        createdBy: currentUser.displayName || currentUser.email || 'Usuário',
        createdById: currentUser.uid
      };

      await updateDoc(doc(db, 'orders', orderId), {
        comments: arrayUnion(comment)
      });

      setNewComment('');
      onCommentAdded();
    } catch (err) {
      console.error('Error adding comment:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('pt-PT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="bg-gray-50 rounded-lg p-6">
      <h3 className="flex items-center gap-2 text-lg font-semibold mb-4">
        <MessageSquare className="text-gray-400" size={20} />
        Comentários
      </h3>

      <div className="space-y-4 mb-6 max-h-96 overflow-y-auto">
        {comments.map((comment) => (
          <div key={comment.id} className="bg-white p-4 rounded-lg shadow-sm">
            <div className="flex justify-between items-start mb-2">
              <div>
                <span className="font-medium">{comment.createdBy}</span>
                <span className="text-sm text-gray-500 ml-2">
                  {formatDate(comment.createdAt)}
                </span>
              </div>
            </div>
            <p className="text-gray-700 whitespace-pre-wrap">{comment.text}</p>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="flex gap-2">
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Adicione um comentário..."
          className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
        <button
          type="submit"
          disabled={loading || !newComment.trim()}
          className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50 flex items-center gap-2"
        >
          <Send size={20} />
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </div>
  );
}