import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { menuItems } from './menuItems';
import { MenuItem } from '../../types';
import { useAuth } from '../../contexts/AuthContext';

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar = ({ isSidebarOpen, toggleSidebar }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const { effectiveUser } = useAuth();

  const handleItemClick = (item: MenuItem) => {
    if (item.subItems) {
      setExpandedItem(expandedItem === item.label ? null : item.label);
    } else {
      navigate(item.path);
      if (window.innerWidth < 1024) {
        toggleSidebar();
      }
    }
  };

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  // Filter menu items based on user role
  const filteredMenuItems = menuItems.filter(item => {
    if (item.adminOnly && effectiveUser?.role !== 'admin') {
      return false;
    }
    if (item.subItems) {
      item.subItems = item.subItems.filter(subItem => 
        !subItem.adminOnly || effectiveUser?.role === 'admin'
      );
      return item.subItems.length > 0;
    }
    return true;
  });

  return (
    <aside 
      className={`fixed top-0 left-0 z-40 h-screen transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } bg-secondary text-white w-64 shadow-xl`}
    >
      <div className="flex items-center justify-center p-4 border-b border-secondary-light">
        <img
          src="https://lognow.pt/imagens/logo_white_xs.png"
          alt="LOGNOW"
          className="h-8"
        />
      </div>
      
      <nav className="p-4 space-y-2">
        {filteredMenuItems.map((item) => (
          <div key={item.label}>
            <button
              onClick={() => handleItemClick(item)}
              className={`flex items-center justify-between w-full p-2 rounded-lg transition-colors ${
                isActive(item.path)
                  ? 'bg-secondary-light'
                  : 'hover:bg-secondary-light'
              }`}
            >
              <div className="flex items-center space-x-3">
                <item.icon size={20} />
                <span>{item.label}</span>
              </div>
              {item.subItems && (
                <ChevronDown
                  className={`transform transition-transform duration-200 ${
                    expandedItem === item.label ? 'rotate-180' : ''
                  }`}
                  size={16}
                />
              )}
            </button>
            
            {item.subItems && expandedItem === item.label && (
              <div className="ml-4 mt-2 space-y-1">
                {item.subItems.map((subItem) => (
                  <button
                    key={subItem.label}
                    onClick={() => handleItemClick(subItem)}
                    className={`flex items-center space-x-3 w-full p-2 rounded-lg transition-colors text-sm ${
                      isActive(subItem.path)
                        ? 'bg-secondary-light'
                        : 'hover:bg-secondary-light'
                    }`}
                  >
                    <subItem.icon size={18} />
                    <span>{subItem.label}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;