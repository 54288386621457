import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, addDoc, updateDoc, deleteDoc, doc, where } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { X, PenSquare, Trash2, Save } from 'lucide-react';

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Category {
  id: string;
  name: string;
  slug: string;
  createdAt: string;
}

export default function CategoryModal({ isOpen, onClose }: CategoryModalProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deleteError, setDeleteError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      loadCategories();
    }
  }, [isOpen]);

  const loadCategories = async () => {
    try {
      const categoriesQuery = query(
        collection(db, 'categories'),
        orderBy('name')
      );
      
      const snapshot = await getDocs(categoriesQuery);
      const categoriesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Category[];
      
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error loading categories:', error);
      setError('Erro ao carregar categorias');
    }
  };

  const handleCreateCategory = async () => {
    if (!newCategoryName.trim() || loading) return;

    try {
      setLoading(true);
      setError('');

      // Check if category already exists
      const exists = categories.some(
        cat => cat.name.toLowerCase() === newCategoryName.trim().toLowerCase()
      );

      if (exists) {
        setError('Esta categoria já existe');
        return;
      }

      const slug = newCategoryName
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      await addDoc(collection(db, 'categories'), {
        name: newCategoryName.trim(),
        slug,
        createdAt: new Date().toISOString()
      });

      setNewCategoryName('');
      await loadCategories();
    } catch (err) {
      console.error('Error creating category:', err);
      setError('Erro ao criar categoria');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCategory = async () => {
    if (!editingCategory || loading) return;

    try {
      setLoading(true);
      setError('');

      // Check if new name already exists
      const exists = categories.some(
        cat => cat.id !== editingCategory.id && 
        cat.name.toLowerCase() === editingCategory.name.trim().toLowerCase()
      );

      if (exists) {
        setError('Já existe uma categoria com este nome');
        return;
      }

      const slug = editingCategory.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      await updateDoc(doc(db, 'categories', editingCategory.id), {
        name: editingCategory.name.trim(),
        slug
      });

      setEditingCategory(null);
      await loadCategories();
    } catch (err) {
      console.error('Error updating category:', err);
      setError('Erro ao atualizar categoria');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async (categoryId: string) => {
    try {
      setLoading(true);
      setDeleteError(null);

      // Check if category has products
      const productsQuery = query(
        collection(db, 'products'),
        where('category', '==', categoryId)
      );
      const productsSnapshot = await getDocs(productsQuery);

      if (!productsSnapshot.empty) {
        setDeleteError('Não é possível excluir esta categoria pois existem produtos associados a ela');
        return;
      }

      await deleteDoc(doc(db, 'categories', categoryId));
      await loadCategories();
    } catch (err) {
      console.error('Error deleting category:', err);
      setDeleteError('Erro ao excluir categoria');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Gerenciar Categorias</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        {error && (
          <div className="mb-4 text-red-600 text-sm">
            {error}
          </div>
        )}

        {deleteError && (
          <div className="mb-4 text-red-600 text-sm">
            {deleteError}
          </div>
        )}

        {/* Create New Category */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Nova Categoria
          </label>
          <div className="flex gap-2">
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
              placeholder="Digite o nome da categoria"
            />
            <button
              onClick={handleCreateCategory}
              disabled={!newCategoryName.trim() || loading}
              className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              Criar
            </button>
          </div>
        </div>

        {/* Categories List */}
        <div className="space-y-2 max-h-96 overflow-y-auto">
          {categories.map((category) => (
            <div
              key={category.id}
              className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
            >
              {editingCategory?.id === category.id ? (
                <input
                  type="text"
                  value={editingCategory.name}
                  onChange={(e) => setEditingCategory({
                    ...editingCategory,
                    name: e.target.value
                  })}
                  className="flex-1 mr-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              ) : (
                <span>{category.name}</span>
              )}

              <div className="flex items-center gap-2">
                {editingCategory?.id === category.id ? (
                  <button
                    onClick={handleUpdateCategory}
                    disabled={loading}
                    className="p-1 text-primary hover:text-primary-dark"
                  >
                    <Save size={18} />
                  </button>
                ) : (
                  <button
                    onClick={() => setEditingCategory(category)}
                    className="p-1 text-primary hover:text-primary-dark"
                  >
                    <PenSquare size={18} />
                  </button>
                )}
                <button
                  onClick={() => handleDeleteCategory(category.id)}
                  disabled={loading}
                  className="p-1 text-red-600 hover:text-red-700"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
          ))}

          {categories.length === 0 && (
            <p className="text-center text-gray-500 py-4">
              Nenhuma categoria cadastrada
            </p>
          )}
        </div>
      </div>
    </div>
  );
}