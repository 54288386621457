import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { User, Building2, PenSquare } from 'lucide-react';
import { validateImage, resizeImage } from '../utils/imageUpload';
import DistrictSelect from '../components/shipping/DistrictSelect';

interface Company {
  id: string;
  legalName: string;
  commercialName: string;
  address: string;
  district: string;
  workingHours: string;
  email: string;
  phone: string;
  logoBase64?: string;
}

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [company, setCompany] = useState<Company | null>(null);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profileData, setProfileData] = useState({
    displayName: '',
    email: '',
    phone: ''
  });
  const [companyData, setCompanyData] = useState({
    legalName: '',
    commercialName: '',
    address: '',
    district: '',
    workingHours: '',
    email: '',
    phone: ''
  });

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [currentUser]);

  const loadData = async () => {
    if (!currentUser) return;

    try {
      // Load user data including profile photo
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfilePhoto(userData.photoBase64 || '');
        setProfileData({
          displayName: userData.displayName || '',
          email: userData.email || '',
          phone: userData.phone || ''
        });
      }

      // Load company data if exists
      if (currentUser.companyId) {
        const companyDoc = await getDoc(doc(db, 'companies', currentUser.companyId));
        if (companyDoc.exists()) {
          const data = companyDoc.data() as Company;
          setCompany(data);
          setCompanyData({
            legalName: data.legalName,
            commercialName: data.commercialName,
            address: data.address,
            district: data.district || '',
            workingHours: data.workingHours,
            email: data.email,
            phone: data.phone
          });
        }
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Erro ao carregar dados');
    }
  };

  const handleProfilePhotoUpload = async (file: File) => {
    if (!currentUser) return;
    setLoading(true);
    setError('');

    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Image = await resizeImage(file);
      
      await updateDoc(doc(db, 'users', currentUser.uid), {
        photoBase64: base64Image,
        updatedAt: new Date().toISOString()
      });

      setProfilePhoto(base64Image);
      setSuccess('Foto atualizada com sucesso');
    } catch (err) {
      console.error('Error uploading photo:', err);
      setError('Erro ao enviar foto');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    setLoading(true);
    setError('');

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        ...profileData,
        updatedAt: new Date().toISOString()
      });

      setIsEditingProfile(false);
      setSuccess('Perfil atualizado com sucesso');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Erro ao atualizar perfil');
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyLogoUpload = async (file: File) => {
    if (!currentUser?.companyId || !company) return;
    setLoading(true);
    setError('');

    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Logo = await resizeImage(file);
      
      await updateDoc(doc(db, 'companies', currentUser.companyId), {
        logoBase64: base64Logo,
        updatedAt: new Date().toISOString()
      });

      setCompany(prev => prev ? { ...prev, logoBase64: base64Logo } : null);
      setSuccess('Logo atualizado com sucesso');
    } catch (err) {
      console.error('Error uploading logo:', err);
      setError('Erro ao enviar logo');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCompany = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.companyId) return;

    setLoading(true);
    setError('');

    try {
      await updateDoc(doc(db, 'companies', currentUser.companyId), {
        ...companyData,
        updatedAt: new Date().toISOString()
      });

      setCompany(prev => prev ? { ...prev, ...companyData } : null);
      setIsEditingCompany(false);
      setSuccess('Dados da empresa atualizados com sucesso');
    } catch (err) {
      console.error('Error updating company:', err);
      setError('Erro ao atualizar dados da empresa');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold text-secondary mb-6">Meu Perfil</h2>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            {success}
          </div>
        )}

        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                id="profile-photo"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleProfilePhotoUpload(file);
                }}
              />
              <label
                htmlFor="profile-photo"
                className="cursor-pointer block"
              >
                {profilePhoto ? (
                  <img
                    src={profilePhoto}
                    alt="Foto de perfil"
                    className="w-24 h-24 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center">
                    <User className="w-12 h-12 text-gray-400" />
                  </div>
                )}
              </label>
            </div>

            {!isEditingProfile ? (
              <div>
                <h3 className="text-xl font-semibold">
                  {profileData.displayName || currentUser?.email?.split('@')[0]}
                </h3>
                <p className="text-gray-600">{profileData.email}</p>
                {profileData.phone && (
                  <p className="text-gray-600">{profileData.phone}</p>
                )}
              </div>
            ) : null}
          </div>

          <button
            onClick={() => setIsEditingProfile(!isEditingProfile)}
            className="flex items-center gap-2 text-primary hover:text-primary-dark"
          >
            <PenSquare size={20} />
            {isEditingProfile ? 'Cancelar' : 'Editar Perfil'}
          </button>
        </div>

        {isEditingProfile && (
          <form onSubmit={handleUpdateProfile} className="mb-8 space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nome
                </label>
                <input
                  type="text"
                  value={profileData.displayName}
                  onChange={(e) => setProfileData({ ...profileData, displayName: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={profileData.email}
                  onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Telefone
                </label>
                <input
                  type="tel"
                  value={profileData.phone}
                  onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setIsEditingProfile(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
              >
                {loading ? 'Salvando...' : 'Salvar Alterações'}
              </button>
            </div>
          </form>
        )}

        {/* Company Section */}
        <div className="border-t pt-8">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <Building2 className="text-gray-400" />
              Empresa
            </h3>
            {company && (
              <button
                onClick={() => setIsEditingCompany(!isEditingCompany)}
                className="flex items-center gap-2 text-primary hover:text-primary-dark"
              >
                <PenSquare size={20} />
                {isEditingCompany ? 'Cancelar' : 'Editar'}
              </button>
            )}
          </div>

          {!company && !currentUser?.companyId && (
            <div className="text-center py-8">
              <p className="text-gray-600 mb-4">Você ainda não tem uma empresa cadastrada</p>
              <button
                onClick={() => navigate('/company-setup')}
                className="bg-primary text-secondary px-6 py-2 rounded-md hover:bg-primary-dark"
              >
                Criar Empresa
              </button>
            </div>
          )}

          {company && !isEditingCompany && (
            <div className="space-y-4">
              <div className="flex items-center space-x-4 mb-6">
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  id="company-logo"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleCompanyLogoUpload(file);
                  }}
                />
                <label
                  htmlFor="company-logo"
                  className="cursor-pointer block"
                >
                  {company.logoBase64 ? (
                    <img
                      src={company.logoBase64}
                      alt="Logo da empresa"
                      className="w-24 h-24 rounded-lg object-contain bg-gray-50"
                    />
                  ) : (
                    <div className="w-24 h-24 rounded-lg bg-gray-200 flex items-center justify-center">
                      <Building2 className="w-12 h-12 text-gray-400" />
                    </div>
                  )}
                </label>
                <div>
                  <h4 className="font-semibold">{company.commercialName}</h4>
                  <p className="text-gray-600">{company.legalName}</p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p>{company.email}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Telefone</p>
                  <p>{company.phone}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Horário de Funcionamento</p>
                  <p>{company.workingHours}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Endereço</p>
                  <p>{company.address}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Distrito</p>
                  <p>{company.district || 'Não definido'}</p>
                </div>
              </div>
            </div>
          )}

          {company && isEditingCompany && (
            <form onSubmit={handleUpdateCompany} className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nome Legal
                  </label>
                  <input
                    type="text"
                    value={companyData.legalName}
                    onChange={(e) => setCompanyData({ ...companyData, legalName: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nome Comercial
                  </label>
                  <input
                    type="text"
                    value={companyData.commercialName}
                    onChange={(e) => setCompanyData({ ...companyData, commercialName: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    value={companyData.email}
                    onChange={(e) => setCompanyData({ ...companyData, email: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Telefone
                  </label>
                  <input
                    type="tel"
                    value={companyData.phone}
                    onChange={(e) => setCompanyData({ ...companyData, phone: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Horário de Funcionamento
                  </label>
                  <input
                    type="text"
                    value={companyData.workingHours}
                    onChange={(e) => setCompanyData({ ...companyData, workingHours: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Endereço
                  </label>
                  <input
                    type="text"
                    value={companyData.address}
                    onChange={(e) => setCompanyData({ ...companyData, address: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Distrito
                  </label>
                  <DistrictSelect
                    value={companyData.district}
                    onChange={(district) => setCompanyData({ ...companyData, district })}
                    className="mt-1"
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setIsEditingCompany(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
                >
                  {loading ? 'Salvando...' : 'Salvar Alterações'}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}