import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    // Store the current URL before redirecting
    localStorage.setItem('auth_redirect', location.pathname + location.search);

    // Check if there's a stored token
    const token = localStorage.getItem('lognow_auth_token');
    if (token) {
      // Show loading while restoring auth state
      return <div>Loading...</div>;
    }

    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}