import { useState, useEffect } from 'react';
import { collection, getDocs, query, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { User } from '../types';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser?.role === 'admin' || currentUser?.role === 'gerente') {
      loadUsers();
    }
  }, [currentUser]);

  const loadUsers = async () => {
    try {
      setError('');
      setLoading(true);

      if (!currentUser) {
        throw new Error('Usuário não autenticado');
      }

      // Get all users for admin, or company users for manager
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(query(usersRef));
      
      const usersData = await Promise.all(
        usersSnapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();
          let companyData = null;
          let walletBalance = 0;

          if (userData.companyId) {
            try {
              // Load company data
              const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
              if (companyDoc.exists()) {
                const company = companyDoc.data();
                companyData = {
                  name: company.commercialName || company.legalName,
                  logoBase64: company.logoBase64
                };
              }

              // Load wallet data
              const walletDoc = await getDoc(doc(db, 'wallets', userData.companyId));
              if (walletDoc.exists()) {
                walletBalance = walletDoc.data().balance || 0;
              }
            } catch (err) {
              console.error('Error loading company/wallet data:', err);
            }
          }

          // Convert 'user' role to 'funcionario' for consistency
          const displayRole = userData.role === 'user' ? 'funcionario' : userData.role;

          return {
            id: userDoc.id,
            email: userData.email || '',
            displayName: userData.displayName || '',
            companyId: userData.companyId,
            role: displayRole,
            photoBase64: userData.photoBase64,
            company: companyData,
            walletBalance
          };
        })
      );

      // Filter users based on role and company
      const filteredUsers = currentUser.role === 'admin' 
        ? usersData 
        : usersData.filter(user => 
            user.companyId === currentUser.companyId && 
            user.role === 'funcionario'
          );

      setUsers(filteredUsers);
      setError('');
    } catch (err) {
      console.error('Error loading users:', err);
      setError(err instanceof Error ? err.message : 'Erro ao carregar usuários');
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  return { users, loading, error, refreshUsers: loadUsers };
}