import { useState } from 'react';
import { X, Phone } from 'lucide-react';
import { useWallet } from '../../contexts/WalletContext';
import { IfthenpayService, PAYMENT_STATUS } from '../../services/ifthenpay';
import { useAuth } from '../../contexts/AuthContext';
import MBWayPaymentStatus from '../payments/MBWayPaymentStatus';

const RECHARGE_OPTIONS = [50, 100, 150, 200, 250, 500];

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  userName: string;
  userEmail: string;
}

export function WalletModal({
  isOpen,
  onClose,
  userName,
  userEmail
}: WalletModalProps) {
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [phone, setPhone] = useState('');
  const [paymentReference, setPaymentReference] = useState<string | null>(null);

  const { balance } = useWallet();
  const { currentUser } = useAuth();

  if (!isOpen) return null;

  const validatePhone = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/[\s-]/g, '');
    const regex = /^9\d{8}$/;
    return regex.test(cleaned);
  };

  const handleMBWayPayment = async () => {
    if (!currentUser?.companyId || !selectedAmount || !phone) {
      setError('Por favor, preencha todos os campos');
      return;
    }

    if (!validatePhone(phone)) {
      setError('Número de telemóvel inválido. Deve começar com 9 e ter 9 dígitos.');
      return;
    }

    try {
      setLoading(true);
      setError('');
      
      const response = await IfthenpayService.createMBWayPayment({
        amount: selectedAmount,
        phone,
        orderId: `WALLET-${currentUser.companyId}-${Date.now()}`,
        description: `Carregamento de carteira LOGNOW - ${userName}`,
        email: userEmail
      });

      if (response.Status === '000' && response.RequestId) {
        setPaymentReference(response.RequestId);
      } else {
        throw new Error(response.Message || 'Erro ao processar pagamento');
      }
    } catch (err: any) {
      console.error('Error processing MBWay payment:', err);
      setError(err.Message || 'Erro ao processar pagamento MBWay');
      setPaymentReference(null);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = () => {
    setSuccess('Pagamento realizado com sucesso!');
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const handlePaymentExpire = () => {
    setError('Tempo para pagamento expirado');
    setPaymentReference(null);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-semibold">Minha Carteira</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        <div className="text-center mb-6">
          <p className="text-sm text-gray-600">Saldo Atual</p>
          <p className="text-3xl font-bold text-primary">€{balance.toFixed(2)}</p>
        </div>

        {error && (
          <div className="mb-4 text-red-600 text-sm text-center">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 text-green-600 text-sm text-center">
            {success}
          </div>
        )}

        {paymentReference ? (
          <MBWayPaymentStatus
            reference={paymentReference}
            onSuccess={handlePaymentSuccess}
            onExpire={handlePaymentExpire}
          />
        ) : (
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-2 mb-6">
              {RECHARGE_OPTIONS.map((amount) => (
                <button
                  key={amount}
                  onClick={() => setSelectedAmount(amount)}
                  className={`p-2 rounded-lg border ${
                    selectedAmount === amount
                      ? 'border-primary bg-primary/5'
                      : 'border-gray-200 hover:border-primary/50'
                  }`}
                >
                  €{amount}
                </button>
              ))}
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Número de Telemóvel
                </label>
                <div className="relative">
                  <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="9xxxxxxxx"
                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  Exemplo: 912345678
                </p>
              </div>
            </div>

            <button
              onClick={handleMBWayPayment}
              disabled={loading || !selectedAmount || !phone}
              className="w-full py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
            >
              {loading ? 'Processando...' : 'Pagar com MB WAY'}
            </button>

            <p className="text-sm text-gray-500 text-center mt-4">
              Para outro tipo de carregamento por transferência ou Multibanco contacte o suporte
            </p>
          </div>
        )}
      </div>
    </div>
  );
}