import { useState } from 'react';
import { DollarSign, Send } from 'lucide-react';
import { MarketplacePrices as MarketplacePricesType } from '../services/baselinker';
import PriceUpdateRequestModal from './PriceUpdateRequestModal';

interface MarketplacePricesProps {
  prices: MarketplacePricesType;
  productEan: string;
  productName: string;
  companyName: string;
}

export default function MarketplacePrices({ prices, productEan, productName, companyName }: MarketplacePricesProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const marketplaces = [
    { id: 'emtuacasa', name: 'EMTUACASA', price: prices.emtuacasa },
    { id: 'worten', name: 'Worten', price: prices.worten },
    { id: 'fnac', name: 'Fnac', price: prices.fnac },
    { id: 'amazon', name: 'Amazon', price: prices.amazon }
  ];

  return (
    <>
      <div className="bg-gray-50 p-6 rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <h3 className="flex items-center gap-2 text-lg font-semibold">
            <DollarSign className="text-gray-400" size={20} />
            Preços nos Marketplaces
          </h3>
          <button
            onClick={() => setIsModalOpen(true)}
            className="text-primary hover:text-primary-dark text-sm flex items-center gap-1"
          >
            <Send size={16} />
            Solicitar alteração
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {marketplaces.map((marketplace) => (
            <div key={marketplace.id} className="p-3 bg-white rounded-lg shadow-sm">
              <p className="text-sm text-gray-500">{marketplace.name}</p>
              <p className="text-lg font-semibold">€{marketplace.price.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>

      <PriceUpdateRequestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        marketplaces={marketplaces}
        productEan={productEan}
        productName={productName}
        companyName={companyName}
      />
    </>
  );
}