import { PORTUGAL_DISTRICTS } from '../../utils/constants';

interface DistrictSelectProps {
  value: string;
  onChange: (district: string) => void;
  required?: boolean;
  className?: string;
}

export default function DistrictSelect({
  value,
  onChange,
  required = false,
  className = ''
}: DistrictSelectProps) {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      className={`block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary ${className}`}
    >
      <option value="">Selecione um distrito</option>
      {PORTUGAL_DISTRICTS.map((district) => (
        <option key={district} value={district}>
          {district}
        </option>
      ))}
    </select>
  );
}