import { useState } from 'react';
import { ShoppingCart, ChevronDown, Building2 } from 'lucide-react';

interface OrderHeaderProps {
  orderNumber: string;
  status: string;
  isSeller?: boolean;
  onUpdateStatus?: (newStatus: string) => void;
  onCancel?: () => void;
  updating?: boolean;
  buyerCompanyId?: string;
  total: number;
  buyerName?: string;
  sellerName?: string;
}

export const ORDER_STATUSES = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido'
} as const;

export default function OrderHeader({ 
  orderNumber, 
  status,
  isSeller = true,
  onUpdateStatus,
  onCancel,
  updating,
  buyerName,
  sellerName
}: OrderHeaderProps) {
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [error, setError] = useState('');

  const isImmutableStatus = status === 'cancelled' || status === 'returned';

  const handleStatusChange = async (newStatus: string) => {
    if (isImmutableStatus) {
      setError('Esta encomenda não pode ser alterada pois já foi cancelada ou devolvida');
      return;
    }

    // Se o novo status for "cancelled", abre o modal de cancelamento
    if (newStatus === 'cancelled') {
      if (onCancel) {
        onCancel();
        setShowStatusMenu(false);
      }
      return;
    }

    // Para outros status, atualiza normalmente
    if (onUpdateStatus && !updating) {
      onUpdateStatus(newStatus);
      setShowStatusMenu(false);
    }
  };

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <ShoppingCart className="text-primary" size={24} />
          <h2 className="text-2xl font-bold text-secondary">
            Encomenda #{orderNumber}
          </h2>
        </div>

        <div className="relative">
          <button
            onClick={() => !isImmutableStatus && setShowStatusMenu(!showStatusMenu)}
            disabled={updating || isImmutableStatus}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg ${getStatusColor(status)} hover:opacity-90 transition-colors ${isImmutableStatus ? 'cursor-not-allowed opacity-80' : ''}`}
          >
            <span>{ORDER_STATUSES[status as keyof typeof ORDER_STATUSES]}</span>
            {!isImmutableStatus && (
              <ChevronDown size={16} className={`transform transition-transform duration-200 ${showStatusMenu ? 'rotate-180' : ''}`} />
            )}
          </button>

          {showStatusMenu && !isImmutableStatus && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 border border-gray-100 z-50">
              {Object.entries(ORDER_STATUSES).map(([value, label]) => (
                <button
                  key={value}
                  onClick={() => handleStatusChange(value)}
                  className={`w-full text-left px-4 py-2 hover:bg-gray-50 ${
                    value === status ? 'bg-gray-50 font-medium' : ''
                  } ${updating ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={updating}
                >
                  {label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {error && (
        <div className="text-red-600 text-sm">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {buyerName && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600 mb-2">
              <Building2 size={20} />
              <span className="font-medium">Comprador</span>
            </div>
            <p className="text-gray-800">{buyerName}</p>
          </div>
        )}

        {sellerName && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600 mb-2">
              <Building2 size={20} />
              <span className="font-medium">Vendedor</span>
            </div>
            <p className="text-gray-800">{sellerName}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function getStatusColor(status: string) {
  switch (status) {
    case 'pending': return 'text-yellow-600 bg-yellow-100';
    case 'preparation': return 'text-blue-600 bg-blue-100';
    case 'awaitingStock': return 'text-orange-600 bg-orange-100';
    case 'awaitingPickup': return 'text-purple-600 bg-purple-100';
    case 'shipped': return 'text-indigo-600 bg-indigo-100';
    case 'delivered': return 'text-green-600 bg-green-100';
    case 'cancelled': return 'text-red-600 bg-red-100';
    case 'returned': return 'text-gray-600 bg-gray-100';
    default: return 'text-gray-600 bg-gray-100';
  }
}