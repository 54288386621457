import { useState } from 'react';
import { CreditCard, Truck, MessageSquare } from 'lucide-react';

interface PaymentMethodSelectorProps {
  companyId: string;
  onSelect: (method: string) => void;
  total: number;
}

export default function PaymentMethodSelector({
  onSelect,
  total
}: PaymentMethodSelectorProps) {
  const [selectedMethod, setSelectedMethod] = useState<string>('bank_transfer');

  const handleMethodSelect = (method: string) => {
    setSelectedMethod(method);
    onSelect(method);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Método de Pagamento</h3>

      <div className="space-y-2">
        <label className="flex items-center gap-3 p-4 border rounded-lg cursor-pointer hover:border-primary">
          <input
            type="radio"
            name="paymentMethod"
            value="bank_transfer"
            checked={selectedMethod === 'bank_transfer'}
            onChange={() => handleMethodSelect('bank_transfer')}
            className="text-primary focus:ring-primary"
          />
          <div>
            <div className="flex items-center gap-2">
              <CreditCard size={20} className="text-gray-400" />
              <span className="font-medium">Transferência Bancária</span>
            </div>
            <p className="text-sm text-gray-500">
              Pagamento por transferência bancária
            </p>
          </div>
        </label>

        <label className="flex items-center gap-3 p-4 border rounded-lg cursor-pointer hover:border-primary">
          <input
            type="radio"
            name="paymentMethod"
            value="cash_on_delivery"
            checked={selectedMethod === 'cash_on_delivery'}
            onChange={() => handleMethodSelect('cash_on_delivery')}
            className="text-primary focus:ring-primary"
          />
          <div>
            <div className="flex items-center gap-2">
              <Truck size={20} className="text-gray-400" />
              <span className="font-medium">À Cobrança</span>
            </div>
            <p className="text-sm text-gray-500">
              Pagamento no momento da entrega
            </p>
          </div>
        </label>

        <label className="flex items-center gap-3 p-4 border rounded-lg cursor-pointer hover:border-primary">
          <input
            type="radio"
            name="paymentMethod"
            value="manual"
            checked={selectedMethod === 'manual'}
            onChange={() => handleMethodSelect('manual')}
            className="text-primary focus:ring-primary"
          />
          <div>
            <div className="flex items-center gap-2">
              <MessageSquare size={20} className="text-gray-400" />
              <span className="font-medium">Pagamento Manual</span>
            </div>
            <p className="text-sm text-gray-500">
              Combine com o seu cliente a melhor forma de receber o pagamento
            </p>
          </div>
        </label>
      </div>
    </div>
  );
}