import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { Building, Mail, Phone, Clock, Upload } from 'lucide-react';

export default function CompanySetup() {
  const [formData, setFormData] = useState({
    legalName: '',
    commercialName: '',
    address: '',
    workingHours: '',
    commercialEmail: '',
    commercialPhone: '',
  });
  const [logo, setLogo] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    try {
      setLoading(true);
      setError('');

      let logoUrl = '';
      if (logo) {
        const logoRef = ref(storage, `companies/${currentUser.uid}/logo`);
        await uploadBytes(logoRef, logo);
        logoUrl = await getDownloadURL(logoRef);
      }

      const companyData = {
        ...formData,
        logo: logoUrl,
        ownerId: currentUser.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Save company data
      await setDoc(doc(db, 'companies', currentUser.uid), companyData);
      
      // Update user with company ID and role
      await setDoc(doc(db, 'users', currentUser.uid), {
        companyId: currentUser.uid,
        role: 'owner',
        updatedAt: new Date().toISOString()
      }, { merge: true });

      // Navigate to profile page
      navigate('/profile');
    } catch (err) {
      console.error('Error creating company:', err);
      setError('Erro ao criar empresa. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white rounded-lg shadow p-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-secondary">Configurar Empresa</h2>
          <p className="mt-2 text-gray-600">Preencha os dados da sua empresa</p>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nome Legal</label>
              <div className="mt-1 relative">
                <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  required
                  className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.legalName}
                  onChange={(e) => setFormData({...formData, legalName: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Nome Comercial</label>
              <div className="mt-1 relative">
                <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  required
                  className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.commercialName}
                  onChange={(e) => setFormData({...formData, commercialName: e.target.value})}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Morada</label>
              <div className="mt-1">
                <textarea
                  required
                  rows={3}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.address}
                  onChange={(e) => setFormData({...formData, address: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email Comercial</label>
              <div className="mt-1 relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="email"
                  required
                  className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.commercialEmail}
                  onChange={(e) => setFormData({...formData, commercialEmail: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Telefone Comercial</label>
              <div className="mt-1 relative">
                <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="tel"
                  required
                  className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.commercialPhone}
                  onChange={(e) => setFormData({...formData, commercialPhone: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Horário de Trabalho</label>
              <div className="mt-1 relative">
                <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  required
                  placeholder="Ex: 9h-18h"
                  className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                  value={formData.workingHours}
                  onChange={(e) => setFormData({...formData, workingHours: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Logo da Empresa</label>
              <div className="mt-1 flex items-center">
                <label className="w-full flex justify-center px-6 py-3 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-primary">
                  <div className="space-y-1 text-center">
                    <Upload className="mx-auto text-gray-400" size={20} />
                    <div className="text-sm text-gray-600">
                      {logo ? logo.name : 'Clique para carregar'}
                    </div>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => e.target.files && setLogo(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="bg-primary hover:bg-primary-dark text-secondary font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
            >
              {loading ? 'A criar...' : 'Criar Empresa'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}