import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, runTransaction, collection, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { Package, Truck, Home, ShoppingCart, AlertTriangle } from 'lucide-react';
import { calculateCommission } from '../utils/commissionUtils';
import DistrictSelect from '../components/shipping/DistrictSelect';

interface ShippingAddress {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  district: string;
}

export default function PrivateSalesCheckout() {
  const [shippingMethod, setShippingMethod] = useState<'normal' | 'dropshipping'>('normal');
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress>({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postalCode: '',
    country: 'Portugal',
    district: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [companyDistrict, setCompanyDistrict] = useState<string>('');
  const [shippingCosts, setShippingCosts] = useState<Record<string, number>>({});
  const [showMultiSellerWarning, setShowMultiSellerWarning] = useState(false);

  const { currentUser } = useAuth();
  const { items, clearCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.companyId) {
      loadCompanyData();
    }
  }, [currentUser]);

  useEffect(() => {
    loadShippingCosts();
  }, [shippingMethod, shippingAddress.district, companyDistrict]);

  useEffect(() => {
    // Check if there are items from different sellers
    const uniqueSellers = new Set(items.map(item => item.companyId));
    setShowMultiSellerWarning(uniqueSellers.size > 1);
  }, [items]);

  const loadCompanyData = async () => {
    if (!currentUser?.companyId) return;

    try {
      const companyDoc = await getDoc(doc(db, 'companies', currentUser.companyId));
      if (companyDoc.exists()) {
        const companyData = companyDoc.data();
        setCompanyAddress(companyData.address || '');
        setCompanyDistrict(companyData.district || '');
      }
    } catch (err) {
      console.error('Error loading company data:', err);
      setError('Erro ao carregar dados da empresa');
    }
  };

  const loadShippingCosts = async () => {
    try {
      const shippingDoc = await getDoc(doc(db, 'settings', 'shipping_costs'));
      if (shippingDoc.exists()) {
        setShippingCosts(shippingDoc.data().costs || {});
      }
    } catch (err) {
      console.error('Error loading shipping costs:', err);
    }
  };

  // Group items by seller
  const itemsBySeller = items.reduce((acc, item) => {
    if (!acc[item.companyId]) {
      acc[item.companyId] = {
        items: [],
        companyName: item.companyName,
        subtotal: 0
      };
    }
    acc[item.companyId].items.push(item);
    acc[item.companyId].subtotal += item.price * item.quantity;
    return acc;
  }, {} as Record<string, { items: typeof items, companyName: string, subtotal: number }>);

  const getShippingCost = (district: string) => {
    return shippingCosts[district] || 0;
  };

  const calculateTotals = () => {
    const district = shippingMethod === 'normal' ? companyDistrict : shippingAddress.district;
    const shippingCostPerSeller = getShippingCost(district);
    const numberOfSellers = Object.keys(itemsBySeller).length;
    const totalShipping = shippingCostPerSeller * numberOfSellers;

    const subtotal = Object.values(itemsBySeller).reduce((sum, seller) => sum + seller.subtotal, 0);
    return {
      subtotal,
      shipping: totalShipping,
      total: subtotal + totalShipping
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.companyId) return;

    const totals = calculateTotals();
    const commission = calculateCommission(totals.total, false); // false for private sale

    setLoading(true);
    setError('');

    try {
      await runTransaction(db, async (transaction) => {
        // Check wallet balance
        const walletRef = doc(db, 'wallets', currentUser.companyId);
        const walletDoc = await transaction.get(walletRef);
        
        if (!walletDoc.exists()) {
          throw new Error('Carteira não encontrada');
        }

        const currentBalance = walletDoc.data().balance;
        if (currentBalance < commission.totalCommission) {
          throw new Error(`Saldo insuficiente. Por favor, carregue sua carteira com pelo menos €${commission.totalCommission.toFixed(2)}`);
        }

        // Update wallet balance
        transaction.update(walletRef, {
          balance: currentBalance - commission.totalCommission,
          updatedAt: new Date().toISOString()
        });

        // Create commission transaction record
        const commissionTransactionRef = doc(collection(db, 'walletTransactions'));
        transaction.set(commissionTransactionRef, {
          type: 'commission',
          amount: commission.totalCommission,
          baseAmount: commission.baseCommission,
          vatAmount: commission.vatAmount,
          status: 'completed',
          description: 'Comissão LOGNOW - Venda Privada',
          companyId: currentUser.companyId,
          createdAt: new Date()
        });

        // Create orders for each seller
        for (const [sellerId, sellerData] of Object.entries(itemsBySeller)) {
          const orderId = `PS${Date.now()}${Math.floor(Math.random() * 1000)}`;
          const orderRef = doc(collection(db, 'orders'));

          const district = shippingMethod === 'normal' ? companyDistrict : shippingAddress.district;
          const sellerShippingCost = getShippingCost(district);

          transaction.set(orderRef, {
            orderNumber: orderId,
            buyerId: currentUser.uid,
            buyerCompanyId: currentUser.companyId,
            sellerId,
            items: sellerData.items.map(item => ({
              productId: item.productId,
              name: item.name,
              quantity: item.quantity,
              price: item.price,
              total: item.price * item.quantity,
              mainImage: item.mainImage
            })),
            shippingMethod,
            shippingAddress: shippingMethod === 'dropshipping' ? shippingAddress : {
              name: currentUser.displayName || '',
              email: currentUser.email || '',
              phone: currentUser.phone || '',
              address: companyAddress,
              city: '',
              postalCode: '',
              country: 'Portugal',
              district: companyDistrict
            },
            subtotal: sellerData.subtotal,
            shippingCost: sellerShippingCost,
            total: sellerData.subtotal + sellerShippingCost,
            commission: commission.totalCommission,
            isPrivateSale: true,
            status: 'pending',
            createdAt: new Date().toISOString(),
            createdBy: currentUser.uid,
            comments: [{
              text: 'Portes pagos pelo comprador',
              createdAt: new Date().toISOString(),
              createdBy: 'Sistema'
            }]
          });
        }
      });

      clearCart();
      navigate('/private-sales/orders');
    } catch (err) {
      console.error('Error creating order:', err);
      setError(err instanceof Error ? err.message : 'Erro ao criar encomenda');
      setLoading(false);
    }
  };

  const totals = calculateTotals();

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold text-secondary mb-6">Finalizar Compra</h2>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {showMultiSellerWarning && (
          <div className="mb-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <AlertTriangle className="text-yellow-400 mr-2" size={24} />
              <p className="text-yellow-700">
                Atenção: Você está comprando produtos de diferentes vendedores. 
                Será cobrado o valor dos portes para cada vendedor separadamente.
              </p>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Order Summary */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Resumo do Pedido</h3>
            <div className="space-y-6">
              {Object.entries(itemsBySeller).map(([sellerId, sellerData]) => (
                <div key={sellerId} className="border-b pb-4 last:border-b-0 last:pb-0">
                  <h4 className="font-medium mb-2">Vendedor: {sellerData.companyName}</h4>
                  {sellerData.items.map((item) => (
                    <div key={item.productId} className="flex gap-4 mb-4">
                      {item.mainImage ? (
                        <img
                          src={item.mainImage}
                          alt={item.name}
                          className="w-20 h-20 object-cover rounded-lg"
                        />
                      ) : (
                        <div className="w-20 h-20 bg-gray-100 flex items-center justify-center rounded-lg">
                          <Package className="h-8 w-8 text-gray-400" />
                        </div>
                      )}
                      <div className="flex-1">
                        <h4 className="font-medium">{item.name}</h4>
                        <div className="flex justify-between mt-2">
                          <span>Quantidade: {item.quantity}</span>
                          <span className="font-medium">€{(item.price * item.quantity).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="text-sm text-gray-600">
                    Subtotal: €{sellerData.subtotal.toFixed(2)}
                  </div>
                </div>
              ))}

              <div className="pt-4 border-t border-gray-200">
                <div className="flex justify-between items-center">
                  <span>Subtotal:</span>
                  <span>€{totals.subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <span>Envio ({Object.keys(itemsBySeller).length} vendedor{Object.keys(itemsBySeller).length > 1 ? 'es' : ''}):</span>
                  <span>€{totals.shipping.toFixed(2)}</span>
                </div>
                <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200 font-bold">
                  <span>Total</span>
                  <span>€{totals.total.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Shipping Method */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Método de Envio</h3>
            <div className="space-y-4">
              <label className="flex items-start gap-3 p-4 border rounded-lg cursor-pointer hover:border-primary">
                <input
                  type="radio"
                  name="shippingMethod"
                  value="normal"
                  checked={shippingMethod === 'normal'}
                  onChange={(e) => setShippingMethod(e.target.value as 'normal' | 'dropshipping')}
                  className="mt-1"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <Home size={20} className="text-gray-400" />
                    <span className="font-medium">Envio Normal</span>
                  </div>
                  <p className="text-sm text-gray-500">
                    Enviar para o endereço da minha empresa
                  </p>
                  {shippingMethod === 'normal' && companyAddress && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-600">{companyAddress}</p>
                      <p className="text-sm text-gray-600">Distrito: {companyDistrict || 'Não definido'}</p>
                    </div>
                  )}
                </div>
              </label>

              <label className="flex items-start gap-3 p-4 border rounded-lg cursor-pointer hover:border-primary">
                <input
                  type="radio"
                  name="shippingMethod"
                  value="dropshipping"
                  checked={shippingMethod === 'dropshipping'}
                  onChange={(e) => setShippingMethod(e.target.value as 'normal' | 'dropshipping')}
                  className="mt-1"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <Truck size={20} className="text-gray-400" />
                    <span className="font-medium">Dropshipping Anônimo</span>
                  </div>
                  <p className="text-sm text-gray-500">
                    Enviar diretamente para meu cliente
                  </p>
                </div>
              </label>
            </div>

            {shippingMethod === 'dropshipping' && (
              <div className="mt-6 bg-gray-50 p-6 rounded-lg">
                <h4 className="font-medium mb-4">Endereço de Entrega</h4>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Nome do Destinatário
                    </label>
                    <input
                      type="text"
                      required
                      value={shippingAddress.name}
                      onChange={(e) => setShippingAddress({ ...shippingAddress, name: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      required
                      value={shippingAddress.email}
                      onChange={(e) => setShippingAddress({ ...shippingAddress, email: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Telefone
                    </label>
                    <input
                      type="tel"
                      required
                      value={shippingAddress.phone}
                      onChange={(e) => setShippingAddress({ ...shippingAddress, phone: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Morada
                    </label>
                    <input
                      type="text"
                      required
                      value={shippingAddress.address}
                      onChange={(e) => setShippingAddress({ ...shippingAddress, address: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Distrito
                    </label>
                    <DistrictSelect
                      value={shippingAddress.district}
                      onChange={(district) => setShippingAddress({ ...shippingAddress, district })}
                      required
                      className="mt-1"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Cidade
                      </label>
                      <input
                        type="text"
                        required
                        value={shippingAddress.city}
                        onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Código Postal
                      </label>
                      <input
                        type="text"
                        required
                        value={shippingAddress.postalCode}
                        onChange={(e) => setShippingAddress({ ...shippingAddress, postalCode: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-8">
              <button
                onClick={handleSubmit}
                disabled={loading}
                className="w-full flex items-center justify-center gap-2 py-3 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
              >
                <ShoppingCart size={20} />
                {loading ? 'Processando...' : 'Finalizar Compra'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}