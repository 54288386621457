import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { User, MessageCircle, Heart, Share2, Package, Trash2 } from 'lucide-react';
import ShareModal from './ShareModal';
import CommentSection from './CommentSection';
import { moderateContent } from '../../utils/moderationUtils';

interface Post {
  id: string;
  content: string;
  createdAt: Date;
  images: string[];
  products: any[];
  likes: string[];
  comments: any[];
  userId: string;
  user: {
    id: string;
    name: string;
    photoBase64: string | null;
    companyName: string | null;
  };
  sharedPost?: {
    id: string;
    content: string;
    images: string[];
    products: any[];
    originalAuthor: {
      id: string;
      name: string;
    };
  };
}

interface PostListProps {
  posts: Post[];
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
}

export function PostList({ posts, onLoadMore, hasMore, loading }: PostListProps) {
  const [isLiking, setIsLiking] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [showComments, setShowComments] = useState<string | null>(null);
  const [moderatedContents, setModeratedContents] = useState<Record<string, string>>({});
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const moderateAllContent = async () => {
      const moderated: Record<string, string> = {};
      
      for (const post of posts) {
        if (post.content) {
          moderated[`post-${post.id}`] = await moderateContent(post.content);
        }
        if (post.sharedPost?.content) {
          moderated[`shared-${post.id}`] = await moderateContent(post.sharedPost.content);
        }
      }
      
      setModeratedContents(moderated);
    };

    moderateAllContent();
  }, [posts]);

  const handleLike = async (postId: string, isLiked: boolean) => {
    if (!currentUser || isLiking) return;

    setIsLiking(true);
    try {
      const postRef = doc(db, 'social_posts', postId);
      await updateDoc(postRef, {
        likes: isLiked ? arrayRemove(currentUser.uid) : arrayUnion(currentUser.uid)
      });
    } catch (err) {
      console.error('Error updating like:', err);
    } finally {
      setIsLiking(false);
    }
  };

  const handleShare = (post: Post) => {
    setSelectedPost(post);
  };

  const toggleComments = (postId: string) => {
    setShowComments(showComments === postId ? null : postId);
  };

  const handleDeletePost = async (postId: string) => {
    if (!currentUser) return;

    const confirmDelete = window.confirm('Tem certeza que deseja excluir esta publicação?');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'social_posts', postId));
    } catch (err) {
      console.error('Error deleting post:', err);
    }
  };

  const canDeletePost = (post: Post) => {
    if (!currentUser) return false;
    return currentUser.role === 'admin' || post.userId === currentUser.uid;
  };

  return (
    <div className="space-y-6">
      {posts.map((post) => (
        <div key={post.id} className="bg-white rounded-lg shadow p-6">
          {/* User Info */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(`/social/profile/${post.user.id}`)}
                className="flex-shrink-0"
              >
                {post.user.photoBase64 ? (
                  <img
                    src={post.user.photoBase64}
                    alt={post.user.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                    <User className="w-6 h-6 text-gray-400" />
                  </div>
                )}
              </button>
              <div>
                <button
                  onClick={() => navigate(`/social/profile/${post.user.id}`)}
                  className="font-medium hover:text-primary"
                >
                  {post.user.name}
                </button>
                {post.user.companyName && (
                  <p className="text-sm text-gray-500">{post.user.companyName}</p>
                )}
                <p className="text-xs text-gray-400">
                  {post.createdAt.toLocaleString('pt-PT')}
                </p>
              </div>
            </div>

            {canDeletePost(post) && (
              <button
                onClick={() => handleDeletePost(post.id)}
                className="text-red-500 hover:text-red-600 p-2"
                title="Excluir publicação"
              >
                <Trash2 size={20} />
              </button>
            )}
          </div>

          {/* Content */}
          {post.content && moderatedContents[`post-${post.id}`] && (
            <p className="mb-4 whitespace-pre-wrap">{moderatedContents[`post-${post.id}`]}</p>
          )}

          {/* Shared Post */}
          {post.sharedPost && (
            <div className="mx-6 mb-4 border rounded-lg p-4 bg-gray-50">
              <div className="flex items-center gap-2 mb-2">
                <Share2 size={16} className="text-gray-400" />
                <span className="text-sm text-gray-500">
                  Compartilhado da publicação de{' '}
                  <button
                    onClick={() => navigate(`/social/profile/${post.sharedPost?.originalAuthor.id}`)}
                    className="text-primary hover:underline"
                  >
                    {post.sharedPost.originalAuthor.name}
                  </button>
                </span>
              </div>
              
              {post.sharedPost.content && moderatedContents[`shared-${post.id}`] && (
                <p className="text-gray-600 mb-4">{moderatedContents[`shared-${post.id}`]}</p>
              )}

              {post.sharedPost.images?.length > 0 && (
                <div className="grid grid-cols-2 gap-2">
                  {post.sharedPost.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Imagem compartilhada ${index + 1}`}
                      className="rounded-lg w-full h-48 object-cover"
                    />
                  ))}
                </div>
              )}

              {post.sharedPost.products?.length > 0 && (
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {post.sharedPost.products.map((product) => (
                    <button
                      key={product.id}
                      onClick={() => navigate(`/products/${product.id}`)}
                      className="flex items-center gap-3 p-3 rounded-lg border hover:border-primary text-left"
                    >
                      {product.mainImage ? (
                        <img
                          src={product.mainImage}
                          alt={product.name}
                          className="w-16 h-16 rounded object-cover"
                        />
                      ) : (
                        <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
                          <Package className="w-8 h-8 text-gray-400" />
                        </div>
                      )}
                      <div>
                        <p className="font-medium">{product.name}</p>
                        <p className="text-primary">€{product.price.toFixed(2)}</p>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Images */}
          {post.images.length > 0 && (
            <div className={`grid gap-2 mb-4 ${
              post.images.length === 1 ? 'grid-cols-1' :
              post.images.length === 2 ? 'grid-cols-2' :
              'grid-cols-2'
            }`}>
              {post.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Imagem ${index + 1}`}
                  className="rounded-lg w-full h-48 object-cover"
                />
              ))}
            </div>
          )}

          {/* Products */}
          {post.products.length > 0 && (
            <div className="grid grid-cols-2 gap-4 mb-4">
              {post.products.map((product) => (
                <button
                  key={product.id}
                  onClick={() => navigate(`/products/${product.id}`)}
                  className="flex items-center gap-3 p-3 rounded-lg border hover:border-primary text-left"
                >
                  {product.mainImage ? (
                    <img
                      src={product.mainImage}
                      alt={product.name}
                      className="w-16 h-16 rounded object-cover"
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
                      <Package className="w-8 h-8 text-gray-400" />
                    </div>
                  )}
                  <div>
                    <p className="font-medium">{product.name}</p>
                    <p className="text-primary">€{product.price.toFixed(2)}</p>
                  </div>
                </button>
              ))}
            </div>
          )}

          {/* Actions */}
          <div className="flex items-center gap-6 pt-4 border-t">
            <button
              onClick={() => handleLike(post.id, post.likes.includes(currentUser?.uid || ''))}
              disabled={isLiking}
              className={`flex items-center gap-2 ${
                post.likes.includes(currentUser?.uid || '')
                  ? 'text-red-500'
                  : 'text-gray-500 hover:text-red-500'
              }`}
            >
              <Heart className={post.likes.includes(currentUser?.uid || '') ? 'fill-current' : ''} size={20} />
              <span>{post.likes.length}</span>
            </button>
            <button
              onClick={() => toggleComments(post.id)}
              className="flex items-center gap-2 text-gray-500 hover:text-primary"
            >
              <MessageCircle size={20} />
              <span>{post.comments.length}</span>
            </button>
            <button
              onClick={() => handleShare(post)}
              className="flex items-center gap-2 text-gray-500 hover:text-primary"
            >
              <Share2 size={20} />
              <span>Compartilhar</span>
            </button>
          </div>

          {/* Comments Section */}
          {showComments === post.id && (
            <CommentSection 
              postId={post.id} 
              comments={post.comments}
              onCommentAdded={() => {}}
            />
          )}
        </div>
      ))}

      {hasMore && (
        <div className="text-center">
          <button
            onClick={onLoadMore}
            disabled={loading}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 disabled:opacity-50"
          >
            {loading ? 'Carregando...' : 'Carregar mais'}
          </button>
        </div>
      )}

      {/* Share Modal */}
      {selectedPost && (
        <ShareModal
          postId={selectedPost.id}
          originalPost={selectedPost}
          onClose={() => setSelectedPost(null)}
        />
      )}
    </div>
  );
}

export default PostList;