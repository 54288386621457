import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import DistrictShippingCosts from '../components/shipping/DistrictShippingCosts';

export default function ShippingCosts() {
  const { currentUser } = useAuth();

  if (!currentUser?.role === 'admin') {
    return <Navigate to="/" />;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <DistrictShippingCosts />
      </div>
    </div>
  );
}