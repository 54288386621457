import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LogOut } from 'lucide-react';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';

export default function Layout({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={toggleSidebar}
        />
      )}

      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Header toggleSidebar={toggleSidebar} />

      <div className={`${isSidebarOpen ? 'lg:ml-64' : 'ml-0'} transition-all duration-300 min-h-screen pt-16`}>
        <main className="p-6">
          {children}
        </main>
      </div>

      <div className={`fixed bottom-0 left-0 right-0 p-4 ${isSidebarOpen ? 'lg:ml-64' : 'ml-0'} transition-all duration-300 lg:hidden`}>
        <button
          onClick={handleLogout}
          className="w-full flex items-center justify-center gap-2 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
        >
          <LogOut size={20} />
          <span>Terminar Sessão</span>
        </button>
      </div>
    </div>
  );
}