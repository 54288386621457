import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Eye, ShoppingCart, Package, Truck, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Order {
  id: string;
  orderNumber: string;
  total: number;
  status: 'pending' | 'processing' | 'shipped' | 'delivered' | 'cancelled';
  createdAt: any;
  sellerId: string;
  sellerName?: string;
}

export default function PrivateSaleOrders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.companyId) {
      loadOrders();
    }
  }, [currentUser]);

  const formatDate = (timestamp: any): string => {
    if (!timestamp) return '-';
    
    try {
      // Handle Firestore Timestamp
      if (timestamp?.toDate) {
        return timestamp.toDate().toLocaleString('pt-PT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      }
      
      // Handle string timestamp
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return '-';
      }
      
      return date.toLocaleString('pt-PT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'processing': return 'bg-blue-100 text-blue-800';
      case 'shipped': return 'bg-purple-100 text-purple-800';
      case 'delivered': return 'bg-green-100 text-green-800';
      case 'cancelled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'pending': return 'Pendente';
      case 'processing': return 'Em Processamento';
      case 'shipped': return 'Enviada';
      case 'delivered': return 'Entregue';
      case 'cancelled': return 'Cancelada';
      default: return status;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'pending': return ShoppingCart;
      case 'processing': return Package;
      case 'shipped': return Truck;
      case 'delivered': return CheckCircle;
      default: return ShoppingCart;
    }
  };

  const loadOrders = async () => {
    if (!currentUser?.companyId) return;

    try {
      // Get all orders for this company
      const ordersRef = collection(db, 'orders');
      const ordersQuery = query(
        ordersRef,
        where('buyerCompanyId', '==', currentUser.companyId),
        where('isPrivateSale', '==', true)
      );
      
      const snapshot = await getDocs(ordersQuery);
      
      // Load orders and seller company data
      const ordersData = await Promise.all(
        snapshot.docs.map(async (orderDoc) => {
          const data = orderDoc.data();
          let sellerName = 'Empresa não encontrada';
          
          // Load seller company data
          if (data.sellerId) {
            try {
              const companyDoc = await getDoc(doc(db, 'companies', data.sellerId));
              if (companyDoc.exists()) {
                const companyData = companyDoc.data();
                sellerName = companyData.commercialName || companyData.legalName;
              }
            } catch (err) {
              console.error('Error loading company data:', err);
            }
          }
          
          return {
            id: orderDoc.id,
            orderNumber: data.orderNumber,
            total: data.total,
            status: data.status,
            createdAt: data.createdAt,
            sellerId: data.sellerId,
            sellerName
          };
        })
      );
      
      // Sort orders by createdAt date
      const sortedOrders = ordersData.sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      
      setOrders(sortedOrders);
      setError('');
    } catch (err) {
      console.error('Error loading orders:', err);
      setError('Erro ao carregar pedidos. Por favor, atualize a página.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-secondary mb-6">Pedidos de Venda Privada</h2>

          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {error}
            </div>
          )}

          {orders.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500">
                Você ainda não realizou nenhum pedido em vendas privadas.
              </p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nº Encomenda
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Vendedor
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valor
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orders.map((order) => {
                    const StatusIcon = getStatusIcon(order.status);
                    return (
                      <tr key={order.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.orderNumber}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {formatDate(order.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.sellerName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}>
                            <StatusIcon className="w-4 h-4 mr-1" />
                            {getStatusText(order.status)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          €{order.total.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => navigate(`/orders/${order.id}`)}
                            className="text-primary hover:text-primary-dark"
                            title="Ver detalhes"
                          >
                            <Eye size={20} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}