import { Commission } from '../types';

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('pt-PT', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);
};

export const calculateCommission = (total: number, isManualOrder: boolean = true): {
  baseCommission: number;
  vatAmount: number;
  totalCommission: number;
} => {
  // Manual orders: 7.5%, Private sales: 15%
  const commissionRate = isManualOrder ? 0.075 : 0.15;
  const baseCommission = total * commissionRate;
  const vatAmount = baseCommission * 0.23; // 23% VAT
  const totalCommission = baseCommission + vatAmount;

  return {
    baseCommission: Number(baseCommission.toFixed(2)),
    vatAmount: Number(vatAmount.toFixed(2)),
    totalCommission: Number(totalCommission.toFixed(2))
  };
};

export const calculateCommissionTotals = (commissions: Commission[]) => {
  return commissions.reduce((acc, commission) => {
    if (commission.type === 'commission') {
      acc.totalCommissions += commission.amount;
      acc.totalVat += commission.vatAmount;
    } else if (commission.type === 'commission_refund') {
      acc.totalRefunds += commission.amount;
    }
    acc.netTotal = acc.totalCommissions - acc.totalRefunds;
    return acc;
  }, {
    totalCommissions: 0,
    totalRefunds: 0,
    totalVat: 0,
    netTotal: 0
  });
};