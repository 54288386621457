import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, User, LogOut, UserCircle, Wallet, ShoppingCart, Truck } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useCart } from '../../contexts/CartContext';
import { useWallet } from '../../contexts/WalletContext';
import { WalletModal } from '../wallet/WalletModal';
import CartDrawer from './CartDrawer';

interface HeaderProps {
  toggleSidebar: () => void;
}

export default function Header({ toggleSidebar }: HeaderProps) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showCartDrawer, setShowCartDrawer] = useState(false);
  const { effectiveUser, logout } = useAuth();
  const { totalItems } = useCart();
  const { balance } = useWallet();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleLogisticsAccess = () => {
    window.location.href = 'https://logistica.lognow.pt';
  };

  return (
    <header className="bg-white shadow-sm fixed top-0 right-0 left-0 z-40">
      <div className="flex items-center justify-between px-4 py-2 h-16">
        <button
          onClick={toggleSidebar}
          className="p-2 rounded-lg hover:bg-gray-100 lg:ml-2"
        >
          <Menu size={24} />
        </button>

        <div className="flex items-center gap-4">
          <button
            onClick={handleLogisticsAccess}
            className="flex items-center gap-2 px-4 py-2 bg-secondary text-white rounded-lg hover:bg-secondary-dark"
          >
            <Truck size={20} />
            <span>Aceder a Logística</span>
          </button>

          <button
            onClick={() => setShowCartDrawer(true)}
            className="relative p-2 rounded-lg hover:bg-gray-100"
          >
            <ShoppingCart size={20} />
            {totalItems > 0 && (
              <span className="absolute -top-1 -right-1 bg-primary text-secondary text-xs w-5 h-5 rounded-full flex items-center justify-center">
                {totalItems}
              </span>
            )}
          </button>

          <button
            onClick={() => setShowWalletModal(true)}
            className="flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <Wallet size={20} className="text-primary" />
            <span className="font-medium">€{balance.toFixed(2)}</span>
          </button>

          <div className="relative">
            <button
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="flex items-center gap-3 px-3 py-2 rounded-lg hover:bg-gray-100 transition-colors"
            >
              {effectiveUser?.photoBase64 ? (
                <img
                  src={effectiveUser.photoBase64}
                  alt="Foto de perfil"
                  className="h-8 w-8 rounded-full object-cover border border-gray-200"
                />
              ) : (
                <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                  <User className="h-5 w-5 text-gray-600" />
                </div>
              )}
            </button>

            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 border border-gray-100">
                <button
                  onClick={() => {
                    navigate('/profile');
                    setShowUserMenu(false);
                  }}
                  className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 w-full"
                >
                  <UserCircle size={16} />
                  <span>Ver meu perfil</span>
                </button>
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 w-full"
                >
                  <LogOut size={16} />
                  <span>Terminar Sessão</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <WalletModal
        isOpen={showWalletModal}
        onClose={() => setShowWalletModal(false)}
        userName={effectiveUser?.displayName || ''}
        userEmail={effectiveUser?.email || ''}
      />

      <CartDrawer
        isOpen={showCartDrawer}
        onClose={() => setShowCartDrawer(false)}
      />
    </header>
  );
}