import { useState, useEffect } from 'react';
import { collection, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Shield, Plus, Trash2, Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Moderation() {
  const [restrictedWords, setRestrictedWords] = useState<string[]>([]);
  const [newWord, setNewWord] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.role === 'admin') {
      navigate('/');
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'settings', 'moderation'),
      (doc) => {
        if (doc.exists()) {
          setRestrictedWords(doc.data().restrictedWords || []);
        } else {
          // Create initial document if it doesn't exist
          setDoc(doc.ref, { restrictedWords: [] });
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error loading moderation settings:', error);
        setError('Erro ao carregar configurações de moderação');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleAddWord = () => {
    if (!newWord.trim()) return;

    const word = newWord.trim().toLowerCase();
    if (restrictedWords.includes(word)) {
      setError('Esta palavra já está na lista');
      return;
    }

    setRestrictedWords([...restrictedWords, word]);
    setNewWord('');
    setError('');
  };

  const handleRemoveWord = (word: string) => {
    setRestrictedWords(restrictedWords.filter(w => w !== word));
  };

  const handleSave = async () => {
    if (!currentUser?.role === 'admin') return;

    setSaving(true);
    setError('');
    setSuccess('');

    try {
      await setDoc(doc(db, 'settings', 'moderation'), {
        restrictedWords,
        updatedAt: new Date().toISOString(),
        updatedBy: currentUser.uid
      });

      setSuccess('Configurações salvas com sucesso');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      console.error('Error saving moderation settings:', err);
      setError('Erro ao salvar configurações');
    } finally {
      setSaving(false);
    }
  };

  if (!currentUser?.role === 'admin') {
    return null;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center gap-3 mb-6">
          <Shield className="text-primary" size={24} />
          <h2 className="text-2xl font-bold text-secondary">Moderação</h2>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
            {success}
          </div>
        )}

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Adicionar Palavra Restrita
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={newWord}
                onChange={(e) => setNewWord(e.target.value)}
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                placeholder="Digite uma palavra para restringir"
              />
              <button
                onClick={handleAddWord}
                disabled={!newWord.trim()}
                className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
              >
                <Plus size={20} />
              </button>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Palavras Restritas</h3>
            <div className="bg-gray-50 rounded-lg p-4">
              {restrictedWords.length === 0 ? (
                <p className="text-gray-500 text-center py-4">
                  Nenhuma palavra restrita cadastrada
                </p>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {restrictedWords.map((word) => (
                    <div
                      key={word}
                      className="flex items-center gap-2 px-3 py-1 bg-white rounded-full border"
                    >
                      <span>{word}</span>
                      <button
                        onClick={() => handleRemoveWord(word)}
                        className="text-red-500 hover:text-red-600"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end pt-6">
            <button
              onClick={handleSave}
              disabled={saving}
              className="flex items-center gap-2 px-6 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
            >
              <Save size={20} />
              {saving ? 'Salvando...' : 'Salvar Alterações'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}