import { useState, useEffect } from 'react';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Save } from 'lucide-react';

interface ShippingCosts {
  [district: string]: number;
}

const PORTUGAL_DISTRICTS = [
  'Aveiro', 'Beja', 'Braga', 'Bragança', 'Castelo Branco',
  'Coimbra', 'Évora', 'Faro', 'Guarda', 'Leiria',
  'Lisboa', 'Portalegre', 'Porto', 'Santarém', 'Setúbal',
  'Viana do Castelo', 'Vila Real', 'Viseu',
  'Região Autónoma dos Açores', 'Região Autónoma da Madeira'
];

export default function DistrictShippingCosts() {
  const [costs, setCosts] = useState<ShippingCosts>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    loadShippingCosts();
  }, []);

  const loadShippingCosts = async () => {
    try {
      const shippingDoc = await getDoc(doc(db, 'settings', 'shipping_costs'));
      if (shippingDoc.exists()) {
        setCosts(shippingDoc.data().costs || {});
      } else {
        // Initialize with empty costs for all districts
        const initialCosts: ShippingCosts = {};
        PORTUGAL_DISTRICTS.forEach(district => {
          initialCosts[district] = 0;
        });
        setCosts(initialCosts);
      }
    } catch (err) {
      console.error('Error loading shipping costs:', err);
      setError('Erro ao carregar custos de envio');
    } finally {
      setLoading(false);
    }
  };

  const handleCostChange = (district: string, value: string) => {
    const numValue = parseFloat(value) || 0;
    setCosts(prev => ({
      ...prev,
      [district]: numValue
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    setError('');
    setSuccess('');

    try {
      await setDoc(doc(db, 'settings', 'shipping_costs'), {
        costs,
        updatedAt: new Date().toISOString()
      });

      setSuccess('Custos de envio atualizados com sucesso');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      console.error('Error saving shipping costs:', err);
      setError('Erro ao salvar custos de envio');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-secondary">Portes de Envio</h2>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {PORTUGAL_DISTRICTS.map((district) => (
            <div key={district} className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                {district}
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={costs[district] || 0}
                  onChange={(e) => handleCostChange(district, e.target.value)}
                  className="block w-full pl-7 pr-3 py-2 rounded-md border-gray-300 focus:ring-primary focus:border-primary"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
          >
            <Save size={20} />
            {saving ? 'Salvando...' : 'Salvar Alterações'}
          </button>
        </div>
      </form>
    </div>
  );
}