export const validateImage = async (file: File, type: 'profile' | 'logo' | 'post' = 'post'): Promise<{ isValid: boolean; error?: string }> => {
  if (!file.type.startsWith('image/')) {
    return { isValid: false, error: 'O ficheiro deve ser uma imagem' };
  }

  // Profile photos and company logos only check file size
  if (type === 'profile' || type === 'logo') {
    if (file.size > 4 * 1024 * 1024) { // 4MB limit
      return { isValid: false, error: 'A imagem deve ter no máximo 4MB' };
    }
    return { isValid: true };
  }

  // For other images (posts, etc), check dimensions and 4MB size limit
  if (file.size > 4 * 1024 * 1024) {
    return { isValid: false, error: 'A imagem deve ter no máximo 4MB' };
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      if (img.width > 1800 || img.height > 1800) {
        resolve({ isValid: false, error: 'A imagem deve ter no máximo 1800x1800 pixels' });
      } else {
        resolve({ isValid: true });
      }
    };
    img.onerror = () => {
      resolve({ isValid: false, error: 'Erro ao carregar imagem' });
    };
    img.src = URL.createObjectURL(file);
  });
};

export const resizeImage = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        const maxSize = 1800;

        if (width > height) {
          if (width > maxSize) {
            height = Math.round((height * maxSize) / width);
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width = Math.round((width * maxSize) / height);
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert to base64 with reduced quality
        const base64 = canvas.toDataURL('image/jpeg', 0.7);
        resolve(base64);
      };
      img.onerror = () => reject(new Error('Erro ao processar imagem'));
    };
    reader.onerror = () => reject(new Error('Erro ao ler arquivo'));
  });
};