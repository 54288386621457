import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface TermsStepProps {
  onSubmit: (terms: {
    general: boolean;
    marketplace: boolean;
    social: boolean;
    privacy: boolean;
  }) => void;
  initialTerms: {
    general: boolean;
    marketplace: boolean;
    social: boolean;
    privacy: boolean;
  };
}

export default function TermsStep({ onSubmit, initialTerms }: TermsStepProps) {
  const [terms, setTerms] = useState(initialTerms);
  const [expandedTerm, setExpandedTerm] = useState<string | null>(null);

  const termsContent = {
    general: `Termos e Condições Gerais

1. Aceitação dos Termos
Ao utilizar esta plataforma, você concorda com estes termos e condições na sua totalidade.

2. Uso da Plataforma
A plataforma deve ser utilizada apenas para fins comerciais legítimos.

3. Responsabilidades
Os usuários são responsáveis por manter a confidencialidade de suas credenciais.

4. Modificações
Reservamo-nos o direito de modificar estes termos a qualquer momento.`,

    marketplace: `Termos e Condições dos Marketplaces

1. Vendas e Listagens
Todas as listagens devem ser precisas e completas.

2. Preços e Pagamentos
Os preços devem incluir todos os custos aplicáveis.

3. Envio e Entrega
Os vendedores são responsáveis pelo envio dentro dos prazos estabelecidos.

4. Políticas de Devolução
Devem seguir as políticas específicas de cada marketplace.`,

    social: `Termos e Condições da Rede Social

1. Conteúdo
O conteúdo publicado deve ser apropriado e respeitar direitos autorais.

2. Interações
As interações devem ser respeitosas e profissionais.

3. Privacidade
Respeite a privacidade de outros usuários.

4. Moderação
Reservamo-nos o direito de moderar conteúdo inadequado.`,

    privacy: `Política de Privacidade e RGPD

1. Coleta de Dados
Coletamos apenas dados necessários para o funcionamento da plataforma.

2. Uso de Dados
Os dados são utilizados apenas para os fins especificados.

3. Direitos do Usuário
Você tem direito de acessar, corrigir e excluir seus dados.

4. Segurança
Implementamos medidas de segurança para proteger seus dados.`
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (Object.values(terms).every(term => term)) {
      onSubmit(terms);
    }
  };

  const toggleTerm = (term: string) => {
    setExpandedTerm(expandedTerm === term ? null : term);
  };

  const allTermsAccepted = Object.values(terms).every(term => term);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-secondary mb-6">
        Termos e Condições
      </h2>

      <div className="space-y-4">
        {Object.entries(termsContent).map(([key, content]) => (
          <div key={key} className="border rounded-lg">
            <button
              onClick={() => toggleTerm(key)}
              className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={terms[key as keyof typeof terms]}
                  onChange={(e) => setTerms({ ...terms, [key]: e.target.checked })}
                  onClick={(e) => e.stopPropagation()}
                  className="rounded border-gray-300 text-primary focus:ring-primary"
                />
                <span className="font-medium">
                  {key === 'general' && 'Termos e Condições Gerais'}
                  {key === 'marketplace' && 'Termos e Condições dos Marketplaces'}
                  {key === 'social' && 'Termos e Condições da Rede Social'}
                  {key === 'privacy' && 'Política de Privacidade e RGPD'}
                </span>
              </div>
              {expandedTerm === key ? (
                <ChevronUp className="text-gray-400" />
              ) : (
                <ChevronDown className="text-gray-400" />
              )}
            </button>
            {expandedTerm === key && (
              <div className="p-4 border-t bg-gray-50 whitespace-pre-wrap">
                {content}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-end pt-6">
        <button
          onClick={handleSubmit}
          disabled={!allTermsAccepted}
          className="px-6 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
        >
          Continuar
        </button>
      </div>
    </div>
  );
}