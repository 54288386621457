import { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Image, Package, Smile, Send, X } from 'lucide-react';
import { validateImage, resizeImage } from '../../utils/imageUpload';
import { moderateContent } from '../../utils/moderationUtils';
import EmojiPicker from './EmojiPicker';
import ProductSelector from './ProductSelector';

interface CreatePostProps {
  onPostCreated: () => void;
}

export default function CreatePost({ onPostCreated }: CreatePostProps) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  const handleImageUpload = async (file: File) => {
    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Image = await resizeImage(file);
      setImages(prev => [...prev, base64Image]);
      setError('');
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Erro ao processar imagem');
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleEmojiSelect = (emoji: string) => {
    setContent(prev => prev + emoji);
    setShowEmojiPicker(false);
  };

  const handleProductSelect = (product: any) => {
    setSelectedProducts(prev => [...prev, product]);
    setShowProductSelector(false);
  };

  const handleRemoveProduct = (productId: string) => {
    setSelectedProducts(prev => prev.filter(p => p.id !== productId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    if (!content.trim() && images.length === 0 && selectedProducts.length === 0) {
      setError('Adicione algum conteúdo ao seu post');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const moderatedContent = await moderateContent(content.trim());
      
      const postData = {
        userId: currentUser.uid,
        content: moderatedContent,
        images,
        products: selectedProducts.map(p => ({
          id: p.id,
          name: p.name,
          price: p.regularPrice,
          mainImage: p.mainImage
        })),
        createdAt: Timestamp.now(),
        likes: [],
        comments: []
      };

      await addDoc(collection(db, 'social_posts'), postData);

      setContent('');
      setImages([]);
      setSelectedProducts([]);
      onPostCreated();
    } catch (err) {
      console.error('Error creating post:', err);
      setError('Erro ao criar post');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="O que está acontecendo?"
            rows={3}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary resize-none"
          />
          <div className="absolute bottom-2 right-2">
            <button
              type="button"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="p-2 text-gray-500 hover:text-primary rounded-full hover:bg-gray-100"
            >
              <Smile size={20} />
            </button>
          </div>
          {showEmojiPicker && (
            <div className="absolute bottom-12 right-0">
              <EmojiPicker onSelect={handleEmojiSelect} />
            </div>
          )}
        </div>

        {error && (
          <div className="text-red-600 text-sm">
            {error}
          </div>
        )}

        {/* Image Previews */}
        {images.length > 0 && (
          <div className="grid grid-cols-2 gap-2">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-32 object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Product Previews */}
        {selectedProducts.length > 0 && (
          <div className="grid grid-cols-2 gap-2">
            {selectedProducts.map((product) => (
              <div key={product.id} className="flex items-center gap-2 p-2 border rounded-lg">
                {product.mainImage ? (
                  <img
                    src={product.mainImage}
                    alt={product.name}
                    className="w-12 h-12 object-cover rounded"
                  />
                ) : (
                  <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
                    <Package className="w-6 h-6 text-gray-400" />
                  </div>
                )}
                <div className="flex-1">
                  <p className="font-medium truncate">{product.name}</p>
                  <p className="text-primary">€{product.regularPrice.toFixed(2)}</p>
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveProduct(product.id)}
                  className="p-1 text-red-600 hover:text-red-700"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center justify-between pt-2 border-t">
          <div className="flex gap-2">
            <label className="p-2 text-gray-500 hover:text-primary rounded-full hover:bg-gray-100 cursor-pointer">
              <Image size={20} />
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => e.target.files?.[0] && handleImageUpload(e.target.files[0])}
              />
            </label>
            <button
              type="button"
              onClick={() => setShowProductSelector(true)}
              className="p-2 text-gray-500 hover:text-primary rounded-full hover:bg-gray-100"
            >
              <Package size={20} />
            </button>
          </div>

          <button
            type="submit"
            disabled={loading || (!content.trim() && images.length === 0 && selectedProducts.length === 0)}
            className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
          >
            <Send size={20} />
            {loading ? 'Publicando...' : 'Publicar'}
          </button>
        </div>
      </form>

      {showProductSelector && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Selecionar Produto</h3>
              <button
                onClick={() => setShowProductSelector(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>
            <ProductSelector
              companyId={currentUser?.companyId || ''}
              onProductSelect={handleProductSelect}
            />
          </div>
        </div>
      )}
    </div>
  );
}