import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, runTransaction } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { ShoppingCart } from 'lucide-react';
import ProductSelector from '../components/orders/ProductSelector';
import ProductList from '../components/orders/ProductList';
import PaymentMethodSelector from '../components/orders/PaymentMethodSelector';
import { calculateCommission } from '../utils/commissionUtils';

interface OrderItem {
  productId: string;
  name: string;
  sku: string;
  quantity: number;
  price: number;
  originalPrice: number;
  total: number;
  mainImage?: string;
}

interface CustomerInfo {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

export default function OrderCreate() {
  const [selectedProducts, setSelectedProducts] = useState<OrderItem[]>([]);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postalCode: '',
    country: 'Portugal'
  });
  const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
  const [shippingCost, setShippingCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleProductSelect = (product: any) => {
    const existingItem = selectedProducts.find(item => item.productId === product.id);
    
    if (existingItem) {
      setSelectedProducts(selectedProducts.map(item =>
        item.productId === product.id
          ? { 
              ...item, 
              quantity: item.quantity + 1,
              total: (item.quantity + 1) * item.price 
            }
          : item
      ));
    } else {
      setSelectedProducts([...selectedProducts, {
        productId: product.id,
        name: product.name,
        sku: product.sku,
        quantity: 1,
        price: product.regularPrice,
        originalPrice: product.regularPrice,
        total: product.regularPrice,
        mainImage: product.mainImage
      }]);
    }
  };

  const handleUpdateQuantity = (productId: string, newQuantity: number) => {
    if (newQuantity < 1) {
      setSelectedProducts(selectedProducts.filter(item => item.productId !== productId));
    } else {
      setSelectedProducts(selectedProducts.map(item =>
        item.productId === productId
          ? { ...item, quantity: newQuantity, total: newQuantity * item.price }
          : item
      ));
    }
  };

  const handleUpdatePrice = (productId: string, newPrice: number) => {
    setSelectedProducts(selectedProducts.map(item =>
      item.productId === productId
        ? { ...item, price: newPrice, total: item.quantity * newPrice }
        : item
    ));
  };

  const handleRemoveItem = (productId: string) => {
    setSelectedProducts(selectedProducts.filter(item => item.productId !== productId));
  };

  const calculateTotal = () => {
    const subtotal = selectedProducts.reduce((sum, item) => sum + item.total, 0);
    return subtotal + shippingCost;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.companyId || selectedProducts.length === 0) return;

    const orderTotal = calculateTotal();
    const commission = calculateCommission(orderTotal, true); // true for manual order

    setLoading(true);
    setError('');

    try {
      await runTransaction(db, async (transaction) => {
        // Check wallet balance
        const walletRef = doc(db, 'wallets', currentUser.companyId);
        const walletDoc = await transaction.get(walletRef);
        
        if (!walletDoc.exists()) {
          throw new Error('Carteira não encontrada');
        }

        const currentBalance = walletDoc.data().balance;
        if (currentBalance < commission.totalCommission) {
          throw new Error(`Saldo insuficiente. Por favor, carregue sua carteira com pelo menos €${commission.totalCommission.toFixed(2)}`);
        }

        // Deduct commission from wallet
        transaction.update(walletRef, {
          balance: currentBalance - commission.totalCommission,
          updatedAt: new Date().toISOString()
        });

        // Create commission transaction record
        const commissionTransactionRef = doc(collection(db, 'walletTransactions'));
        const orderId = `ORD${Date.now()}${Math.floor(Math.random() * 1000)}`;

        transaction.set(commissionTransactionRef, {
          type: 'commission',
          amount: commission.totalCommission,
          baseAmount: commission.baseCommission,
          vatAmount: commission.vatAmount,
          status: 'completed',
          description: 'Comissão LOGNOW - Encomenda Manual',
          companyId: currentUser.companyId,
          orderId,
          createdAt: new Date()
        });

        // Create order
        const orderRef = doc(collection(db, 'orders'));
        transaction.set(orderRef, {
          orderNumber: orderId,
          companyId: currentUser.companyId,
          status: paymentMethod === 'cash_on_delivery' ? 'processing' : 'pending',
          customer: customerInfo,
          items: selectedProducts,
          payment: {
            method: paymentMethod,
            status: paymentMethod === 'cash_on_delivery' ? 'pending' : 'pending'
          },
          subtotal: selectedProducts.reduce((sum, item) => sum + item.total, 0),
          shippingCost,
          total: orderTotal,
          commission: commission.totalCommission,
          shippingMethod: 'direct',
          createdAt: new Date().toISOString(),
          createdBy: currentUser.uid
        });
      });

      navigate('/orders');
    } catch (err) {
      console.error('Error creating order:', err);
      setError(err instanceof Error ? err.message : 'Erro ao criar encomenda');
      setLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center gap-3 mb-6">
          <ShoppingCart className="text-primary" size={24} />
          <h2 className="text-2xl font-bold text-secondary">Nova Encomenda</h2>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Product Selection */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Produtos</h3>
            <ProductSelector
              companyId={currentUser?.companyId || ''}
              onProductSelect={handleProductSelect}
            />
            <div className="mt-4">
              <ProductList
                items={selectedProducts}
                onUpdateQuantity={handleUpdateQuantity}
                onRemoveItem={handleRemoveItem}
                onUpdatePrice={handleUpdatePrice}
              />
            </div>
          </div>

          {/* Customer Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Informação do Cliente</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Nome</label>
                <input
                  type="text"
                  required
                  value={customerInfo.name}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  required
                  value={customerInfo.email}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, email: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Telefone</label>
                <input
                  type="tel"
                  required
                  value={customerInfo.phone}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, phone: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Morada</label>
                <input
                  type="text"
                  required
                  value={customerInfo.address}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, address: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Cidade</label>
                <input
                  type="text"
                  required
                  value={customerInfo.city}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, city: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Código Postal</label>
                <input
                  type="text"
                  required
                  value={customerInfo.postalCode}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, postalCode: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
            </div>
          </div>

          {/* Shipping Cost */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Custos de Envio</h3>
            <div className="flex items-center gap-4">
              <div className="w-64">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Valor do Envio (€)
                </label>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={shippingCost}
                  onChange={(e) => setShippingCost(Number(e.target.value))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                />
              </div>
              <div className="flex-1">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Subtotal:</span>
                    <span>€{selectedProducts.reduce((sum, item) => sum + item.total, 0).toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <span className="text-gray-600">Envio:</span>
                    <span>€{shippingCost.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200 font-bold">
                    <span>Total:</span>
                    <span>€{calculateTotal().toFixed(2)}</span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    Comissão LOGNOW (7.5% + IVA): €{calculateCommission(calculateTotal(), true).totalCommission.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Payment Method */}
          <PaymentMethodSelector
            companyId={currentUser?.companyId || ''}
            onSelect={setPaymentMethod}
            total={calculateTotal()}
          />

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/orders')}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading || selectedProducts.length === 0}
              className="flex items-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
            >
              {loading ? 'A criar...' : 'Criar Encomenda'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}