import {
  LayoutDashboard,
  Settings,
  UserCog,
  UserPlus,
  Key,
  Package,
  ShoppingCart,
  Users,
  Shield,
  Tag,
  Truck,
  HeadphonesIcon
} from 'lucide-react';
import { MenuItem } from '../../types';

export const menuItems: MenuItem[] = [
  { 
    icon: LayoutDashboard, 
    label: 'Dashboard', 
    path: '/' 
  },
  { 
    icon: Package, 
    label: 'Produtos', 
    path: '/products' 
  },
  { 
    icon: Tag, 
    label: 'Vendas Privadas', 
    path: '/private-sales' 
  },
  { 
    icon: ShoppingCart, 
    label: 'Encomendas', 
    path: '/orders' 
  },
  {
    icon: Users,
    label: 'Social',
    path: '/social'
  },
  {
    icon: Settings,
    label: 'Configurações',
    path: '#',
    subItems: [
      { icon: UserCog, label: 'Gerir Utilizadores', path: '/users' },
      { icon: UserPlus, label: 'Criar Utilizador', path: '/users/create' },
      { icon: Key, label: 'Gerir APIs', path: '/settings/apis' },
      { 
        icon: Shield, 
        label: 'Moderação', 
        path: '/settings/moderation',
        adminOnly: true 
      },
      {
        icon: Truck,
        label: 'Portes de Envio',
        path: '/settings/shipping',
        adminOnly: true
      },
      {
        icon: HeadphonesIcon,
        label: 'Suporte',
        path: '/settings/support',
        adminOnly: true
      }
    ]
  }
];