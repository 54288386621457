import { Clock } from 'lucide-react';

interface OrderSummaryProps {
  subtotal?: number;
  shippingCost?: number;
  total?: number;
  createdAt: string;
}

export default function OrderSummary({ subtotal = 0, shippingCost = 0, total = 0, createdAt }: OrderSummaryProps) {
  const formatDate = (dateString: string): string => {
    try {
      const date = new Date(dateString);
      return date.toLocaleString('pt-PT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Data não disponível';
    }
  };

  return (
    <div className="space-y-8">
      {/* Order Summary */}
      <div>
        <h3 className="text-lg font-semibold mb-4">Resumo</h3>
        <div className="bg-gray-50 p-6 rounded-lg space-y-4">
          <div className="flex justify-between">
            <span className="text-gray-600">Subtotal</span>
            <span>€{subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Envio</span>
            <span>€{shippingCost.toFixed(2)}</span>
          </div>
          <div className="flex justify-between pt-4 border-t border-gray-200">
            <span className="font-medium">Total</span>
            <span className="font-medium">€{total.toFixed(2)}</span>
          </div>
        </div>
      </div>

      {/* Order Timeline */}
      <div>
        <h3 className="text-lg font-semibold mb-4">Data</h3>
        <div className="bg-gray-50 p-6 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="text-gray-400" size={20} />
            <div>
              <p className="font-medium">Criada em</p>
              <p className="text-gray-600">{formatDate(createdAt)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}