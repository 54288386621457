import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthContext';

interface WalletContextType {
  balance: number;
  loading: boolean;
  error: string | null;
}

const WalletContext = createContext<WalletContextType | null>(null);

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export function WalletProvider({ children }: { children: React.ReactNode }) {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const initializeWallet = async () => {
      if (!currentUser?.companyId) {
        setLoading(false);
        return;
      }

      try {
        // Check if wallet exists
        const walletRef = doc(db, 'wallets', currentUser.companyId);
        const walletDoc = await getDoc(walletRef);

        // Create wallet if it doesn't exist
        if (!walletDoc.exists()) {
          await setDoc(walletRef, {
            balance: 0,
            companyId: currentUser.companyId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          });
        }

        // Listen to wallet changes
        unsubscribe = onSnapshot(walletRef, 
          (doc) => {
            if (doc.exists()) {
              setBalance(doc.data().balance || 0);
              setError(null);
            }
          },
          (err) => {
            console.error('Error listening to wallet:', err);
            setError('Erro ao carregar dados da carteira');
          }
        );

      } catch (err) {
        console.error('Error initializing wallet:', err);
        setError('Erro ao inicializar carteira');
      } finally {
        setLoading(false);
      }
    };

    if (currentUser?.companyId) {
      initializeWallet();
    } else {
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  const value = {
    balance,
    loading,
    error
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
}