import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  Package, 
  PenSquare, 
  Calendar, 
  Tag, 
  Box, 
  Scale, 
  DollarSign, 
  Clock
} from 'lucide-react';
import MarketplacePrices from '../components/MarketplacePrices';
import { useAuth } from '../contexts/AuthContext';

interface Product {
  id: string;
  name: string;
  description?: string;
  sku: string;
  ean?: string;
  baselinker_id?: string;
  regularPrice: number;
  salePrice: number;
  cost: number;
  preparationCost: number;
  stockQuantity: number;
  stockStatus: 'instock' | 'outofstock' | 'onbackorder';
  mainImage?: string;
  galleryImages?: string[];
  companyId: string;
  createdAt: string;
  updatedAt?: string;
  lastSyncedAt?: string;
  marketplacePrices?: {
    emtuacasa: number;
    worten: number;
    fnac: number;
    amazon: number;
  };
  promotionalPeriod?: {
    startDate: string;
    endDate: string;
  };
  weight: number;
  length: number;
  width: number;
  height: number;
}

interface Company {
  id: string;
  commercialName: string;
}

const ProductDetails = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    loadData();
  }, [id, currentUser]);

  const loadData = async () => {
    if (!id || !currentUser?.companyId) return;

    try {
      // Load product data
      const productDoc = await getDoc(doc(db, 'products', id));
      if (!productDoc.exists()) {
        setError('Produto não encontrado');
        return;
      }

      const productData = productDoc.data() as Product;
      setProduct({
        id: productDoc.id,
        ...productData
      });

      if (productData.mainImage) {
        setSelectedImage(productData.mainImage);
      }

      // Load company data
      const companyDoc = await getDoc(doc(db, 'companies', currentUser.companyId));
      if (companyDoc.exists()) {
        setCompany({
          id: companyDoc.id,
          ...companyDoc.data()
        } as Company);
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Erro ao carregar dados');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'N/A';
    
    try {
      // If it's a Firebase Timestamp object
      if (typeof dateString === 'object' && 'seconds' in dateString) {
        const date = new Date(dateString.seconds * 1000);
        return date.toLocaleString('pt-PT', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Europe/Lisbon'
        });
      }

      // If it's a regular date string
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Data inválida';
      }

      return date.toLocaleString('pt-PT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Lisbon'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Data inválida';
    }
  };

  const isPromotionalPriceActive = (product: Product): boolean => {
    if (!product.promotionalPeriod?.startDate || !product.promotionalPeriod?.endDate) {
      return false;
    }

    const now = new Date().getTime();
    const startDate = new Date(product.promotionalPeriod.startDate).getTime();
    const endDate = new Date(product.promotionalPeriod.endDate).getTime();

    return now >= startDate && now <= endDate && product.salePrice > 0;
  };

  const getCurrentPrice = (product: Product): number => {
    if (isPromotionalPriceActive(product)) {
      return product.salePrice;
    }
    return product.regularPrice;
  };

  const calculateProfit = (product: Product): number => {
    const currentPrice = getCurrentPrice(product);
    return currentPrice - (product.cost || 0) - (product.preparationCost || 0);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="text-center text-red-600">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      <div className="bg-white rounded-lg shadow-md p-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Package className="text-primary" size={24} />
            <h2 className="text-2xl font-bold text-secondary">{product.name}</h2>
          </div>
          <button
            onClick={() => navigate(`/products/${id}/edit`)}
            className="flex items-center gap-2 text-primary hover:text-primary-dark"
          >
            <PenSquare size={20} />
            Editar Produto
          </button>
        </div>

        {/* Product Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Images */}
          <div className="space-y-4">
            <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt={product.name}
                  className="w-full h-full object-contain"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <Package className="h-24 w-24 text-gray-400" />
                </div>
              )}
            </div>

            {(product.galleryImages?.length > 0 || product.mainImage) && (
              <div className="grid grid-cols-5 gap-2">
                {product.mainImage && (
                  <button
                    onClick={() => setSelectedImage(product.mainImage)}
                    className={`aspect-square rounded-lg overflow-hidden border-2 ${
                      selectedImage === product.mainImage ? 'border-primary' : 'border-transparent'
                    }`}
                  >
                    <img
                      src={product.mainImage}
                      alt="Principal"
                      className="w-full h-full object-cover"
                    />
                  </button>
                )}
                {product.galleryImages?.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedImage(image)}
                    className={`aspect-square rounded-lg overflow-hidden border-2 ${
                      selectedImage === image ? 'border-primary' : 'border-transparent'
                    }`}
                  >
                    <img
                      src={image}
                      alt={`Galeria ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Right Column - Product Info */}
          <div className="space-y-8">
            {/* Pricing Section */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="space-y-4">
                <div className="flex items-baseline gap-4">
                  {isPromotionalPriceActive(product) ? (
                    <>
                      <span className="text-3xl font-bold text-green-600">
                        €{product.salePrice.toFixed(2)}
                      </span>
                      <span className="text-xl text-gray-500 line-through">
                        €{product.regularPrice.toFixed(2)}
                      </span>
                      <div className="text-sm text-gray-600">
                        <div>Promoção válida de:</div>
                        <div>{formatDate(product.promotionalPeriod?.startDate)}</div>
                        <div>até</div>
                        <div>{formatDate(product.promotionalPeriod?.endDate)}</div>
                      </div>
                    </>
                  ) : (
                    <span className="text-3xl font-bold text-gray-900">
                      €{product.regularPrice.toFixed(2)}
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-3 gap-4 pt-4 border-t border-gray-200">
                  <div>
                    <p className="text-sm text-gray-500">Custo do Produto</p>
                    <p className="font-medium">€{(product.cost || 0).toFixed(2)}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Custo de Preparação</p>
                    <p className="font-medium">€{(product.preparationCost || 0).toFixed(2)}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Lucro</p>
                    <p className={`font-medium ${calculateProfit(product) > 0 ? 'text-green-600' : 'text-red-600'}`}>
                      €{calculateProfit(product).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Marketplace Prices */}
            {product.marketplacePrices && company && (
              <MarketplacePrices 
                prices={product.marketplacePrices}
                productEan={product.ean || ''}
                productName={product.name}
                companyName={company.commercialName}
              />
            )}

            {/* Stock Information */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="flex items-center gap-2 text-lg font-semibold mb-4">
                <Box className="text-gray-400" size={20} />
                Stock
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Quantidade</p>
                  <p className="text-xl font-semibold">{product.stockQuantity}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Status</p>
                  <p className="text-xl font-semibold">
                    {product.stockStatus === 'instock' ? 'Em Stock' :
                     product.stockStatus === 'outofstock' ? 'Fora de Stock' :
                     'Sob Encomenda'}
                  </p>
                </div>
              </div>
            </div>

            {/* Product Details */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="flex items-center gap-2 text-lg font-semibold mb-4">
                <Tag className="text-gray-400" size={20} />
                Detalhes do Produto
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">SKU</p>
                  <p className="font-medium">{product.sku}</p>
                </div>
                {product.ean && (
                  <div>
                    <p className="text-sm text-gray-500">EAN</p>
                    <p className="font-medium">{product.ean}</p>
                  </div>
                )}
                {product.baselinker_id && (
                  <div>
                    <p className="text-sm text-gray-500">ID BaseLinker</p>
                    <p className="font-medium">{product.baselinker_id}</p>
                  </div>
                )}
              </div>
            </div>

            {/* Dimensions */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="flex items-center gap-2 text-lg font-semibold mb-4">
                <Scale className="text-gray-400" size={20} />
                Dimensões e Peso
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Peso</p>
                  <p className="font-medium">{product.weight} kg</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Dimensões (C x L x A)</p>
                  <p className="font-medium">
                    {product.length} × {product.width} × {product.height} cm
                  </p>
                </div>
              </div>
            </div>

            {/* Last Update Info */}
            {product.updatedAt && (
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="flex items-center gap-2 text-lg font-semibold mb-4">
                  <Clock className="text-gray-400" size={20} />
                  Última Atualização
                </h3>
                <p className="text-gray-600">
                  {formatDate(product.updatedAt)}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Description */}
        {product.description && (
          <div className="mt-8">
            <h3 className="text-lg font-semibold mb-4">Descrição</h3>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-600 whitespace-pre-wrap">{product.description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;