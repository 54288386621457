import { useState } from 'react';
import { Building2, Mail, Phone, Clock, Upload, X } from 'lucide-react';
import { validateImage, resizeImage } from '../../../utils/imageUpload';

interface CompanyStepProps {
  onSubmit: (data: {
    legalName: string;
    commercialName: string;
    address: string;
    workingHours: string;
    commercialEmail: string;
    commercialPhone: string;
    logo: string | null;
  }) => void;
  initialData: {
    legalName: string;
    commercialName: string;
    address: string;
    workingHours: string;
    commercialEmail: string;
    commercialPhone: string;
    logo: string | null;
  };
}

export default function CompanyStep({ onSubmit, initialData }: CompanyStepProps) {
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState('');

  const handleLogoUpload = async (file: File) => {
    try {
      const validation = await validateImage(file);
      if (!validation.isValid) {
        setError(validation.error || 'Erro ao validar imagem');
        return;
      }

      const base64Logo = await resizeImage(file);
      setFormData({ ...formData, logo: base64Logo });
      setError('');
    } catch (err) {
      console.error('Error uploading logo:', err);
      setError('Erro ao processar imagem');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.legalName || !formData.commercialName || !formData.address) {
      setError('Por favor, preencha todos os campos obrigatórios');
      return;
    }
    onSubmit(formData);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-secondary mb-6">
        Dados da Empresa
      </h2>

      {error && (
        <div className="mb-4 text-red-600 text-sm">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Logo Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Logo da Empresa
          </label>
          <div className="flex items-center gap-4">
            {formData.logo ? (
              <div className="relative">
                <img
                  src={formData.logo}
                  alt="Logo"
                  className="w-32 h-32 object-contain rounded-lg border"
                />
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, logo: null })}
                  className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <label className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-primary">
                <Upload className="text-gray-400 mb-2" size={24} />
                <span className="text-sm text-gray-500">Upload</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => e.target.files?.[0] && handleLogoUpload(e.target.files[0])}
                />
              </label>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nome Legal *
            </label>
            <div className="mt-1 relative">
              <Building2 className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                required
                value={formData.legalName}
                onChange={(e) => setFormData({ ...formData, legalName: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nome Comercial *
            </label>
            <div className="mt-1 relative">
              <Building2 className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                required
                value={formData.commercialName}
                onChange={(e) => setFormData({ ...formData, commercialName: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Morada *
            </label>
            <textarea
              required
              rows={3}
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email Comercial
            </label>
            <div className="mt-1 relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="email"
                value={formData.commercialEmail}
                onChange={(e) => setFormData({ ...formData, commercialEmail: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Telefone Comercial
            </label>
            <div className="mt-1 relative">
              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="tel"
                value={formData.commercialPhone}
                onChange={(e) => setFormData({ ...formData, commercialPhone: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Horário de Funcionamento
            </label>
            <div className="mt-1 relative">
              <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Ex: 9h-18h"
                value={formData.workingHours}
                onChange={(e) => setFormData({ ...formData, workingHours: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end pt-6">
          <button
            type="submit"
            className="px-6 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark"
          >
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}