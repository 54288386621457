import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface MBWayPaymentRequest {
  amount: number;
  phone: string;
  orderId: string;
  description: string;
  email: string;
}

interface MBWayPaymentResponse {
  Amount?: string;
  Message?: string;
  OrderId?: string;
  RequestId?: string;
  Status: string;
}

export const PAYMENT_STATUS = {
  SUCCESS: '000',
  REJECTED: '020',
  EXPIRED: '101',
  DECLINED: '122'
} as const;

const MBWAY_KEY = 'CKY-865055';

export class IfthenpayService {
  static async createMBWayPayment(data: MBWayPaymentRequest): Promise<MBWayPaymentResponse> {
    try {
      const requestBody = {
        mbWayKey: MBWAY_KEY,
        orderId: data.orderId,
        amount: data.amount.toFixed(2),
        mobileNumber: data.phone.replace(/[\s-]/g, ''),
        email: "1",
        description: data.description
      };

      console.log('Sending request to IFThenpay:', requestBody);

      const response = await fetch('https://api.ifthenpay.com/spg/payment/mbway', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('IFThenpay response:', result);

      // Validate response
      if (!result.Status) {
        throw new Error('Invalid response from IFThenpay');
      }

      return result;
    } catch (error) {
      console.error('IFTHENPAY Request Error:', error);
      throw {
        Status: 'error',
        Message: error instanceof Error ? error.message : 'Failed to process payment'
      };
    }
  }

  static async checkPaymentStatus(requestId: string): Promise<MBWayPaymentResponse> {
    try {
      const url = new URL('https://api.ifthenpay.com/spg/payment/mbway/status');
      url.searchParams.append('mbWayKey', MBWAY_KEY);
      url.searchParams.append('requestId', requestId);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('IFThenpay status response:', result);

      return result;
    } catch (error) {
      console.error('IFTHENPAY Status Check Error:', error);
      throw {
        Status: 'error',
        Message: error instanceof Error ? error.message : 'Failed to check payment status'
      };
    }
  }
}