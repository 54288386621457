import { useState } from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../hooks/useUsers';
import { useAuth } from '../contexts/AuthContext';
import UserList from '../components/users/UserList';
import WalletManagementModal from '../components/admin/WalletManagementModal';
import DeleteUserModal from '../components/admin/DeleteUserModal';
import type { User } from '../types';

export default function UserManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<{
    userId: string;
    companyId: string;
    balance: number;
    name: string;
  } | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  
  const { users, loading, error, refreshUsers } = useUsers();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  if (!currentUser?.role || (currentUser.role !== 'admin' && currentUser.role !== 'gerente')) {
    navigate('/');
    return null;
  }

  const handleEditUser = (userId: string) => {
    navigate(`/users/${userId}/edit`);
  };

  const handleWalletClick = (user: User) => {
    if (!user.companyId) return;

    setSelectedUser({
      userId: user.id,
      companyId: user.companyId,
      balance: user.walletBalance || 0,
      name: user.company?.name || user.displayName || user.email
    });
  };

  const handleWalletModalClose = () => {
    setSelectedUser(null);
    refreshUsers();
  };

  const canDeleteUser = (userToDelete: User) => {
    if (!currentUser) return false;

    if (currentUser.role === 'admin') return true;

    if (currentUser.role === 'gerente' && currentUser.companyId === userToDelete.companyId) {
      return userToDelete.role === 'funcionario';
    }

    return false;
  };

  const handleDeleteClick = (user: User) => {
    if (canDeleteUser(user)) {
      setUserToDelete(user);
    }
  };

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.company?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-secondary mb-6">Gestão de Utilizadores</h2>
          
          <div className="mb-6 relative">
            <input
              type="text"
              placeholder="Pesquisar por nome, email ou empresa..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>

          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {error}
            </div>
          )}

          <div className="overflow-x-auto">
            <UserList
              users={filteredUsers}
              onEditUser={handleEditUser}
              onWalletClick={handleWalletClick}
              onDeleteClick={handleDeleteClick}
              canDeleteUser={canDeleteUser}
            />
          </div>
        </div>
      </div>

      {selectedUser && (
        <WalletManagementModal
          isOpen={true}
          onClose={handleWalletModalClose}
          userId={selectedUser.userId}
          companyId={selectedUser.companyId}
          currentBalance={selectedUser.balance}
          userName={selectedUser.name}
        />
      )}

      {userToDelete && (
        <DeleteUserModal
          isOpen={true}
          onClose={() => setUserToDelete(null)}
          user={userToDelete}
          onDeleted={() => {
            setUserToDelete(null);
            refreshUsers();
          }}
        />
      )}
    </div>
  );
}