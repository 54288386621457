import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Package, ShoppingCart, Plus, Minus } from 'lucide-react';
import { useCart } from '../contexts/CartContext';

interface Product {
  id: string;
  name: string;
  description?: string;
  mainImage?: string;
  regularPrice: number;
  salePrice: number;
  stockQuantity: number;
  stockStatus: 'instock' | 'outofstock' | 'onbackorder';
  companyId: string;
  promotionalPeriod?: {
    startDate: string;
    endDate: string;
  };
}

interface Company {
  id: string;
  commercialName: string;
}

export default function PrivateSaleDetails() {
  const [product, setProduct] = useState<Product | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [addedToCart, setAddedToCart] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { addItem } = useCart();

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    if (!id) return;

    try {
      const productDoc = await getDoc(doc(db, 'products', id));
      if (!productDoc.exists()) {
        setError('Produto não encontrado');
        return;
      }

      const productData = productDoc.data() as Product;
      setProduct({
        id: productDoc.id,
        ...productData
      });

      if (productData.companyId) {
        const companyDoc = await getDoc(doc(db, 'companies', productData.companyId));
        if (companyDoc.exists()) {
          setCompany({
            id: companyDoc.id,
            ...companyDoc.data()
          } as Company);
        }
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Erro ao carregar dados');
    } finally {
      setLoading(false);
    }
  };

  const isPromotionalPriceActive = (product: Product): boolean => {
    if (!product.promotionalPeriod?.startDate || !product.promotionalPeriod?.endDate) {
      return false;
    }

    const now = new Date().getTime();
    const startDate = new Date(product.promotionalPeriod.startDate).getTime();
    const endDate = new Date(product.promotionalPeriod.endDate).getTime();

    return now >= startDate && now <= endDate && product.salePrice > 0;
  };

  const getCurrentPrice = (product: Product): number => {
    return isPromotionalPriceActive(product) ? product.salePrice : product.regularPrice;
  };

  const handleAddToCart = () => {
    if (!product || !company) return;

    addItem({
      productId: product.id,
      name: product.name,
      price: getCurrentPrice(product),
      quantity,
      mainImage: product.mainImage,
      companyId: product.companyId,
      companyName: company.commercialName
    });

    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), 2000);
  };

  const handleBuyNow = () => {
    if (!product || !company) return;

    addItem({
      productId: product.id,
      name: product.name,
      price: getCurrentPrice(product),
      quantity,
      mainImage: product.mainImage,
      companyId: product.companyId,
      companyName: company.commercialName
    });

    navigate('/private-sales/checkout');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!product || error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="text-center text-red-600">{error || 'Produto não encontrado'}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Product Image */}
          <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
            {product.mainImage ? (
              <img
                src={product.mainImage}
                alt={product.name}
                className="w-full h-full object-contain"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <Package className="h-24 w-24 text-gray-400" />
              </div>
            )}
          </div>

          {/* Product Info */}
          <div className="space-y-6">
            <h1 className="text-2xl font-bold text-gray-900">{product.name}</h1>

            {company && (
              <p className="text-gray-600">Vendido por: {company.commercialName}</p>
            )}

            <div className="flex items-baseline gap-2">
              <span className="text-3xl font-bold text-gray-900">
                €{getCurrentPrice(product).toFixed(2)}
              </span>
              {isPromotionalPriceActive(product) && (
                <span className="text-xl text-gray-500 line-through">
                  €{product.regularPrice.toFixed(2)}
                </span>
              )}
            </div>

            {product.description && (
              <div className="prose max-w-none">
                <p className="text-gray-600">{product.description}</p>
              </div>
            )}

            <div className="flex items-center gap-4">
              <label className="block text-sm font-medium text-gray-700">
                Quantidade
              </label>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setQuantity(prev => Math.max(1, prev - 1))}
                  className="p-1 rounded-full hover:bg-gray-100"
                >
                  <Minus size={20} />
                </button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                  className="w-16 px-3 py-2 text-center border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                />
                <button
                  onClick={() => setQuantity(prev => prev + 1)}
                  className="p-1 rounded-full hover:bg-gray-100"
                >
                  <Plus size={20} />
                </button>
              </div>
            </div>

            <div className="flex gap-4">
              <button
                onClick={handleAddToCart}
                className={`flex-1 py-3 ${
                  addedToCart 
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                } rounded-lg flex items-center justify-center gap-2`}
              >
                <ShoppingCart size={20} />
                {addedToCart ? 'Adicionado!' : 'Adicionar ao Carrinho'}
              </button>

              <button
                onClick={handleBuyNow}
                className="flex-1 py-3 bg-primary text-secondary rounded-lg hover:bg-primary-dark flex items-center justify-center gap-2"
              >
                <ShoppingCart size={20} />
                Comprar Agora
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}