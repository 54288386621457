import { useState } from 'react';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Send } from 'lucide-react';
import { moderateContent } from '../../utils/moderationUtils';

interface CommentSectionProps {
  postId: string;
  comments: any[];
  onCommentAdded: () => void;
}

const CommentSection = ({ postId, comments, onCommentAdded }: CommentSectionProps) => {
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser || !newComment.trim()) return;

    setLoading(true);
    try {
      const moderatedContent = await moderateContent(newComment.trim());
      const comment = {
        userId: currentUser.uid,
        userName: currentUser.displayName || currentUser.email?.split('@')[0],
        userPhoto: currentUser.photoBase64,
        content: moderatedContent,
        createdAt: new Date().toISOString()
      };

      await updateDoc(doc(db, 'social_posts', postId), {
        comments: arrayUnion(comment)
      });

      setNewComment('');
      onCommentAdded();
    } catch (err) {
      console.error('Error adding comment:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
    
    if (diffInMinutes < 1) return 'Agora mesmo';
    if (diffInMinutes < 60) return `${diffInMinutes} min`;
    
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h`;
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d`;
    
    return date.toLocaleDateString('pt-PT', {
      day: 'numeric',
      month: 'short'
    });
  };

  return (
    <div className="border-t bg-gray-50">
      <div className="p-4 space-y-4">
        {comments.map((comment, index) => (
          <div key={index} className="flex gap-3">
            {comment.userPhoto ? (
              <img
                src={comment.userPhoto}
                alt={comment.userName}
                className="w-8 h-8 rounded-full object-cover"
              />
            ) : (
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-xs font-medium text-gray-500">
                  {comment.userName?.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
            <div className="flex-1">
              <div className="bg-white p-3 rounded-lg">
                <p className="font-medium">{comment.userName}</p>
                <p className="text-gray-600">{comment.content}</p>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                {formatDate(comment.createdAt)}
              </p>
            </div>
          </div>
        ))}

        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Escreva um comentário..."
            className="flex-1 px-4 py-2 border border-gray-200 rounded-full focus:ring-primary focus:border-primary"
          />
          <button
            type="submit"
            disabled={loading || !newComment.trim()}
            className="p-2 bg-primary text-secondary rounded-full hover:bg-primary-dark disabled:opacity-50"
          >
            <Send size={20} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default CommentSection;