import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Package, Search, ShoppingCart, Tag, Eye, ClipboardList } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

interface Product {
  id: string;
  name: string;
  description?: string;
  sku: string;
  ean?: string;
  regularPrice: number;
  salePrice: number;
  mainImage?: string;
  category?: string;
  isPrivateSale: boolean;
  companyId: string;
  promotionalPeriod?: {
    startDate: string;
    endDate: string;
  };
}

interface Company {
  id: string;
  commercialName: string;
  legalName: string;
}

export default function PrivateSales() {
  const [products, setProducts] = useState<(Product & { companyName: string })[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [categories, setCategories] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadProducts();
    loadCategories();
  }, [selectedCategory]);

  const loadCategories = async () => {
    try {
      const categoriesQuery = query(collection(db, 'categories'));
      const snapshot = await getDocs(categoriesQuery);
      const categoriesData = snapshot.docs.map(doc => doc.data().name);
      setCategories(categoriesData);
    } catch (err) {
      console.error('Error loading categories:', err);
    }
  };

  const loadProducts = async () => {
    try {
      // Base query for private sale products
      let productsQuery = query(
        collection(db, 'products'),
        where('isPrivateSale', '==', true)
      );

      // Add category filter if a specific category is selected
      if (selectedCategory !== 'all') {
        productsQuery = query(
          collection(db, 'products'),
          where('isPrivateSale', '==', true),
          where('category', '==', selectedCategory)
        );
      }

      const snapshot = await getDocs(productsQuery);
      
      // Load company data for each product
      const productsWithCompanyData = await Promise.all(
        snapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data() as Product;
          let companyName = 'Empresa não encontrada';
          
          try {
            if (productData.companyId) {
              const companyDoc = await getDoc(doc(db, 'companies', productData.companyId));
              if (companyDoc.exists()) {
                const companyData = companyDoc.data() as Company;
                companyName = companyData.commercialName || companyData.legalName;
              }
            }
          } catch (err) {
            console.error('Error loading company data:', err);
          }
          
          return {
            id: productDoc.id,
            ...productData,
            companyName
          };
        })
      );
      
      setProducts(productsWithCompanyData);
      setError('');
    } catch (err) {
      console.error('Error loading products:', err);
      setError('Erro ao carregar produtos. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const isPromotionalPriceActive = (product: Product): boolean => {
    if (!product.promotionalPeriod?.startDate || !product.promotionalPeriod?.endDate) {
      return false;
    }

    const now = new Date().getTime();
    const startDate = new Date(product.promotionalPeriod.startDate).getTime();
    const endDate = new Date(product.promotionalPeriod.endDate).getTime();

    return now >= startDate && now <= endDate && product.salePrice > 0;
  };

  const getCurrentPrice = (product: Product): number => {
    return isPromotionalPriceActive(product) ? product.salePrice : product.regularPrice;
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (product.ean && product.ean.includes(searchTerm))
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-secondary">Vendas Privadas</h2>
            <button
              onClick={() => navigate('/private-sales/orders')}
              className="flex items-center gap-2 px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark"
            >
              <ClipboardList size={20} />
              Ver Pedidos Efectuados
            </button>
          </div>

          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Pesquisar por nome, SKU ou EAN..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>

            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
            >
              <option value="all">Todas as Categorias</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {error && (
            <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {error}
            </div>
          )}

          {filteredProducts.length === 0 ? (
            <div className="text-center py-12">
              <Package className="mx-auto h-12 w-12 text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">Nenhum produto encontrado</h3>
              <p className="text-gray-500">
                Não existem produtos disponíveis para venda privada neste momento.
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {filteredProducts.map((product) => (
                <Link
                  key={product.id}
                  to={`/private-sales/${product.id}`}
                  className="group relative bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                >
                  {isPromotionalPriceActive(product) && (
                    <div className="absolute top-2 right-2 z-10">
                      <div className="bg-red-600 text-white text-xs px-2 py-1 rounded">
                        Promoção
                      </div>
                    </div>
                  )}

                  <div className="aspect-square relative overflow-hidden rounded-t-lg">
                    {product.mainImage ? (
                      <img
                        src={product.mainImage}
                        alt={product.name}
                        className="w-full h-full object-cover group-hover:scale-105 transition-transform"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                        <Package className="h-12 w-12 text-gray-400" />
                      </div>
                    )}
                  </div>

                  <div className="p-4">
                    <div className="flex items-center gap-2 mb-2">
                      <Tag size={16} className="text-gray-400" />
                      <span className="text-sm text-gray-600">{product.category || 'Sem categoria'}</span>
                    </div>

                    <h3 className="font-medium text-gray-900 mb-2 line-clamp-2">
                      {product.name}
                    </h3>

                    <p className="text-sm text-gray-600 mb-2">
                      Vendido por: {product.companyName}
                    </p>

                    <div className="flex items-baseline gap-2">
                      <span className="text-lg font-bold text-gray-900">
                        €{getCurrentPrice(product).toFixed(2)}
                      </span>
                      {isPromotionalPriceActive(product) && (
                        <span className="text-sm text-gray-500 line-through">
                          €{product.regularPrice.toFixed(2)}
                        </span>
                      )}
                    </div>

                    <button
                      className="mt-4 w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark"
                    >
                      <Eye size={20} />
                      Ver Detalhes
                    </button>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}