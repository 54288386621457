import { ArrowLeft } from 'lucide-react';
import { useSupportView } from '../contexts/SupportViewContext';

export default function SupportModeIndicator() {
  const { supportView, exitSupportMode } = useSupportView();

  if (!supportView) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="flex flex-col items-end gap-2">
        <div className="bg-yellow-100 text-yellow-800 px-3 py-1 rounded text-sm">
          Visualizando dados de: {supportView.userName}
        </div>
        <button
          onClick={exitSupportMode}
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 shadow-lg"
        >
          <ArrowLeft size={20} />
          Voltar ao Admin
        </button>
      </div>
    </div>
  );
}