import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

let restrictedWordsCache: string[] | null = null;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const getRestrictedWords = async (): Promise<string[]> => {
  const now = Date.now();
  
  // Return cached words if they exist and cache hasn't expired
  if (restrictedWordsCache && (now - lastFetchTime) < CACHE_DURATION) {
    return restrictedWordsCache;
  }

  try {
    const moderationDoc = await getDoc(doc(db, 'settings', 'moderation'));
    if (moderationDoc.exists()) {
      restrictedWordsCache = moderationDoc.data().restrictedWords || [];
      lastFetchTime = now;
      return restrictedWordsCache;
    }
    return [];
  } catch (error) {
    console.error('Error fetching restricted words:', error);
    return restrictedWordsCache || [];
  }
};

export const moderateContent = async (content: string): Promise<string> => {
  const restrictedWords = await getRestrictedWords();
  let moderatedContent = content;

  restrictedWords.forEach(word => {
    const regex = new RegExp(word, 'gi');
    moderatedContent = moderatedContent.replace(regex, '(ocultado por LOGNOW)');
  });

  return moderatedContent;
};