import { useState, useEffect } from 'react';
import { IfthenpayService, PAYMENT_STATUS } from '../../services/ifthenpay';
import { CheckCircle, XCircle, Clock } from 'lucide-react';

interface MBWayPaymentStatusProps {
  reference: string;
  onSuccess: () => void;
  onExpire: () => void;
}

export default function MBWayPaymentStatus({
  reference,
  onSuccess,
  onExpire
}: MBWayPaymentStatusProps) {
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes
  const [status, setStatus] = useState<'pending' | 'success' | 'expired' | 'error'>('pending');
  const [error, setError] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          onExpire();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onExpire]);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await IfthenpayService.checkPaymentStatus(reference);
        
        switch (response.Status) {
          case PAYMENT_STATUS.SUCCESS:
            setStatus('success');
            onSuccess();
            break;
          case PAYMENT_STATUS.EXPIRED:
          case PAYMENT_STATUS.REJECTED:
          case PAYMENT_STATUS.DECLINED:
            setStatus('expired');
            onExpire();
            break;
        }
      } catch (err: any) {
        console.error('Error checking payment status:', err);
        setError(err.Message || 'Erro ao verificar status do pagamento');
      }
    };

    const statusInterval = setInterval(checkStatus, 2000); // Check every 2 seconds

    return () => clearInterval(statusInterval);
  }, [reference, onSuccess, onExpire]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  if (status === 'success') {
    return (
      <div className="text-center py-8">
        <CheckCircle className="mx-auto h-16 w-16 text-green-500 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Pagamento Confirmado!
        </h3>
        <p className="text-gray-600">
          O seu pagamento foi processado com sucesso.
        </p>
      </div>
    );
  }

  if (status === 'expired') {
    return (
      <div className="text-center py-8">
        <XCircle className="mx-auto h-16 w-16 text-red-500 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Tempo Expirado
        </h3>
        <p className="text-gray-600">
          O tempo para confirmar o pagamento expirou.
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <XCircle className="mx-auto h-16 w-16 text-red-500 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Erro ao Processar Pagamento
        </h3>
        <p className="text-gray-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="text-center py-8">
      <Clock className="mx-auto h-16 w-16 text-primary animate-pulse mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">
        Aguardando Confirmação
      </h3>
      <p className="text-gray-600 mb-4">
        Por favor, aceite o pagamento na sua app MB WAY
      </p>
      <div className="inline-flex items-center justify-center px-4 py-2 rounded-full bg-gray-100">
        <span className="font-medium">{formatTime(timeLeft)}</span>
      </div>
    </div>
  );
}