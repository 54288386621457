import { useState } from 'react';
import { User, Mail, Phone } from 'lucide-react';

interface SystemProfileStepProps {
  onSubmit: (data: {
    displayName: string;
    email: string;
    phone: string;
    role: string;
  }) => void;
  initialData: {
    displayName: string;
    email: string;
    phone: string;
    role: string;
  };
  loading: boolean;
  error: string;
}

export default function SystemProfileStep({ 
  onSubmit, 
  initialData,
  loading,
  error: submitError
}: SystemProfileStepProps) {
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.displayName || !formData.email) {
      setError('Por favor, preencha todos os campos obrigatórios');
      return;
    }
    onSubmit(formData);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-secondary mb-6">
        Perfil do Sistema
      </h2>

      {(error || submitError) && (
        <div className="mb-4 text-red-600 text-sm">
          {error || submitError}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nome para Perfil *
          </label>
          <div className="mt-1 relative">
            <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              required
              value={formData.displayName}
              onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email *
          </label>
          <div className="mt-1 relative">
            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Telefone
          </label>
          <div className="mt-1 relative">
            <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="tel"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
            />
          </div>
        </div>

        <div className="flex justify-end pt-6">
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-2 bg-primary text-secondary rounded-lg hover:bg-primary-dark disabled:opacity-50"
          >
            {loading ? 'Finalizando...' : 'Finalizar'} </button>
        </div>
      </form>
    </div>
  );
}